export default {
  "activity.category.DEIdiversityInclusionAccessibility": "DEI", // ignore-untranslated
  "activity.category.DEIdiversityInclusionAccessibility.subtitle": "Diversidad, inclusión y accesibilidad",
  "activity.category.accounting": "Contabilidad",
  "activity.category.accounting.subtitle": "Finanzas, auditoría y gestión de riesgos",
  "activity.category.advancedManufacturing": "Fabricación avanzada",
  "activity.category.agricultureTechnology": "Tecnología agrícola",
  "activity.category.appliedLearning": "Aprendizaje aplicado",
  "activity.category.appliedLearning.subtitle": "Técnicos farmacéuticos, ópticos y más",
  "activity.category.biomanufacturing": "Biofabricación",
  "activity.category.businessOperations": "Operaciones comerciales",
  "activity.category.businessOperations.subtitle": "Mejora del proceso, Agile y Scrum",
  "activity.category.businessStrategyInnovationAdministration": "Negocios",
  "activity.category.businessStrategyInnovationAdministration.subtitle": "Estrategia, innovación y administración de negocios",
  "activity.category.cleanTechnology": "Tecnología limpia",
  "activity.category.cloudComputingProgramming": "Computación en la nube",
  "activity.category.cloudComputingProgramming.subtitle": "Cloud Essentials, operaciones y arquitectura en la nube",
  "activity.category.communicationConfidenceInfluenceResolution": "Comunicación",
  "activity.category.communicationConfidenceInfluenceResolution.subtitle": "Confianza profesional, influencia y resolución de conflictos",
  "activity.category.constructionManagement": "Gestión de la construcción",
  "activity.category.constructionManagement.subtitle": "Contratos de construcción, estimación y estrategia",
  "activity.category.cybersecurity": "Ciberseguridad",
  "activity.category.dataAnalytics": "Análisis de datos",
  "activity.category.dataAnalytics.subtitle": "PowerBI, Excel, análisis de datos y visualización",
  "activity.category.dataScience": "Ciencia de datos",
  "activity.category.dataScience.subtitle": "Modelado de datos, ingeniería de datos y programación de datos",
  "activity.category.design": "Diseño",
  "activity.category.design.subtitle": "Diseño creativo, gráfico y UX/UI",
  "activity.category.digitalSkills": "Habilidades digitales",
  "activity.category.digitalSkills.subtitle": "Microsoft Office, PowerPoint y MS365",
  "activity.category.digitalTechnology": "Tecnología digital",
  "activity.category.emergentTechnologies": "Tecnologías emergentes",
  "activity.category.emergentTechnologies.subtitle": "Cadena de bloques, aprendizaje automático, IA y metaverso",
  "activity.category.engineeringContinuingEducation": "Ingeniería",
  "activity.category.engineeringContinuingEducation.subtitle": "Educación continua para ingenieros profesionales (Ing. p.)",
  "activity.category.growthAccelerators": "Aceleradores de crecimiento",
  "activity.category.growthAccelerators.subtitle": "Estudiantes de inglés como segundo idioma (ESL)/estudiantes de francés como segundo idioma (FSL), comunicaciones escritas y preparación universitaria",
  "activity.category.inProgress": "En progreso",
  "activity.category.integratedTechnology": "TI",
  "activity.category.integratedTechnology.subtitle": "Seguridad cibernética, seguridad de la información y privacidad de la información",
  "activity.category.leadership": "Liderazgo",
  "activity.category.leadership.subtitle": "Material esencial para líderes, coaching e inteligencia emocional",
  "activity.category.lifestyle": "Estilo de vida",
  "activity.category.lifestyle.subtitle": "Nutrición, enriquecimiento personal y pasatiempos",
  "activity.category.management101": "Gestión básica",
  "activity.category.management101.subtitle": "Desarrollo del equipo, gestión del desempeño y gestión del cambio",
  "activity.category.marketing": "Marketing", // ignore-untranslated
  "activity.category.marketing.subtitle": "Marketing digital, servicio al cliente y gestión de productos",
  "activity.category.myList": "Mi lista",
  "activity.category.peopleCulture": "Personas y cultura",
  "activity.category.peopleCulture.subtitle": "Adquisición de talentos, compensación y relaciones con los empleados",
  "activity.category.preConstruction": "Preconstrucción",
  "activity.category.preConstruction.subtitle": "BIM, vivienda pasiva y diseño ambiental",
  "activity.category.projectManagement": "Administración de proyectos",
  "activity.category.projectManagement.subtitle": "Preparación del examen, educación continua y herramientas de gestión de proyectos",
  "activity.category.safetyFirst": "La seguridad es lo primero",
  "activity.category.safetyFirst.subtitle": "Salud y seguridad ocupacional, WHMIS y seguridad en el lugar de trabajo",
  "activity.category.softwareDevelopment": "Desarrollo de software",
  "activity.category.softwareDevelopment.subtitle": "Bases de datos, desarrollo de pila completa y web",
  "activity.category.supplyChainManagement": "Gestión de la cadena de suministro",
  "activity.category.supplyChainManagement.subtitle": "Logística, comercio global y prácticas Lean",
  "activity.category.sustainability": "Sostenibilidad",
  "activity.category.sustainability.subtitle": "Gestión sostenible, prácticas y responsabilidad social corporativa",
  "activity.category.wellness": "Bienestar",
  "activity.category.wellness.subtitle": "Bienestar digital, bienestar emocional y equilibrio entre el trabajo y la vida personal",
  "activity.category.writtenCommunication": "Comunicación escrita",
  "activity.category.writtenCommunication.subtitle": "Comunicación comercial, escritura técnica y aprendizaje de idiomas",
  "activity.certificateType.certificate": "Certificado",
  "activity.certificateType.course": "Curso",
  "activity.certificateType.degree": "Grado",
  "activity.certificateType.diploma": "Diploma", // ignore-untranslated
  "activity.certificateType.microcredential": "Microcredencial",
  "activity.certificateType.online": "En línea",
  "activity.contentFormats.readings": "Lecturas",
  "activity.contentFormats.slides": "Diapositivas",
  "activity.contentFormats.videos": "Videos", // ignore-untranslated
  "activity.delivery.blended": "Combinado",
  "activity.delivery.inPerson": "En persona",
  "activity.delivery.inPersonLocationRestricted": "Presencial (radio de ubicación)",
  "activity.delivery.online": "En línea",
  "activity.duration.lessThanThreeMonths": "Menos de 3 meses",
  "activity.duration.oneToTwoYears": "De 1 a 2 años",
  "activity.duration.sixToTwelveMonths": "De 6 a 12 meses",
  "activity.duration.threeToSixMonths": "De 3 a 6 meses",
  "activity.duration.twoPlusYears": "Más de 2 años",
  "activity.feedbackTypes.assignments": "Asignaciones",
  "activity.feedbackTypes.discussions": "Debates",
  "activity.feedbackTypes.instructorReviews": "Revisiones del instructor",
  "activity.feedbackTypes.peerReviews": "Evaluación entre pares",
  "activity.feedbackTypes.quizzes": "Cuestionarios",
  "activity.format.instructorLed": "Guiado por instructor",
  "activity.format.selfPaced": "Autodidáctico",
  "activity.instructionLang.en": "Inglés",
  "activity.instructionLang.es": "Español",
  "activity.instructionLang.fr": "Francés (Canadá)",
  "activity.project.false": "No", // ignore-untranslated
  "activity.project.true": "Sí",
  "activity.skills.ariaLabel.exactlyOne": "Habilidad: {skillName}", // List of skills read by screen readers when there is exactly one skills associated with an activity
  "activity.skills.ariaLabel.exactlyTwo": "Habilidades: {skillName} y 1 habilidad más.", // List of skills read by screen readers when there are exactly two skills associated with an activity
  "activity.skills.ariaLabel.threePlus": "Habilidades: {skillName} y {numMoreSkills} más habilidades.", // List of skills read by screen readers when there are three or more skilsl associated with an activity
  "activity.startDate.anytime": "En cualquier momento",
  "activity.startDate.comingSoon": "Próximamente",
  "activity.startDate.notApplicable": "N/A", // ignore-untranslated
  "activity.startDate.notScheduled": "No programado",
  "activity.startDateStatus.startsNextMonth": "Empieza el próximo mes", // Used to indicate when the next session of an activity starts
  "activity.startDateStatus.startsNextWeek": "Empieza la próxima semana", // Used to indicate when the next session of an activity starts
  "activity.startDateStatus.startsThisMonth": "Empieza este mes", // Used to indicate when the next session of an activity starts
  "activity.startDateStatus.startsThisWeek": "Empieza esta semana", // Used to indicate when the next session of an activity starts
  "activity.status.newArrival": "Nueva llegada", // Used to indicate that an activity is a new arrival
  "activity.status.trending": "Tendencia", // Used to indicate that an activity is trending
  "activity.type.course": "Curso",
  "activity.type.program": "Programa",
  "activity.type.program.completionCount": "cursos completados", // Text for describing number of courses completed (beside a fraction) ex: 5/8 courses completed
  "activity.weeklyTimeCommitment.fiveToTen": "De 5 a 10 horas por semana",
  "activity.weeklyTimeCommitment.lessThanFive": "Menos de 5 horas por semana",
  "activity.weeklyTimeCommitment.tenToTwenty": "De 10 a 20 horas por semana",
  "activity.weeklyTimeCommitment.twentyPlus": "Más de 20 horas por semana",

  "activity-features.certificateType": "Tipo de certificado", // Alt-text describing the value for the program/course type of certificate earned
  "activity-features.coursesRequired": "Cursos obligatorios", // Alt-text describing the value for the program/course number of required courses
  "activity-features.delivery": "Entrega", // Alt-text describing the value for the program/course delivery type (i.e online or in-person)
  "activity-features.duration": "Duración", // Alt-text describing the value for the program/course duration
  "activity-features.format": "Formato", // Alt-text describing the value for the program/course format
  "activity-features.numberOfCourses": "{numberOfCourses} curso(s)", // The number of courses reqruied for this program
  "activity-features.timeCommitment": "Tiempo necesario", // Alt-text describing the value for the program/course time commitment

  "activity-fees.activityCost": "Precio en {type}", // label for the cost of the program/course
  "activity-fees.approvedAmount": "Monto aprobado", // The label for the amount that has been approved for this program/course
  "activity-fees.balanceBeforeApproval": "Saldo antes de la aprobación", // The label for the benefit balance before approval
  "activity-fees.balanceWithApproval": "Saldo con aprobación", // label for the balance of your benefit with the approval
  "activity-fees.billedDirectly": "Si se aprueba, los cargos se facturarán directamente a {companyName}. Solo pagará los costos que superen su cobertura.", // Supplementary note to explain when a user has to pay in a direct billing scenario
  "activity-fees.caseByCaseReimbursementMessage": "Si ya hizo los cargos a su empresa, comuníquese con {programSponsor} para conocer los próximos pasos.", // The reimbursement message shown when a company has case-by-case budget setting enabled
  "activity-fees.coursePrices": "Precios del curso", // Related number field displays price range of courses associated with program being displayed (pluralization option retained if possible)
  "activity-fees.coverage.employee": "Cobertura del empleador", // Label for line item showing how much financial coverage the employer will provide
  "activity-fees.coverage.jobseeker": "Available coverage", // Label for line item showing how much financial coverage the organization will provide
  "activity-fees.coverage.member": "Available coverage", // Label for line item showing how much financial coverage the association will provide
  "activity-fees.coverage.tooltip.description.employee": "Esta institución cobra en {providerCurrency}. Su cobertura de empleador se aplicará en el momento de la compra.", // Description for currency conversion tooltip in activity-fees
  "activity-fees.coverage.tooltip.description.jobseeker": "Esta institución cobra en {providerCurrency}. La cobertura de su organización se aplicará en el momento de la compra.", // Description for currency conversion tooltip in activity-fees
  "activity-fees.coverage.tooltip.description.member": "Esta institución cobra en {providerCurrency}. La cobertura de su asociación se aplicará en el momento de la compra.", // Description for currency conversion tooltip in activity-fees
  "activity-fees.coverage.tooltip.target": "Se convierte a {coverage}", // Title for currency conversion tooltip in activity-fees
  "activity-fees.discountCodeAmount": "Monto del código de descuento", // The label for the amount that the discount code is good for.
  "activity-fees.employee.cost": "Costo para el empleado", // Label for the amount the employee will pay for a program/course
  "activity-fees.header": "Resumen financiero", // The header for the activity fees card
  "activity-fees.howToPay.header": "¿Cómo pago?", // Header for supplementary section where additional instructions on how to pay is provided
  "activity-fees.jobseeker.cost": "Costo para la persona en busca de empleo", // Label for the amount the jobseeker will pay for a program/course
  "activity-fees.member.cost": "Costo para miembros", // Label for the amount the member will pay for a program/course (association tenants)
  "activity-fees.outOfPocket": "Si se aprueba, pagará el costo total cuando se registre, luego envíe el gasto a {companyName}. Para reclamar su subsidio, pague la tarifa como se establece en la política de su empleador.", // Supplementary information to explain how to pay in a "out of pocket" scenario
  "activity-fees.overallCost": "Precio total", // label for the overall cost of the program/course
  "activity-fees.programOrCourseCost": "Precio del programa/curso", // label for the cost of the program/course
  "activity-fees.refundAmount": "Un {refundPercent} % de lo que {employerName} pagó se reembolsará a su cobertura de empleador.", // Refund amount message
  "activity-fees.reimbursmentMessage": "Si su empresa ya lo reembolsó, tome las medidas necesarias según las políticas de su empresa o comuníquese con {programSponsor}.", // The reimbursment message shown when a company required reimbursment
  "activity-fees.stripeRefundAmount": "Reembolso emitido por un {refundPercent} %.", // Stripe refund amount message
  "activity-fees.submitOwnCost": "Precio", // label for cost for submit own requests
  "activity-fees.taxCalculatedNext": "Impuesto de ventas calculado en el siguiente paso", // Message informing user the tax will be calcuated on the next page
  "activity-fees.taxIncluded": "Impuesto de ventas incluido", // Message informing user the tax is already included in the amount displayed
  "activity-fees.taxWillApply": "Se aplicará el impuesto a las ventas", // Message informing user they will have to pay taxes
  "activity-fees.yourProgramSponsor": "su patrocinador del programa", // Displayed if the name of your program sponsnor is unkonwn

  "activity-filter.clearFilters": "Borrar filtros", // Button label for clear filters button.
  "activity-filter.dialog.close": "Cerrar", // The filter by skill button label
  "activity-filter.filterBy": "Filtrar por", // Label to proceed filter bar interactions
  "activity-filter.filterType.activeState.ariaLabel": "Estado activo", // Aria text for active state filter dropdown
  "activity-filter.filterType.activeState.displayText": "Estado", // Label for active state filter dropdown
  "activity-filter.filterType.careerType.ariaLabel": "Carrera profesional", // Career type value for careers
  "activity-filter.filterType.careerType.displayText": "Carrera profesional", // Career type value for careers
  "activity-filter.filterType.careerType.introText": "Elija la opción que mejor describa el conjunto de habilidades que desea desarrollar", // Text displayed at the top of a career selector, introducing what it contains
  "activity-filter.filterType.certificationType.ariaLabel": "Tipo de certificación", // Aria text for certification type filter dropdown
  "activity-filter.filterType.certificationType.certificate": "Certificado", // Certification type value for certificates
  "activity-filter.filterType.certificationType.course": "Curso", // Certification type value for courses
  "activity-filter.filterType.certificationType.degree": "Grado", // Certification type value for degrees
  "activity-filter.filterType.certificationType.diploma": "Diploma", // Certification type value for diplomas, ignore-untranslated
  "activity-filter.filterType.certificationType.displayText": "Tipo", // Label for certification type filter dropdown
  "activity-filter.filterType.certificationType.microcredential": "Microcredencial", // Certification type value for microcredentials
  "activity-filter.filterType.delivery.ariaLabel": "En línea o en persona", // Aria text for delivery filter dropdown
  "activity-filter.filterType.delivery.blended": "Combinado", // Delivery type value for blended
  "activity-filter.filterType.delivery.displayText": "Entrega", // Label for delivery filter dropdown
  "activity-filter.filterType.delivery.inPerson": "En persona", // Delivery type value for in person
  "activity-filter.filterType.delivery.online": "En línea", // Delivery type value for online
  "activity-filter.filterType.duration.ariaLabel": "Duración del programa", // Aria text for program duration filter dropdown
  "activity-filter.filterType.duration.displayText": "Duración", // Label for program duration filter dropdown
  "activity-filter.filterType.duration.lessThanThreeMonths": "Menos de 3 meses", // Duration value for less than three months
  "activity-filter.filterType.duration.oneToTwoYears": "De 1 a 2 años", // Duration value for 1 to 2 years
  "activity-filter.filterType.duration.sixToTwelveMonths": "De 6 a 12 meses", // Duration value for 6 to 12 months
  "activity-filter.filterType.duration.threeToSixMonths": "De 3 a 6 meses", // Duration value for three to six months
  "activity-filter.filterType.duration.twoPlusYears": "Más de 2 años", // Duration value for more than 2 years
  "activity-filter.filterType.format.ariaLabel": "Formato del ritmo de entrega del contenido", // Aria for format dropdown label
  "activity-filter.filterType.format.displayText": "Formato", // Display text for content delivery format (is either instructor led or self-paced)
  "activity-filter.filterType.format.instructorLed": "Guiado por un instructor", // Content pacing description when activity is led by an academic provider employee
  "activity-filter.filterType.format.selfPaced": "Autodidáctico", // Content pacing description when activity is led by the individual taking the course
  "activity-filter.filterType.instructionLang.ariaLabel": "Idioma base utilizado por el instructor y el trabajo de curso", // Aria for instruction language dropdown label
  "activity-filter.filterType.instructionLang.displayText": "Idioma", // Display text for instruction language
  "activity-filter.filterType.instructionLang.en": "Inglés", // Filter label to display courses provided in english
  "activity-filter.filterType.instructionLang.fr": "Francés (Canadá)", // Filter label to display french canadian courses
  "activity-filter.filterType.skillDialog.ariaLabel": "Habilidad", // The header for the skills filter dialog
  "activity-filter.filterType.skillDialog.displayText": "Habilidad", // The header for the skills filter dialog
  "activity-filter.filterType.weeklyTimeCommitment.ariaLabel": "Tiempo semanal necesario", // Aria text for weekly time commitment filter dropdown
  "activity-filter.filterType.weeklyTimeCommitment.displayText": "Horas/semana", // Label for weekly time commitment filter dropdown
  "activity-filter.filterType.weeklyTimeCommitment.fiveToTen": "De 5 a 10 horas por semana", // Weekly time commitment value of five to ten hours a week
  "activity-filter.filterType.weeklyTimeCommitment.lessThanFive": "Menos de 5 horas por semana", // Weekly time commitment value of less than five hours a week
  "activity-filter.filterType.weeklyTimeCommitment.tenToTwenty": "De 10 a 20 horas por semana", // Weekly time commitment value of ten to twenty a week
  "activity-filter.filterType.weeklyTimeCommitment.twentyPlus": "Más de 20 horas por semana", // Weekly time commitment value of more than twenty hours a week
  "activity-filter.mobile.filterBySkill": "Filtrar por habilidad", // The filter by skill button label
  "activity-filter.mobile.filters": "Filtros", // The filters mobile dialog header
  "activity-filter.mobile.otherFilters": "Otros filtros", // The other filters button label

  "activity-import.documentTitle.stream": "Importar flujo",

  "activity-list.nextPage.ariaLabel": "Próximos programas", // The aria label for the next page button
  "activity-list.previousPage.ariaLabel": "Programas anteriores", // The aria label for the previous page button
  "activity-list.viewAll": "Ver todos ({numberOfActivities})", // The text for the link to view all programs in a category (e.g. "Project Management, Scoping and Planning", "Diversity, Inclusion and Accessibility", etc.). Note that the "category" text is not shown, and only announced by screenreaders in the associated "activity-list.viewAll.ariaLabel".
  "activity-list.viewAll.ariaLabel": "Ver todos de {category} ({numberOfActivities})", // The accessibility text for the link to view all programs in a category (e.g. "Project Management, Scoping and Planning", "Diversity, Inclusion and Accessibility", etc.).

  "activity-relationships.completeElectives": "Completar {numberOfElectives} curso(s) optativo(s)", // The text specifying how many electives need to be completed.
  "activity-relationships.relatedProgramsHeader": "Parte de los siguientes programas", // The text for related relationships header
  "activity-relationships.requiredCourses": "Cursos obligatorios", // The text for required courses header

  "activity-skill-tagger.mainLabel": "Búsqueda de habilidades", // Label displayed above search input
  "activity-skill-tagger.noResults": "No hay resultados.", // Displayed after searching if there are no results found
  "activity-skill-tagger.resultsPlaceholder": "Los resultados aparecerán aquí.", // Displayed before uses searches for anything
  "activity-skill-tagger.searching": "Buscando...", // Displayed when searching is in progress

  "activity-small.notScheduledDescription": "Este curso estará disponible cuando se programe la próxima sesión.", // Alert and tooltip informing about an unscheduled course

  "activity-table.confirmation.cancelText": "También cancelará las solicitud(es) de {applicationsLength}.", // Text for confirmation dialogue box when actitivity is disabled to show how many applications will be cancelled
  "activity-table.confirmation.disabledText": "Si desactiva esta actividad, la ocultará de todos los inquilinos. {cancelText}", // Text for confirmation dialogue box when actitivity is disabled
  "activity-table.confirmation.enableText": "Habilitar esta actividad hará que sea visible para todos los inquilinos.", // Text for confirmation dialogue box when actitivity is enabled
  "activity-table.confirmation.header": "¿Está seguro?", // Header for confirmation dialogue box

  "activity-table.exportDialog.exporting": "Exportando...", // Text for exporting animation
  "activity-table.exportDialog.text": "Esto exportará las actividades con el filtro aplicado a un archivo CSV. Seleccione los atributos que desea exportar a continuación.", // Text for export dialog template
  "activity-table.exportDialog.title": "Exportar actividades a CSV", // Header for export dialog template

  "activity-table.header.actions": "Acciones",
  "activity-table.header.active": "Activo", // Label for table header for active toggle
  "activity-table.header.active.tooltip": "Si esta actividad se muestra en el catálogo", // Tooltip for table header for active toggle
  "activity-table.header.export": "Exportar", // Label for stream export button on activities page and in activity page dialogue, and export dialog in the admin section
  "activity-table.header.import": "Importar", // Label for stream import button on activities page in the admin section
  "activity-table.header.provider": "Proveedor", // Label for table header for provider
  "activity-table.header.req": "Req", // Label for table header for whether the activity is requestable, ignore-untranslated
  "activity-table.header.req.tooltip": "Si la actividad es necesaria", // Tooltip for req table header
  "activity-table.header.tax": "Impuesto", // Label for table header for whether the activity is taxable
  "activity-table.header.tax.tooltip": "Si la actividad es gravable", // Tooltip for tax table header
  "activity-table.header.title": "Título", // Label for table header for title
  "activity-table.header.type": "Tipo", // Label for table header for type

  "add-role.cancel": "Cancelar",
  "add-role.description": "Utiliza esta página para configurar un nuevo rol definiendo permisos amplios y detallados.",
  "add-role.submit": "Crear rol",
  "add-role.title": "Crear nuevo rol",

  "admin.custom-terms-of-use.alert.disabled": "Casilla de selección personalizada desactivada.", // Alert text which appears when the custom term of use checkbox is disabled
  "admin.custom-terms-of-use.alert.enabled": "Casilla de selección personalizada activada.", // Alert text which appears when the custom term of use checkbox is enabled
  "admin.custom-terms-of-use.explanation": "Cuando los empleados envían solicitudes, deben aceptar los términos de uso seleccionando una serie de casillas de selección. Aquí puede crear una </bold>casilla de selección personalizada<bold> única para {employerName} que se presentará a los empleados <bold>cada vez que envíen una solicitud</bold>.", // Introductory text explaining to admins how to use the page
  "admin.custom-terms-of-use.heading": "Agregue términos de uso personalizados del empleado", // Heading for a page that allows admins to create custom terms of use for when people create requests to take courses or programs
  "admin.custom-terms-of-use.input.enable-label": "Active la casilla de selección personalizada. Los cambios no aparecerán a menos que se guarden.", // Label for a checkbox which enables custom terms of use
  "admin.custom-terms-of-use.input.instructions": "Ingrese el texto de la casilla de selección personalizada (límite de {charLimit} caracteres)", // Label for an input box that allows users to input some custom language to display on the create request page
  "admin.custom-terms-of-use.input.instructions.placeholder": "Ingrese el texto", // Placeholder text that appears in the custom term of use before the user has input anything
  "admin.custom-terms-of-use.language.intro": "Seleccione un idioma para agregar una versión localizada del texto de la casilla de verificación personalizada.", // Introductory text explaining the purpose of the language selector
  "admin.custom-terms-of-use.optional-statement": "Esto es opcional y no pretende reemplazar la política de su empresa.", // Explanatory text stating that custom terms of use are optional
  "admin.custom-terms-of-use.preview.heading": "Cómo le aparecerá esto a los empleados:", // Heading for the preview of what the user-entered custom text will look like
  "admin.custom-terms-of-use.preview.text": "Aquí aparecerá el texto de la casilla de verificación personalizada.", // Placeholder text for the custom checkbox preview that appears if no custom text has been input

  "admin.tax-details.rates.copied": "Las tasas de impuestos se copiaron en el portapapeles", // The label for copying tax rates for toast
  "admin.tax-nexus.add-registration.button": "Agregar registro", // The label for the add registration button
  "admin.tax-nexus.other-registrations": "Seleccionar si hay otros registros", // Input label for province/state registration selection
  "admin.tax-nexus.registration-country.label": "País de registro", // The label for the registration country dropdown
  "admin.tax-nexus.tax-registration.label": "Registros fiscales", // The label for the tax registration input,
  "admin.transaction-details.strip-session-id.copied": "El ID de sesión de Stripe se copió en el portapapeles", // The Label for copied toast on stripe session id

  "admission.requirements.contactEmailHelpCard": "¿Tiene preguntas sobre las admisiones? Envíe un correo electrónico a ", // alert user that they may reach out to the education facility's admission contact person for assistance
  "admission.requirements.hasCostAndDelay": "Puede aplicarse una tarifa adicional de aproximadamente {cost} y el procesamiento de esto puede tardar hasta {delay, plural, one {# week} other {# weeks}}.", // lets the learner know a cost and delay is associated with this admission requirement
  "admission.requirements.hasCostOnly": "Puede aplicarse una tarifa adicional de aproximadamente {cost}.", // lets the learner know that a cost is associated with this admission requirement
  "admission.requirements.hasDelayOnly": "El procesamiento de esto puede tardar hasta {delay, plural, one {# week} other {# weeks}}.", // lets the learner know that a delay (weeks) is associate with this admission requirement
  "admission.requirements.internationalAnnouncement.applicant": "Debe preparar documentos adicionales si tiene un expediente académico de una escuela secundaria internacional:", // alerts the applicant that there are additional requirements if they have an international degree
  "admission.requirements.internationalAnnouncement.not.applicant": "El solicitante deberá preparar documentos adicionales si tiene un expediente académico de una escuela secundaria internacional:", // alerts reader that the applicant will have additional requirements if they have an international degree

  "admission-requirements.review.documentTitle": "Revisar requisitos - SkillsWave", // Browser-document title for view where program requirements are viewed by user
  "admission-requirements.review.introduction.applicant": "Solo un recordatorio, {activity} tiene requisitos de admisión. Si se le aprueba para este programa, aquí está lo que necesitará preparar.", // additional text that lets the user know that this specific program has requirements
  "admission-requirements.review.introduction.not.applicant": "Le recordamos que {activity} tiene requisitos de admisión. Si se aprueba la participación del solicitante en este programa, esto es lo que necesitará para prepararse.", // additional text that lets the user know that this specific program has requirements
  "admission-requirements.review.navigation.cancel": "Regresar", // Text on navigation button that user will press if they'd like to navigate back one page in the workflow
  "admission-requirements.review.navigation.submit": "Entiendo, revise mi solicitud", // Button text that user will select if they'd like to proceed to the next step in the workflow where they review their request for final submission
  "admission-requirements.review.title.applicant": "Si se le aprueba, esto es lo que necesitará", // Title for "program requirements" page, letting applicants know they'll need to satisfy the below requirements
  "admission-requirements.review.title.not.applicant": "Si el solicitante es aprobado, esto es lo que necesitará", // Lets approver/admin/etc. know that the applicant will need to satisfy the below requirements

  "app-footer.help.link": "Comuníquese con el equipo de soporte", // Part 2 of sentence for 'contact-us' page - This portion is the actual hyperlink [internal link]
  "app-footer.help.text": "¿Necesita ayuda?", // Part 1 of sentence for link to 'contact-us' page - User will be taken to support/contact-us page [internal link]
  "app-footer.policy.link": "política del programa", // Part 2 of sentence for program policy link  - This portion is the actual hyperlink [external link]
  "app-footer.policy.text": "Vea la", // Part 1 of sentence for program policy link - User will be taken to their company's program policy page [external link]
  "app-footer.trademark.text": "© 2025 SkillsWave Corporation. Todos los derechos reservados", // Do not translate "SkillsWave Corporation".

  "app-header.about": "Acerca de", // Navigation bar link, user will be taken to the How it Works page, also ariaLabel for tenant-logo/homepage route
  "app-header.analytics": "Análisis", // Navigation bar link, user will be taken to analytics page (privileged users only)
  "app-header.bookmarks": "Marcadores", // Navigation bar link, employee user will be able to view bookmarked programs/courses
  "app-header.changeLanguage": "Cambiar idioma", // Navigation bar link, user will be able to choose language the app will be in
  "app-header.editRole": "Editar rol", // Navigation bar link, user will be able relaunch onboarding
  "app-header.home": "Página de inicio", // Navigation bar link, user will be taken to the home page
  "app-header.howItWorks": "Cómo funciona", // Navigation bar link, user will be taken to the How it Works page, also ariaLabel for tenant-logo/homepage route
  "app-header.logo.altText": "logotipo de {tenantName}", // Alt text for image of tenant's logo (tenant being an employer or provider)
  "app-header.logo.ariaLabel": "Volver al inicio", // ariaLabel for tenant logo link (brings user back to hompage of Wave)
  "app-header.logout": "Cerrar sesión", // Navigation bar link, user will be logged out
  "app-header.navigation.exit": "Salir del menú de navegación", // Exit navigation popout dialog on narrow width viewports
  "app-header.navigation.open": "Abrir el menú de navegación", // Opens navigation popout dialog on narrow width viewports
  "app-header.programs": "Programas", // Navigation bar link, user will be taken to activity catalogue
  "app-header.recommended": "Recomendado", // Navigation bar link, user will be taken to the home page
  "app-header.requests": "Solicitudes", // Navigation bar link, user will be taken to requests-table view
  "app-header.settings": "Configuración", // Navigation bar link, user will be taken to settings page

  "app-nova.catchAllErrorMessage": "Vuelva a intentarlo. Si el problema continúa, comuníquese con el equipo de soporte.", // The catch all error message for when an unknown error occurs
  "app-nova.documentTitle": "SkillsWave", // Do not translate "SkillsWave". ignore-untranslated
  "app-nova.marketing.callToAction.button": "¡Comience hoy mismo!", // The label that goes on the marketing call to action button
  "app-nova.marketing.callToAction.header": "Descubra una mejor manera de liberar el <nobreak>potencial de los empleados</nobreak>", // The message that goes at the top of all pages when marketing is turned on.
  "app-nova.marketing.dialog.step.intro.getStartedButton": "¡Comience hoy mismo!", // The button that will trigger an email to marketing.
  "app-nova.marketing.dialog.step.intro.header": "Desarrolle las habilidades que necesita con el talento que ya tiene.", // The header is shown in the marketing popup when an action is made.
  "app-nova.marketing.dialog.step.intro.message": "Comuníquese con nosotros para programar una demostración o comenzar.", // The message is shown in the marketing popup when an action is made.
  "app-nova.marketing.dialog.step.submit.header": "¡Estaremos con usted enseguida!", // The header is shown in the marketing popup when a message has been sent ot the marketing team
  "app-nova.marketing.dialog.step.submit.message": "Gracias por enviar su solicitud. Un representante de SkillsWave se comunicará con usted en un plazo de 1 a 2 días hábiles para ayudarlo a comenzar.", // The message is shown in the marketing popup when a message has been sent to the marketing team. Do not translate "SkillsWave".
  "app-nova.skipToMain": "pasar al contenido principal", // label for the skip to main content button used for accessibility

  "application.cancelReason.ACTIVITY_NOT_OFFERED": "Este curso/programa ya no está disponible.", // Informs the user that this application is canceled because the activity is no longer offered

  "application.status.approved": "Aprobado",
  "application.status.cancelled": "Cancelado",
  "application.status.completed": "Completado",
  "application.status.declined": "Rechazado",
  "application.status.failed": "Error",
  "application.status.oneOfTwoApproved": "1 de 2 aprobadas",
  "application.status.paid": "Pagó",
  "application.status.pending": "Pendiente",
  "application.status.registered": "Registrado",
  "application.status.registrationSubmitted": "Se inició el registro",
  "application.status.withdrew": "Retirado",

  "application-call-to-action.dialog.cancelled.reason.label": "Motivo", // Label for reason input field
  "application-call-to-action.dialog.cancelled.reasonInfo.1": "Motivo:", // Info text above the reason input field for cancelled status confirmation dialog
  "application-call-to-action.dialog.cancelled.reasonInfo.2": "esto se proporcionará al estudiante.", // Info text above the reason input field for cancelled status confirmation dialog
  "application-call-to-action.dialog.refundBreakdown": "Desglose del reembolso:", // Text title for refund breakdown
  "application-call-to-action.dialog.refundMessageForProvider.1": "{refundPercentage}% ({refundAmountFormatted}) será reembolsado al aprendiz", // Refund message for provider line 1
  "application-call-to-action.dialog.refundMessageForProvider.2": "{retainPercentage}% ({retainAmountFormatted}) será retenido por su institución", // Refund message provider line 2
  "application-call-to-action.discountCode.save.success": "Los cambios se han guardado", // alert message for successfully saving discount code
  "application-call-to-action.input.completionDate.label": "Fecha de finalización", // Label for completion date input
  "application-call-to-action.input.completionStatus.label": "Estado de finalización", // Label for completion status input
  "application-call-to-action.input.discountCode.label": "Código de descuento", // Label for discount code input
  "application-call-to-action.input.enrollmentDate.label": "Fecha de inscripción", // Label for enrollment date input
  "application-call-to-action.input.refund.label": "Reembolso", // Label for refund input
  "application-call-to-action.input.refund.tooltip": "Reembolso al aprendiz", // Tooltip for refund input
  "application-call-to-action.input.refund.validLabel": "un reembolso válido", // Label for refund input
  "application-call-to-action.providerForm.error.completionBeforeEnrollment": "La fecha de finalización debe ser la fecha de inscripción o posterior", // Error msg to display if completion date is before the enrollment date
  "application-call-to-action.providerForm.error.empty.completionDate": "Necesita una fecha de finalización", // Error msg for missing completion date
  "application-call-to-action.providerForm.error.empty.discountCode": "Necesita un código de descuento",
  "application-call-to-action.providerForm.error.empty.enrollmentDate": "Necesita una fecha de inscripción", // Error msg for missing enrollment date
  "application-call-to-action.providerForm.error.empty.invalid_refund": "Necesita un monto de reembolso válido si el curso se cancela", // Error msg for missing completion date
  "application-call-to-action.providerForm.error.empty.refund": "Necesita un monto de reembolso si el curso se cancela.", // Error msg for missing completion date
  "application-call-to-action.providerForm.error.template": "Necesita", // Error msg template"
  "application-call-to-action.providerForm.error.template.and": "y", // Error msg separator
  "application-call-to-action.status.cancelled": "Cancelado", // Cancelled application status
  "application-call-to-action.status.fail": "Reprobado", // Failed application status
  "application-call-to-action.status.pass": "Aprobado", // Passed application status
  "application-call-to-action.status.pending": "Pendiente", // Pending application status
  "application-call-to-action.status.withdrawn": "Dado de baja", // Withdrawn application status
  "application-call-to-action.template.approver.firstApprover": "{ role, select, sponsor {El patrocinador} manager {El gerente} other {Otro aprobador} } aún tiene que revisar esta solicitud. Lo mantendremos informado cuando tengamos más información.", // Status bar message to show first approver after first request approval.
  "application-call-to-action.template.approver.firstApprover.heading": "Aprobó esta solicitud", // heading for first approver
  "application-call-to-action.template.approver.secondApprover": "{ role, select, sponsor {El patrocinador} manager {El gerente} other {Otro aprobador} } aprobó esta solicitud.", // Message to show second approver after first request approval, stating that the manager has already approved the request.
  "application-call-to-action.template.provider.buttonSave": "Guardar", // Button text for saving provider call-to-action input
  "application-call-to-action.template.requester.accountConnection.button": "Comenzar", // Button will navigate learner to workflow where they will create a profile
  "application-call-to-action.template.requester.accountConnection.heading": "¡Está aprobado! Vamos a enviar su registro.", // Approval celebration text displayed when application is approved - but learner still needs to make a profile
  "application-call-to-action.template.requester.accountConnection.heading.admissionBased": "¡Está aprobado! Comience los siguientes pasos para la admisión y el registro.", // Approval celebration text displayed when application is approved - but learner still needs to make a profile on an admission based activity
  "application-call-to-action.template.requester.accountConnection.heading.isPaymentHandledByProvider": "¡Está aprobado! Comience los siguientes pasos para registrarse en {providerName}.", // Approval celebration text displayed when application is approved - but learners activity is paid for by provider
  "application-call-to-action.template.requester.accountConnection.text": "Su parte debe tomar 10 minutos. Luego, {providerName} demorará aproximadamente {registrationTime} días hábiles en registrarlo.", // Supplimentary information regarding registration of learner account with provider
  "application-call-to-action.template.requester.accountConnection.text.admissionBased": "Una vez que se completen estos pasos, {providerName} tardará aproximadamente {registrationTime} días hábiles en comunicarse con usted y comenzar el proceso de admisión y registro.",
  "application-call-to-action.template.requester.approved.registrationClosed.bodyText": "Una vez programada la próxima sesión, podrá inscribirse.", // Supplementary info displayed when user is approved but registration to a course is closed
  "application-call-to-action.template.requester.approved.registrationClosed.heading": "Está aprobado, pero el proceso de registro ha finalizado.", // Heading of a call to action that is displayed when user is approved but registration to a course is closed
  "application-call-to-action.template.requester.approvedOrRegistered.discountCode.copy": "Copiar código", // Aria-label for copying discount code button
  "application-call-to-action.template.requester.approvedOrRegistered.discountCode.label": "Código de descuento:", // Label beside discount code
  "application-call-to-action.template.requester.approvedOrRegistered.heading": "¡Está aprobado! Comience los siguientes pasos para registrarse.", // Heading to show requesters when application is approved or registered
  "application-call-to-action.template.requester.approvedOrRegistered.heading.admissionBased": "¡Está aprobado! Comience los siguientes pasos para la admisión y el registro.", // Heading to show requesters when application is approved or registered on an admission based activity
  "application-call-to-action.template.requester.approvedOrRegistered.heading.discountCode": "¡Felicitaciones, está aprobado! Pero no olvide su código de descuento.", // Heading to show requesters when application is approved or registered (supports discount codes)
  "application-call-to-action.template.requester.approvedOrRegistered.pendingDiscountCode": "Esperando el código de descuento para el registro", // Message to show requesters when application is pending discount code (approved or registered status)
  "application-call-to-action.template.requester.approvedOrRegistered.registerButton.stripe.label": "Registrarse", // Button text for register button
  "application-call-to-action.template.requester.approvedOrRegistered.registerButton.stripe.label.admissionBased": "Comience los siguientes pasos", // Button text for register button
  "application-call-to-action.template.requester.approvedOrRegistered.text": "Para registrarse, diríjase a la página de registro de {providerName}.", // Text to show requesters when application is approved or registered
  "application-call-to-action.template.requester.approvedOrRegistered.text.discountCode": "Use este código de descuento en la compra cuando se registre en su curso con {providerName}.", // Text to show requesters when application is approved or registered (supports discount codes)
  "application-call-to-action.template.requester.approvedOrRegistered.text.stripe": "Una vez que se hayan completado los pasos, {providerName} tardará aproximadamente {registrationTime} días hábiles en registrarlo.", // Text to show requesters when application is approved or registered (stripe)
  "application-call-to-action.template.requester.approvedOrRegistered.text.stripe.admissionBased": "Una vez que se completen estos pasos, {providerName} tardará aproximadamente {registrationTime} días hábiles en comunicarse con usted y comenzar el proceso de admisión y registro.", // Text to show requesters when application is approved or registered for admission based activity(stripe)
  "application-call-to-action.template.requester.cancelled": "{providerName} canceló este programa. Fecha de entrada en vigor: {formattedCompletionDate}.", // Message to show requesters when application has been cancelled
  "application-call-to-action.template.requester.failed": "No cumplió con los requisitos necesarios para aprobar este programa.", // Message to show requesters when they failed the program/course they applied for
  "application-call-to-action.template.requester.oneOfTwoApproved.role.manager": "El gerente aún necesita revisar esta solicitud. Lo mantendremos informado cuando tengamos más información.", // Message to show requester when request is approved by the manager in hybrid approval model
  "application-call-to-action.template.requester.oneOfTwoApproved.role.sponsor": "El auspiciador aún necesita revisar esta solicitud. Lo mantendremos informado cuando tengamos más información.", // Message to show requester when request is approved by the sponsor in hybrid approval model
  "application-call-to-action.template.requester.paid.heading": "{providerName} te está registrando.", // Heading to show requesters when application is paid
  "application-call-to-action.template.requester.paid.heading.isPaymentHandledByProvider": "Solicite y regístrese en este programa en el <redirectLink>sitio web de {providerName}</redirectLink>", // Heading to show requesters when application is paid for by provider
  "application-call-to-action.template.requester.paid.text": "Una vez que se abra la ventana de registro, quedará registrado en aproximadamente {registrationTime} días hábiles. {institution} se comunicará con usted para confirmar su registro en: {email}. Si tiene alguna pregunta, comuníquese con el <link>equipo de soporte de SkillsWave</link>.", // Text to show requesters when application is paid. Do not translate "SkillsWave".
  "application-call-to-action.template.requester.paid.text.isPaymentHandledByProvider": "Si tiene preguntas acerca de su solicitud o el proceso de registro, comuníquese con <link>{studentSupportEmail}</link>.", // Text to show requesters when application is paid. Do not translate "SkillsWave".
  "application-call-to-action.template.requester.pending": "Actualmente, esta solicitud está pendiente de aprobación. Lo mantendremos informado cuando tengamos más información.", // Message to show requesters when application is pending
  "application-call-to-action.template.requester.withdrawn": "Se retiró de {activityTitle}. Fecha de entrada en vigor: {formattedCompletionDate}.", // Message to show requesters when the application has been withdrawn

  "application-overview.approvalAmount.checkbox.label": "Administro el presupuesto de capacitación para {employeeName}", // Label for a confirmation checkbox when authorizing the inputted approval amount
  "application-overview.approvalComment": "Comentario", // Label for text field where approver can leave a comment when approving a request
  "application-overview.approvalInfo.amount": "Monto aprobado", // Key for the approved amount
  "application-overview.approvalInfo.amount.edit": "Editar", // Button text for editing approval amount
  "application-overview.approvalInfo.amount.edit.cancel": "Cancelar", // Button text for cancel editing approval amount
  "application-overview.approvalInfo.amount.edit.label": "Edición del monto aprobado", // Key for editing approval amount
  "application-overview.approvalInfo.amount.pending": "Aprobación pendiente del titular del presupuesto", // Key for pending approval amount
  "application-overview.approvalInfo.amount.save": "Guardar", // Button text for editing approval amount
  "application-overview.approvalInfo.amount.updated": "Se actualizó el monto aprobado", // toast message for updating approval amount
  "application-overview.approvalInfo.description.hybridBudgetHolder": "Esta solicitud debe ser aprobada por el gerente de {employeeName} y el patrocinador. El {budgetHolder} determinará el presupuesto de capacitación para esta solicitud.", // approval information description for Hybrid Case by Case
  "application-overview.approvalInfo.description.hybridNonBudgetHolder": "Esta solicitud debe ser aprobada por el gerente de {employeeName} y el patrocinador.", // approval information description
  "application-overview.approvalInfo.heading": "Información de aprobación", // Title for approval information
  "application-overview.approvalInfo.hybridCBC.manager": "Gerente (titular del presupuesto)", // Key for manager name
  "application-overview.approvalInfo.hybridCBC.sponsor": "Patrocinador (titular del presupuesto)", // Key for sponsor name
  "application-overview.approvalInfo.manager": "Gerente encargado de aprobación", // Key for the name of the approving manager
  "application-overview.approvalInfo.managerComments": "Comentarios del gerente", // Key for the comments of the approving manager
  "application-overview.approvalInfo.maxAllowedCoverage.tooltip": "No se puede aprobar por más del costo total.", // Tooltip text indicating to the user when the inserted amount is greater than the total cost
  "application-overview.approvalInfo.minAllowedCoverage.tooltip": "No se puede aprobar por menos de $0.",
  "application-overview.approvalInfo.percentCoverage.tooltip": "La cobertura predeterminada de su organización es el {percentCoverage} % del costo", // Tooltip text indicating to the user what the default number in the 'approved amount' input box is.
  "application-overview.approvalInfo.sponsor": "Patrocinador encargado de aprobación", // Key for the name of the approving sponsor
  "application-overview.approvalInfo.sponsorComments": "Comentarios del patrocinador", // Key for the comments of the approving sponsor
  "application-overview.approveInfo.confirmBudgetHolder": "Confirme que administra el presupuesto para {employeeName}",
  "application-overview.cancelReason": "Motivo de la cancelación", // Label for text field where requester can give a reason for cancelling a request
  "application-overview.declineReason": "Razón", // Label for text field where approver can give a reason for declining a request
  "application-overview.enrollmentInfo.cancellationReason": "Motivo de la cancelación", // Key for the program or course's cancellation reason
  "application-overview.enrollmentInfo.completedOn": "Finalizado el", // Key for the date the user completed the program or course
  "application-overview.enrollmentInfo.completionStatus": "Estado de finalización", // Key for the user's completion status
  "application-overview.enrollmentInfo.enrolledOn": "Inscrito el", // Key for the date the user enrolled for the program or course
  "application-overview.enrollmentInfo.heading": "Información de inscripción", // Title for enrollment information
  "application-overview.enrollmentInfo.status.admin.revoke": "Cancelado por el administrador", // Cancelled application status for admin initiated cancel
  "application-overview.enrollmentInfo.status.cancelled": "Cancelado por el proveedor", // Cancelled application status
  "application-overview.enrollmentInfo.status.employee.revoke": "Cancelado por el empleado", // Cancelled application status for employee initiated cancel
  "application-overview.enrollmentInfo.status.fail": "Reprobado", // Failed application status
  "application-overview.enrollmentInfo.status.jobseeker.revoke": "Cancelado por la persona en busca de empleo", // Cancelled application status for jobseeker initiated cancel
  "application-overview.enrollmentInfo.status.member.revoke": "Cancelado por el miembro", // Cancelled application status for member initiated cancel
  "application-overview.enrollmentInfo.status.pass": "Aprobado", // Passed application status
  "application-overview.enrollmentInfo.status.pending": "Pendiente", // Pending application status
  "application-overview.enrollmentInfo.status.withdrawn": "Dado de baja", // Withdrawn application status
  "application-overview.existingApplication.header": "{displayName} solicitó esto en el pasado.", // Title for the re-request alert banner
  "application-overview.existingApplication.status.cancelled": "{displayName} canceló la solicitud anterior.", // subtext for when the previous application for a user was cancelled
  "application-overview.existingApplication.status.declined": "{displayName} solicitó esto anteriormente y fue rechazado.", // subtext for when the previous application for a user was declined
  "application-overview.existingApplication.status.declined.noDecliner": "La solicitud fue rechazada.", // subtext for when the previous application for a user was declined and the decliner can't be determined
  "application-overview.existingApplication.status.failed": "{displayName} reprobó este {type} anteriormente.", // subtext for when the previous application for a user was failed
  "application-overview.existingApplication.status.withdrawn": "{displayName} se retiró de este {type} anteriormente.", // subtext for when the previous application for a user was withdrawn
  "application-overview.existingApplication.subtext.link": "Ver la solicitud anterior.",
  "application-overview.general.cost": "Costo", // Key for the cost of the program or course requested
  "application-overview.general.country": "País", // Key for the country of the program or course requested
  "application-overview.general.department": "Departamento", // key for the department of the user that is requesting the program or course
  "application-overview.general.email": "Correo electrónico", // Key for the state of province of the user who submitted the request and when they submitted it
  "application-overview.general.goal": "Programa previsto", // Key for activity request goal
  "application-overview.general.nextSession": "Siguiente sesión", // Key for the next session of the program or course
  "application-overview.general.requestId": "ID de solicitud", // Key for the request's ID
  "application-overview.general.requestReason": "Motivo de la solicitud", // Key for the reason given by the user for requesting the program or course
  "application-overview.general.role": "Rol", // Key for the user's role within their company
  "application-overview.general.stateOrProvince": "Estado/Provincia", // Key for the state of province of the user who submitted the request and when they submitted it
  "application-overview.general.submitted": "Enviado", // Key for the user who submitted the request and when they submitted it
  "application-overview.general.submittedOn": "{displayName} el {applicationDate}", // A connector used within the value for 'Submitted' to join the user who submitted the request and the submission date
  "application-overview.heading": "Descripción general de la solicitud", // Top-level heading for the application overview details
  "application-overview.requestConfirmation.approve": "Aprobar", // Label for approve button
  "application-overview.requestConfirmation.decline": "Rechazar", // Label for decline button
  "application-overview.requesterAction.revoke": "Cancelar solicitud", // Label for decline button
  "application-overview.revokeInfo.cancelled": "Se canceló su solicitud de {activity}.",
  "application-overview.revokeInfo.tooltip": "Su solicitud ya se envió a {provider} para su registro. Comuníquese con el equipo de soporte de SkillsWave para obtener ayuda.", // do not translate "SkillsWave"
  "application-overview.status": "Estado", // The key used in off-screen text to indicate the request's progress to screen reader users
  "application-overview.wizardLabels.course.step.4": "Curso completado", // Step 4 of 4 in the wizard/progress tracker for an individual request
  "application-overview.wizardLabels.employee.step.3": "Empleado registrado", // Step 3 of 4 in the wizard/progress tracker for an employee request
  "application-overview.wizardLabels.jobseeker.step.3": "Persona en busca de empleo registrada", // Step 3 of 4 in the wizard/progress tracker for a jobseeker request
  "application-overview.wizardLabels.member.step.3": "Miembro registrado", // Step 3 of 4 in the wizard/progress tracker for a member request
  "application-overview.wizardLabels.program.step.4": "Programa completado", // Step 4 of 4 in the wizard/progress tracker for an individual request
  "application-overview.wizardLabels.step.1": "Solicitud recibida", // Step 1 of 4 in the wizard/progress tracker for an individual request
  "application-overview.wizardLabels.step.2": "Solicitud aprobada", // Step 2 of 4 in the wizard/progress tracker for an individual request

  "application-table.animationText": "Procesando exportación", // Label for export button when it is animating
  "application-table.csv.title": "Lista de solicitudes", // Title of csv export; today's date is appended to this title along with the '.csv' file extension
  "application-table.employee.search.label": "Encuentre una ID, un empleado, un programa o un curso", // The label for the application table search bar
  "application-table.export": "Exportar solicitudes", // Label for export button
  "application-table.export.complete": "Exportación finalizada", // toast message for export complete
  "application-table.export.error": "Se produjo un error. Vuelva a intentarlo.", // toast message for export error
  "application-table.export.processing": "Procesando exportación", // toast message for export processing
  "application-table.filter.course.ariaLabel": "Filtrar por curso", // Aria label for course filter
  "application-table.filter.course.label": "Curso", // Text for course filter
  "application-table.filter.course.tooltipText": "Filtrar solicitudes por nombre de curso o programa.", // Tooltip for course filter
  "application-table.filter.employer.ariaLabel": "Filtrar por empleador", // Aria label for employer filter
  "application-table.filter.employer.tooltipText": "Filtrar solicitudes por nombre de empleador.", // Tooltip for employer filter
  "application-table.filter.filterBy": "Filtrar por:", // Label for requests filter
  "application-table.filter.provider.ariaLabel": "Filtrar por proveedor", // Aria label for provider filter
  "application-table.filter.provider.smo": "Solicitud externa", // Filter name for applications that come from outside the system
  "application-table.filter.provider.tooltipText": "Filtrar solicitudes por nombre de proveedor", // Tooltip for provider filter
  "application-table.filter.status.ariaLabel": "Filtrar por estado", // Aria label for status filter
  "application-table.filter.status.label": "Estado", // Text for status filter
  "application-table.filter.status.tooltipText": "Filtrar por estado de la solicitud, p. ej.: Aprobada, Finalizada.", // Tooltip for status filter
  "application-table.jobseeker.search.label": "Encuentre una ID, una persona en busca de empleo, un programa o un curso", // The label for the application table search bar
  "application-table.member.search.label": "Encuentre una ID, un miembro, un programa o un curso", // The label for the application table search bar
  "application-table.search.placeholder": "Buscar solicitudes…", // The plaveholder for the application table search bar
  "application-table.submitOwn": "Enviar mi propia solicitud", // Label for submit own request button
  "application-table.table.activity": "Programa/curso", // Key for the program or course the user requested
  "application-table.table.employee": "Empleado", // Key for the employee's name
  "application-table.table.empty": "No hay solicitudes en este momento.", // Text displayed when there are no requests in the table
  "application-table.table.id": "ID", // Key for the request ID, ignore-untranslated
  "application-table.table.jobseeker": "Persona en busca de empleo", // Key for the jobseeker's name
  "application-table.table.member": "Miembro", // Key for the member's name
  "application-table.table.noResults": "Verifique la ortografía o intente realizar una nueva búsqueda", // No results message when filtered to no results
  "application-table.table.payment": "Pago", // Key for user payment in provider view
  "application-table.table.price": "Precio", // Key for program/course price
  "application-table.table.session": "Sesión", // Key for program or course's next session
  "application-table.table.sortingButtonTitle": "Ordenar por {header}", // Sorting button by header items
  "application-table.table.status": "Estado", // Key for request status
  "application-table.table.submitted": "Enviado", // Key for the date the user submitted the request

  "application-table-row.approvedAmount": "Monto aprobado", // The key used in off-screen text to indicate the approved amount to screen reader users
  "application-table-row.aria-label": "Solicitud de {employeeName} para {activityTitle}. Enviado el {applicationDate}, Próxima sesión: {nextSessionDate}, Precio: {activityCost}. Estado de la solicitud: {status}. ID de solicitud: {slug} ", // The aria-label for the application table row
  "application-table-row.days.past.plural": "Hace {numDays} días", // Describes how many days ago an application was submitted if more than 1 day
  "application-table-row.days.past.singular": "Hace 1 día", // Describes how many days ago an application was submitted if it was 1 day ago
  "application-table-row.days.today": "Hoy", // Displays when submitted on same day as viewer
  "application-table-row.paid": "Pagado", // Label text to indicate the amount the user paid for the program or course

  "application-transaction-overview.header": "Solicitar descripción general de la transacción", // header for the transaction overview card

  "apply-activity.OR": "O",
  "apply-activity.agree.title": "Términos de uso", // Section where user has to agree to certain policies
  "apply-activity.breadcrumb": "Volver al programa", // Link text to navigate to the program view page
  "apply-activity.buttonClose": "Cerrar",
  "apply-activity.course.addProgram": "Seleccione un programa", // Dropdown option to add a program
  "apply-activity.course.addProgram-tagList-description": "Programas principales seleccionados como objetivo para el curso", // Description for the tag list of parent programs
  "apply-activity.course.programsRelated": "{activityTitle} forma parte del/de los programa(s) a continuación. Si está trabajando para completar un programa, informe a su empleador para que pueda apoyar su progreso", // Section where user is informed of related programs
  "apply-activity.course.prompt": "Está solicitando el siguiente curso:", // Course request prompt
  "apply-activity.course.request.title": "Curso", // Course request section title
  "apply-activity.courses.prompt": "¿Para qué curso desea solicitar aprobación?", // Course selection prompt when multiple courses are available
  "apply-activity.customCondition": "A continuación, se presenta una condición adicional según la política del programa:", // If additional condition is enabled by employer, prompt user that they need to accept it
  "apply-activity.documentTitle": "Mi solicitud: SkillsWave", // Document title when viewing the apply-activity page. Do not translate "SkillsWave".
  "apply-activity.employer": "mi empleador", // Placeholder text for the user's employer
  "apply-activity.error.blank.departmentName": "El nombre del departamento no puede estar vacío", // Error prompt shown to magic link users when applying for program in the event thery entered a department name equivalent to blank spaces.
  "apply-activity.error.blank.name": "El nombre o el apellido no pueden estar en blanco.", // Error prompt shown to magic link users when applying for program in the event they entered a first and/or last name equivalent to blank spaces.
  "apply-activity.error.cantApply": "No puede realizar ninguna solicitud de programa/curso, a menos que sea un empleado.", // Error prompt shown to SkillsWave admins or academic partners when attempting to request a program/course
  "apply-activity.error.missing.approver": "Falta el aprobador solicitado", // Error prompt that the requested approver is missing
  "apply-activity.error.missing.required": "Falta un campo obligatorio", // Error prompt that some required field is missing
  "apply-activity.error.noselection": "Debe seleccionar un programa o curso para solicitar la aprobación", // Error prompt that a program or course must be selected
  "apply-activity.existingApp.header": "Usted solicitó esto en el pasado.",
  "apply-activity.existingApp.subtext": "No se preocupe, puede solicitarlo ahora.",
  "apply-activity.existingApp.subtext.link": "También puede revisar su solicitud anterior",
  "apply-activity.introduction.double": "Esta solicitud será aprobada por: <b>{approverOne}</b> y <b>{approverTwo}</b>", // Text to inform user of both approvers for their application
  "apply-activity.introduction.single": "Esta solicitud será aprobada por: <b>{approver}</b>", // Text to inform user of approver for their application
  "apply-activity.jobseeker.employer": "mi organización", // Placeholder text for the user's organization
  "apply-activity.loading": "Cargando programa/curso", // Placeholder loading text
  "apply-activity.manager.label": "Mi gerente es", // Label to show manager
  "apply-activity.manager.name.label": "Nombre del gerente", // Label to show manager's name
  "apply-activity.manager.toggle.link": "Este no es mi gerente", // Link to remove manager
  "apply-activity.member.employer": "mi asociación", // Placeholder text for the user's association
  "apply-activity.motivation.title": "Mi motivación", // Title for section where user explains their reason for taking a course
  "apply-activity.name.double": "{approverOne} y {approverTwo}", // Name formatter for condition where there's two approvers
  "apply-activity.noManager.label": "Su solicitud se dirigirá a su gerente del programa de aprendizaje", // Label to show that no manager is selected
  "apply-activity.noManager.toast": "No tiene un gerente o patrocinador del programa en la lista. Comuníquese con el equipo de soporte.", // Error prompt that user's account has no manager or sponsor associated with it
  "apply-activity.noManager.toggle.link": "Dirigir mi solicitud a", // Link to add manager
  "apply-activity.personalInfo.country": "País de residencia", // Prompt for user to enter their country of residence
  "apply-activity.personalInfo.country.notSupported": "Otros países no están soportados", // Error when someone is applying to a location-restricted from a non-supported country
  "apply-activity.personalInfo.customFields.costCode": "Código de costo", // Prompt to enter Job code
  "apply-activity.personalInfo.customFields.employeeNumber": "Número de empleado", // Prompt to enter employee Number
  "apply-activity.personalInfo.customFields.jobCode": "Código de trabajo", // Prompt to enter cost code
  "apply-activity.personalInfo.department": "Departamento", // Prompt to enter Department name
  "apply-activity.personalInfo.description": "Si se aprueba su solicitud, su nombre y ocupación se compartirán con la institución para fines de registro.", // Notify to the user that their name and job title (name being manually entered in a subsequent field) will be shared with the educational institute.
  "apply-activity.personalInfo.email": "Dirección de correo electrónico", // Prompt to enter Department name
  "apply-activity.personalInfo.firstName": "Nombre", // In event where the user logged in with the magic link, we do not know the user's first name, and hence it must be requested here.
  "apply-activity.personalInfo.jobTitle": "Ocupación", //  Prompt for user to enter job title
  "apply-activity.personalInfo.lastName": "Apellido", // In event where the user logged in with the magic link, we do not know the user's last name, and hence it must be requested here.
  "apply-activity.personalInfo.phone": "Número de teléfono", // Prompt for user to enter their phone number
  "apply-activity.personalInfo.phone.invalid": "El número de teléfono no es válido", // Error message for when phone number is invalid
  "apply-activity.personalInfo.postalCode": "Código postal", // Prompt for user to enter their postal code
  "apply-activity.personalInfo.postalCode.inPersonLocationRestricted": "{postalCode} (dentro de un radio de {range} km de la institución)",
  "apply-activity.personalInfo.postalCode.invalid": "El código postal no es válido.", // Message that appears to inform the user that the postal code they entered is invalid
  "apply-activity.personalInfo.postalCode.invalidOptions": "Debe proporcionar tanto el radio como el código postal de origen para validar la distancia", // Message that appears to inform the user that there is a configuration error with the activity postal code validation
  "apply-activity.personalInfo.postalCode.tooFar": "This program requires you to attend in-person. Your location is too far away.", // Message that appears to inform the user that the postal code they entered is too far from the learning centre
  "apply-activity.personalInfo.postalCode.tooltip": "Su código postal se utiliza para calcular el impuesto sobre las ventas.", // Tooltip to explain why postal code is collected
  "apply-activity.personalInfo.preferredEmail.tooltip": "El correo electrónico se utilizará para las comunicaciones. Ingrese una dirección de correo electrónico que revise regularmente.",
  "apply-activity.personalInfo.province": "Provincia", // User location province (if user in Canada)
  "apply-activity.personalInfo.state": "Estado", // User location state
  "apply-activity.personalInfo.stateOrProvince": "Provincia/estado", // User location state/province
  "apply-activity.personalInfo.title": "Algunas cosas sobre mí mismo", // Title for section. This section prompts the user to enter additional personal information because their login method (magic link) does not provide certain required information (e.g. first & last name) for proper registration.
  "apply-activity.personalInfo.transcript": "Origen del expediente académico", // User transcript
  "apply-activity.personalInfo.transcriptHeader": "¿Dónde recibió su expediente académico?",
  "apply-activity.personalInfo.zipCode": "Código postal", // Prompt for user to enter their ZIP code
  "apply-activity.personalInfo.zipCode.invalid": "El código postal no es válido.", // Message that appears to inform the user that the ZIP code they entered is invalid
  "apply-activity.personalInfo.zipCode.tooltip": "Su código postal se utiliza para calcular el impuesto sobre las ventas.", // Tooltip to explain why ZIP code is collected
  "apply-activity.program.prompt": "Está solicitando el siguiente programa:", // Program request prompt
  "apply-activity.program.request.title": "Programa", // Program request section title
  "apply-activity.programcourses.prompt": "¿Está solicitando todo el programa o un curso específico?", // Activity selection prompt when user can choose between a whole program or a specific course
  "apply-activity.provider": "el proveedor de educación", // Placeholder text for the education provider that created this activity
  "apply-activity.reason.prompt": "Esto ayudará a mi desarrollo profesional…", // Prompt for user to explain reason for taking the program
  "apply-activity.registration.title": "Registro", // Request registration section title
  "apply-activity.review.button": "Revise mi solicitud", // Form button to review draft application to activity
  "apply-activity.review.introduction": "Solicito: <b>{activity}</b>", // Explanation of what the request submit page is for
  "apply-activity.review-submit-own.introduction": "Usted solicita: <b>{activity}</b>", // Explanation of what the request submit page is for
  "apply-activity.reviewAdmissionRequirements.button": "Revisar requisitos de admisión",
  "apply-activity.select.title": "Lo que quiero aprender", // Section title to select activity to apply to
  "apply-activity.submit.button": "Enviar solicitud", // Submit request button text
  "apply-activity.terms.agree": "Acepto lo dispuesto por SkillsWave en relación con:", // Prompt text for Terms of Use for the user to agree to. (Part 1)
  "apply-activity.terms.link": "Términos de uso.", // Link text for Terms of Use for the user to agree to. (Part 2)
  "apply-activity.termsOfUse": "Comprendo que los registros de educación y la información relacionada con mi participación en el Programa seleccionado, incluido mi estado de finalización, calificaciones e inscripción (“Datos de finalización”) pueden estar protegidos por la ley de privacidad aplicable. Por el presente, autorizo a {provider} a divulgar los Datos de finalización a SkillsWave o a {provider} con el fin de que proporcionen los Servicios. Además, autorizo a SkillsWave a proporcionar la información sobre mí necesaria para mi registro en el curso o programa seleccionado a {provider}.",
  "apply-activity.title": "Mi solicitud", // Request submission page title
  "apply-activity.tos": "Términos de uso",

  "area-of-interest-chip.career-goal.icon": "Objetivo profesional seleccionado", // Icon for the career goal area of interest chip
  "area-of-interest-chip.remove-button.aria-lebel": "Eliminar el elemento seleccionado", // Aria label for the remove button on the area of interest chip
  "area-of-interest-chip.skill-set.icon": "Conjunto de habilidades seleccionado", // Icon for the skill set area of interest chip

  "audit.action.activity_activeStateChange": "Se cambió el estado activo de la actividad", // Display name for audit type activeStateChange
  "audit.action.activity_costUpdated": "Costo de actividad actualizado", // Display name for audit type activeStateChange
  "audit.action.activity_created": "Actividad creada", // Display name for audit type activityCreated
  "audit.action.activity_deleted": "La actividad se ha eliminado", // Display name for audit type activityDeleted
  "audit.action.application_approved": "Solicitud aprobada", // Display name for audit type requestApproved
  "audit.action.application_approved_manager": "Solicitud aprobada por el gerente", // Display name for audit type managerApproved
  "audit.action.application_approved_sponsor": "Solicitud aprobada por el patrocinador", // Display name for audit type sponsorApproved
  "audit.action.application_cancelReasonSubmitted": "Motivo de cancelación enviado", // Display name for audit type cancelReasonSubmitted
  "audit.action.application_completedDateSubmitted": "Fecha de finalización enviada", // Display name for audit type completedDateSubmitted
  "audit.action.application_completionStatusSubmitted": "Estado de finalización enviado", // Display name for audit type completionDateSubmitted
  "audit.action.application_enrollDateSubmitted": "Fecha de inscripción enviada", // Display name for audit type enrollmentDateSubmitted
  "audit.action.application_refundPctSubmitted": "Porcentaje de reembolso enviado", // Display name for audit type refundPercentageSubmitted
  "audit.action.application_rejected": "Solicitud rechazada", // Display name for audit type requestRejected
  "audit.action.application_rejected_manager": "Solicitud rechazada por el gerente", // Display name for audit type managerRejected
  "audit.action.application_rejected_sponsor": "Solicitud rechazada por el patrocinador", // Display name for audit type sponsorRejected
  "audit.action.application_started": "Solicitud iniciada", // Display name for audit type requestStarted
  "audit.action.audit_record_change": "Se modificaron los registros de auditoría",
  "audit.action.collect_details_page_submitted": "Página de recopilación de detalles enviada", // Display name for audit record showing user submitted the form on the Collect Email splash page
  "audit.action.collect_details_page_viewed": "Página de recopilación de detalles vista", // Display name for audit record showing user viewed the Collect Email splash page

  "audit.action.failed_payment_pre_authorization": "Preautorización de pago fallida",
  "audit.action.login": "Iniciar sesión", // Display name for audit type login
  "audit.action.login_linkRequested": "Se solicitó un enlace de inicio de sesión", // Display name for audit type magicLinkRequested
  "audit.action.login_linkUsed": "Se utilizó un enlace de inicio de sesión", // Display name for audit type magicLinkUsed
  "audit.action.logout": "Cerrar sesión", // Display name for audit type logout
  "audit.action.requestPaid": "Solicitud pagada", // Display name for audit type requestPaid
  "audit.action.role_added": "Rol añadido al catálogo de roles", // Display name for audit type role_added
  "audit.action.role_assign_user_to_role": "Rol asignado al usuario", // Display name for audit type assign_user_to_role
  "audit.action.role_deleted": "Rol eliminado del catálogo de roles", // Display name for audit type role_deleted
  "audit.action.role_permission_assigned_to_role": "Permiso asignado al rol", // Display name for audit type permission_assigned_to_role
  "audit.action.role_permission_unassigned_from_role": "Permiso desasignado del rol", // Display name for audit type permission_unassigned_from_role
  "audit.action.role_unassign_user_from_role": "Rol desasignado del usuario", // Display name for audit type unassign_user_from_role
  "audit.action.tenant_logo_email_uploaded": "Se cargó el logotipo del correo electrónico del inquilino", // Display name for audit type tenantEmailAssetsUploaded
  "audit.action.tenant_logo_image_url_changed": "Se modificó la URL del logotipo del inquilino", // Display name for audit type tenantLogoImageUrlChanged
  "audit.action.tenant_logo_image_url_email_changed": "Se modificó la URL del logotipo de correo electrónico del inquilino", // Display name for audit type tenantLogoImageUrlEmailChanged
  "audit.action.tenant_logo_uploaded": "Se cargó el logotipo del inquilino", // Display name for audit type tenantLogoAssetsUploaded
  "audit.action.user_created": "Usuario creado", // Display name for audit type userCreated
  "audit.action.user_failed_freshdesk": "La creación del contacto en Freshdesk falló", // Display name for audit type user_failed_freshdesk
  "audit.action.user_updated": "Usuario actualizado", // Display name for audit type userUpdated
  "audit.action.visibility_record_change": "Registros de visibilidad cambiados", // Display name for audit type visibility_record_change

  "auth-error.body": "Intentó iniciar sesión con un método de autenticación diferente al que utilizó durante el registro. Vuelva a intentarlo utilizando el método de autenticación original.", // Error text when public portal users try to create another account with existing email
  "auth-error.button.label": "Volver a intentarlo", // Label for the button on the auth error page
  "auth-error.title": "Lo sentimos.", // Error title when public portal users try to create another account with existing email

  "career-explorer.enable": "Habilitar el explorador de carreras",
  "career-explorer.toggleSuccess": "Explorador de carreras activado",
  "career-stream.headingIntro": "Recomendado para {careerName}",
  "career-stream.topSkills": "Habilidades principales de emparejamiento: {listOfSkills}", // Text introducing top skills associated with a stream followed by a comma-separated list of the top skills

  "common.startDate": "Fecha de inicio", // The course/programs start date label - reused in multiple contexts
  "common.startDateType": "Tipo de fecha", // The course/programs start date type label - reused in multiple contexts

  "components.form-element.emailInvalid": "El correo electrónico es inválido", // Error message for invalid email address
  "components.form-element.permissions.valueMissing": "Se debe seleccionar al menos un permiso para este rol.",
  "components.form-element.valueMissing": "{label} es obligatoria.",

  "confirmation-dialog.actionMessage.cantBeUndone": "No es posible deshacer esta acción.", // Action message displayed in dialog before confirming
  "confirmation-dialog.activateUser.confirmButton": "Activar usuario", // button label for confirmation dialog on the activate users confirmation
  "confirmation-dialog.activateUser.description": "¿Está seguro de que desea reactivar el acceso de <bold>{fullName}</bold> al Sistema Administrativo de Skillswave? Al usuario se le asignarán los roles que tenía previamente.", // Description for dialog to activate user
  "confirmation-dialog.activateUser.title": "Confirmación de activación del usuario", // Description for title to activate user confirmation dialog
  "confirmation-dialog.approveRequest.button": "Aprobar", // Primary button label to approve request
  "confirmation-dialog.approveRequest.description": "Confirme que desea aprobar la solicitud:", // Description for approve request dialog
  "confirmation-dialog.approveRequest.title": "Aprobar solicitud", // Title for approve request dialog
  "confirmation-dialog.cancel": "Cancelar", // Abort confirmation dialog
  "confirmation-dialog.cancelledOrWithdrawn.employee.footerDescription": "Tras la confirmación, se notificará de inmediato al empleado y a las partes interesadas de la empresa. El saldo de asistencia educativa del empleado se restablecerá según el monto de reembolso correspondiente.", // Footer for dialog to confirm cancelled or withdrawn status
  "confirmation-dialog.cancelledOrWithdrawn.jobseeker.footerDescription": "Tras la confirmación, se notificará de inmediato a la persona en busca de empleo y a las partes interesadas de la empresa. El saldo de asistencia educativa de la persona en busca de empleo se restablecerá según el monto de reembolso correspondiente.", // Footer for dialog to confirm cancelled or withdrawn status
  "confirmation-dialog.cancelledOrWithdrawn.member.footerDescription": "Tras la confirmación, se notificará de inmediato al miembro y a las partes interesadas de la empresa. El saldo de asistencia educativa del miembro se restablecerá según el monto de reembolso correspondiente.", // Footer for dialog to confirm cancelled or withdrawn status
  "confirmation-dialog.cancelledStatus.description.1": "Confirme que", // Part 1: Description for cancelled status dialog. Ends with 'confirm that {activityTitle}'
  "confirmation-dialog.cancelledStatus.description.2": "se canceló el {formattedCompletionDate} y se le debe un reembolso del {refundPct}% a {username}.", // Part 2: Description for cancelled status dialog
  "confirmation-dialog.completionStatus.title": "Confirmar estado de finalización:", // Title of dialog for completion status changes
  "confirmation-dialog.confirm": "Confirmar", // Confirm contents in confirmation dialog
  "confirmation-dialog.content.approvedAmount": "Monto aprobado", // Key for the approved Amount
  "confirmation-dialog.content.cost": "Costo", // Key for program/course cost
  "confirmation-dialog.content.date": "Fecha", // Key for the date the request status changed
  "confirmation-dialog.content.goal": "Programa previsto", // Key for activity request goal
  "confirmation-dialog.content.pending": "Pendiente", // Approved amount pending
  "confirmation-dialog.content.refundPct": "Porcentaje de reembolso", // Key for refund percentage for cancelled or withdrawn courses/programs
  "confirmation-dialog.content.status": "Estado", // Key for request status
  "confirmation-dialog.deactivateUser.confirmButton": "Desactivar usuario", // text for confirmation dialog on the Delete User Roles.
  "confirmation-dialog.deactivateUser.description": "¿Está seguro de que desea desactivar el acceso de <bold>{fullName}</bold> a la Configuración Administrativa de Skillswave? Desactivar al usuario no eliminará los roles asignados.", // text for confirmation dialog on the Delete User Roles.
  "confirmation-dialog.deactivateUser.noUserNameLabel": "este usuario", // text for confirmation dialog users with no name.
  "confirmation-dialog.deactivateUser.title": "Confirmación de desactivación del usuario", // header for confirmation dialog on the Delete User Roles.
  "confirmation-dialog.declineRequest.button": "Rechazar", // Primary button label to decline request
  "confirmation-dialog.declineRequest.description": "Confirme que desea rechazar la solicitud:", // Description for decline request dialog
  "confirmation-dialog.declineRequest.title": "Rechazar la solicitud", // Title for decline request dialog
  "confirmation-dialog.doNotShowAgain": "No volver a mostrarme esto hasta mi próximo inicio de sesión.", // Label for checkbox to not show confirmation dialog again
  "confirmation-dialog.enrollmentDate.description": "Confirme que {username} se haya registrado en <bold>{activityTitle}</bold> el {formattedEnrollDate}.", // Description for dialog to confirm enrollment date registered for {activityTitle}
  "confirmation-dialog.enrollmentDate.title": "Confirmar fecha de inscripción:", // Title for dialog to confirm enrollment date
  "confirmation-dialog.failedStatus.description": "Confirme que {username} no haya aprobado <bold>{activityTitle}</bold> el {formattedCompletionDate}.", // Description for dialog to confirm failed status failed {activityTitle}
  "confirmation-dialog.failedStatus.employee.footerDescription": "Tras la confirmación, se notificará de inmediato al empleado.", // Footer for dialog to confirm failed status
  "confirmation-dialog.failedStatus.jobseeker.footerDescription": "Tras la confirmación, se notificará de inmediato a la persona en busca de empleo.", // Footer for dialog to confirm failed status
  "confirmation-dialog.failedStatus.member.footerDescription": "Tras la confirmación, se notificará de inmediato al miembro.", // Footer for dialog to confirm failed status
  "confirmation-dialog.passedStatus.description.1": "Confirme que {username} aprobó", // Part 1: Description for passed status dialog. Ends with 'passed {activityTitle},'
  "confirmation-dialog.passedStatus.description.2": "el {formattedCompletionDate}.", // Part 2: Description for passed status dialog
  "confirmation-dialog.passedStatus.employee.footerDescription": "Tras la confirmación, se notificará de inmediato al empleado y a las partes interesadas de la empresa.", // Footer for dialog to confirm passed status
  "confirmation-dialog.passedStatus.jobseeker.footerDescription": "Tras la confirmación, se notificará de inmediato a la persona en busca de empleo y a las partes interesadas de la empresa.", // Footer for dialog to confirm passed status
  "confirmation-dialog.passedStatus.member.footerDescription": "Tras la confirmación, se notificará de inmediato al miembro y a las partes interesadas de la empresa.", // Footer for dialog to confirm passed status
  "confirmation-dialog.removeRole.button-text.confirm": "Eliminar el rol",
  "confirmation-dialog.removeRole.description.assigned": "¿Estás seguro de que quieres eliminar el rol <bold>{roleName}</bold>? Esto eliminará el rol de <bold>{usersWithRole} usuarios</bold>.",
  "confirmation-dialog.removeRole.description.assigned-and-orphaned": "¿Estás seguro de que quieres eliminar el rol <bold>{roleName}</bold>? Esto eliminará el rol de <bold>{usersWithRole} usuarios</bold> y dejará a <bold>{usersWithNoRoleAfterDelete} usuarios</bold> sin roles asignados. Por favor, visita la pestaña Permisos de usuario para asignarles los roles necesarios.",
  "confirmation-dialog.removeRole.description.unassigned": "¿Estás seguro de que quieres eliminar el rol <bold>{roleName}</bold>? Este rol no está actualmente asignado a ningún usuario.",
  "confirmation-dialog.removeRole.title": "Confirmar Eliminación del Rol",
  "confirmation-dialog.revoke.approver-joining": "y",
  "confirmation-dialog.revoke.approvers": "Autorizadores",
  "confirmation-dialog.revoke.cancelButton": "No importa",
  "confirmation-dialog.revoke.confirmButton": "Sí, cancelar",
  "confirmation-dialog.revoke.postContentMessage": "Si cancela su solicitud, se notificará a los aprobadores. No se preocupe, puede solicitar esto nuevamente en el futuro.",
  "confirmation-dialog.revoke.title": "Cancelar solicitud",
  "confirmation-dialog.status.cancelled": "Cancelado", // Cancelled application status
  "confirmation-dialog.status.fail": "Falló", // Failed application status
  "confirmation-dialog.status.pass": "Aprobado", // Passed application status
  "confirmation-dialog.status.pending": "Pendiente", // Pending application status
  "confirmation-dialog.status.withdrawn": "Dado de baja", // Withdrawn application status
  "confirmation-dialog.withdrawnStatus.description.1": "Confirme que {username} se retiró de", // Part 1: Description for withdrawn status dialog. Ends with 'withdrew from {activityTitle},'
  "confirmation-dialog.withdrawnStatus.description.2": "el {formattedCompletionDate} y se le debe un reembolso del {refundPct}%.", // Part 2: Description for withdrawn status dialog

  "connect-account.backlink": "Volver a la aplicación", // Describing placeholder
  "connect-account.dialog.button.proceed": "Ir al paso 2", // placeholder
  "connect-account.dialog.description": "Una vez que haya creado una nueva cuenta, vuelva a SkillsWave y vaya al paso 2.", // placeholder. Do not translate "SkillsWave".
  "connect-account.dialog.title": "Cree una nueva cuenta", // Placeholder
  "connect-account.introduction": "Cree una cuenta con {providerName} y conéctela a SkillsWave. No se preocupe, solo tendrá que hacerlo una vez con {providerName}. Esta parte debe tomar 10 minutos. Luego, {providerName} demorará hasta {registrationTime} días hábiles en contactarlo. ", // Describing placeholder. Do not translate "SkillsWave".
  "connect-account.stepOne.button.create": "Cree una nueva cuenta", // placeholder
  "connect-account.stepOne.button.skip": "Ya tengo una cuenta", // placeholder
  "connect-account.stepOne.instructions.admissionsBased": "Es posible que se apliquen tarifas de admisión y la institución las cobrará. Pague estas tarifas en el sitio web de la institución.",
  "connect-account.stepOne.instructions.header": "Instrucciones", // Describing placeholder
  "connect-account.stepOne.instructions.one": "Cree una cuenta mediante: {userEmail}", // Describing placeholder
  "connect-account.stepOne.instructions.three": "Una vez que haya creado su cuenta, vuelva a SkillsWave y continúe con el paso 2.", // Describing placeholder. Do not translate "SkillsWave".
  "connect-account.stepOne.instructions.two.employee": "No pague en el sitio web de la institución; de lo contrario, no se aplicará la cobertura de su empleador.", // Describing placeholder
  "connect-account.stepOne.instructions.two.jobseeker": "No pague en el sitio web de la institución; de lo contrario, no se aplicará la cobertura de su organización.", // Describing placeholder
  "connect-account.stepOne.instructions.two.member": "No pague en el sitio web de la institución; de lo contrario, no se aplicará la cobertura de su asociación.", // Describing placeholder
  "connect-account.stepOne.summary": "Solo tendrá que hacerlo la primera vez que se registre con {providerName}", // Placeholder
  "connect-account.stepOne.support.link": "Comuníquese con el soporte", // placeholder
  "connect-account.stepOne.support.text": "¿Necesita ayuda?", // placeholder
  "connect-account.stepOne.title": "Paso 1: Cree una cuenta con {providerName}", // Describing placeholder

  "connect-account.stepThree.completion.done": "Listo", // placeholder
  "connect-account.stepThree.completion.instructions.employee": "Su empleador cubre los costos de matrícula. Haga clic en “Listo” para finalizar la conexión de su cuenta y {providerName} se comunicará con usted.", // placeholder
  "connect-account.stepThree.completion.instructions.jobseeker": "Su organización cubre los costos de matrícula. Haga clic en “Listo” para finalizar la conexión de su cuenta y {providerName} se comunicará con usted.", // placeholder
  "connect-account.stepThree.completion.instructions.member": "Su asociación cubre los costos de matrícula. Haga clic en “Listo” para finalizar la conexión de su cuenta y {providerName} se comunicará con usted.", // placeholder
  "connect-account.stepThree.completion.summary.employee": "Su empleador cubre la matrícula de este {activityType}.", // placeholder
  "connect-account.stepThree.completion.summary.jobseeker": "Su organización cubre la matrícula de este {activityType}.", // placeholder
  "connect-account.stepThree.completion.summary.member": "Su asociación cubre la matrícula de este {activityType}.", // placeholder
  "connect-account.stepThree.completion.title": "Paso 3: Complete la conexión con la cuenta y {providerName} se comunicará con usted", // placeholder
  "connect-account.stepThree.payment.instructions": "Haga clic en \"Siguiente\" para pagar su monto pendiente.", // placeholder
  "connect-account.stepThree.payment.next": "Siguiente", // placeholder
  "connect-account.stepThree.payment.summary.employee": "Siempre pagará en SkillsWave, de manera que se pueda aplicar la cobertura de su empleador (si corresponde).", // placeholder. Do not translate "SkillsWave".
  "connect-account.stepThree.payment.summary.jobseeker": "Siempre pagará en SkillsWave, de manera que se pueda aplicar la cobertura de su organización (si corresponde).", // placeholder. Do not translate "SkillsWave".
  "connect-account.stepThree.payment.summary.member": "Siempre pagará en SkillsWave, de manera que se pueda aplicar la cobertura de su asociación (si corresponde).", // placeholder. Do not translate "SkillsWave".
  "connect-account.stepThree.payment.title": "Paso 3: pague en SkillsWave", // placeholder. Do not translate "SkillsWave".
  "connect-account.stepTwo.disableTooltip": "Ingrese un correo electrónico válido", // placeholder
  "connect-account.stepTwo.goNext": "Ir al paso 3", // placeholder
  "connect-account.stepTwo.other": "Otro", // placeholder
  "connect-account.stepTwo.question": "¿Qué correo electrónico utilizó para crear su cuenta con {providerName}?", // placeholder
  "connect-account.stepTwo.studentID": "¿Cuál es su ID de estudiante de {providerName}? Su ID de estudiante se verá así: <bold>{idFormat}</bold>",
  "connect-account.stepTwo.summary": "Solo tendrá que hacerlo la primera vez que se registre con {providerName}.", // placeholder
  "connect-account.stepTwo.textInput.label": "Correo electrónico", // placeholder
  "connect-account.stepTwo.textInput.studentID": "ID de estudiante",
  "connect-account.stepTwo.title": "Paso 2: conecte su cuenta de {providerName} a SkillsWave", // placeholder. Do not translate "SkillsWave".
  "connect-account.title": "Conecte su cuenta", // Describing placeholder

  "contact-support.button": "Comuníquese con el equipo de soporte", // Label for form submission button
  "contact-support.description": "Nuestro equipo está listo para apoyarlo y responder sus preguntas.", // Description displayed under page title
  "contact-support.documentTitle": "Contacto de soporte: SkillsWave", // Text which appears in the browser's page title for the Contact Support page. Do not translate "SkillsWave".
  "contact-support.error": "Falta(n) campo(s) obligatorio(s)", // Error message if form field is missing
  "contact-support.form.email": "Su dirección de correo electrónico:", // Label for email address input field
  "contact-support.form.name": "Su nombre:", // Label for name input field
  "contact-support.form.questionType": "Tipo de pregunta", // Label for question type dropdown
  "contact-support.form.questionType.academicContent": "Tengo una pregunta sobre el contenido de un programa académico",
  "contact-support.form.questionType.employerPolicy": "Tengo una pregunta sobre la política de mi empleador",
  "contact-support.form.questionType.productFeedback": "Comentarios sobre el producto",
  "contact-support.form.questionType.technicalSupport": "Tengo un problema de soporte técnico",
  "contact-support.form.textField": "¿Cómo lo podemos ayudar?", // Label for text input field for user to enter support description
  "contact-support.thanksForMessage": "Gracias por su mensaje. Le responderemos en un plazo de dos a tres días hábiles.", // Message displayed after support form is submitted
  "contact-support.title": "Comuníquese con el equipo de soporte", // Title of contact support page

  "countries.australia": "Australia", // Australia as option for employee location selection, ignore-untranslated
  "countries.canada": "Canadá", // Canada as option for employee location selection
  "countries.other": "Otro", // Other as option for employee location selection
  "countries.united-states": "Estados Unidos", // United States as option for employee location selection

  "course.error.imagesMustBeHTTPS": "Las imágenes del curso deben estar en formato https", // error image isn't https
  "course.error.missingContentFormat": "El curso debe tener al menos un formato de contenido", // error when course has 0 content formats
  "course.error.missingFeedbackType": "El curso debe tener al menos un tipo de comentario", // error when course has 0 feedback types
  "course.error.requiredFieldMissing": "Campo(s) obligatorio(s) faltante(s) del curso", // error when missing required fields

  "defaultFAQs.a1": "Es simple, busque un curso o programa en el catálogo y haga clic en “Comenzar solicitud”. Complete el formulario de solicitud y envíelo para su aprobación. Una vez que se apruebe su solicitud, recibirá un correo electrónico que le indicará que complete el proceso de registro.", // Answer to Frequently Asked Question 1
  "defaultFAQs.a2.employee": "Todos los cursos y programas son elegibles para la facturación directa a su empleador, lo que significa que usted solo pagará los costos que superen la cobertura de su empleador. Una vez que se apruebe su solicitud, podrá pagar cualquier costo que exceda su cobertura con una tarjeta de crédito durante el proceso de registro.", // Answer to Frequently Asked Question 2
  "defaultFAQs.a2.jobseeker": "Todos los cursos y programas son elegibles para la facturación directa a su organización, es decir, usted solo pagará los costos que superen la cobertura de su organización. Una vez que se apruebe su solicitud, podrá pagar cualquier costo que exceda su cobertura con una tarjeta de crédito durante el proceso de registro.", // Answer to Frequently Asked Question 2
  "defaultFAQs.a2.member": "Todos los cursos y programas son elegibles para la facturación directa a su asociación, lo que significa que usted solo pagará los costos que superen la cobertura de su asociación. Una vez que se apruebe su solicitud, podrá pagar cualquier costo que exceda su cobertura con una tarjeta de crédito durante el proceso de registro.", // Answer to Frequently Asked Question 2
  "defaultFAQs.a3": "La mayoría de los cursos de los programas se pueden tomar individualmente sin inscribirse en la credencial completa. Sin embargo, hay algunas excepciones. Un curso que no se puede tomar de forma separada del programa no le dará la opción de solicitarlo.", // Answer to Frequently Asked Question 3
  "defaultFAQs.a4.employee": "Si desea tomar un curso de un proveedor de educación que no aparece en el catálogo, hable con su gerente sobre cómo su empleador podría financiarlo. Además, nos encantaría saber al respecto para que podamos agregarlo al catálogo. Envíe el nombre del curso y del proveedor de educación usando el bot de chat o completando el formulario de “Comunicarse con el equipo de soporte”.", // Answer to Frequently Asked Question 4
  "defaultFAQs.a4.jobseeker": "Si desea tomar un curso de un proveedor de educación que no aparece en el catálogo, hable con su gerente sobre cómo su organización podría financiarlo. Además, nos encantaría saber al respecto para que podamos agregarlo al catálogo. Envíe el nombre del curso y del proveedor de educación usando el bot de chat o completando el formulario de “Comunicarse con el equipo de soporte”.", // Answer to Frequently Asked Question 4
  "defaultFAQs.a4.member": "Si desea tomar un curso de un proveedor de educación que no aparece en el catálogo, hable con su gerente sobre cómo su asociación podría financiarlo. Además, nos encantaría saber al respecto para que podamos agregarlo al catálogo. Envíe el nombre del curso y del proveedor de educación usando el bot de chat o completando el formulario de “Comunicarse con el equipo de soporte”.", // Answer to Frequently Asked Question 4
  "defaultFAQs.a5.employee": "Las solicitudes se pueden cancelar en la página de solicitudes. Cuando haga clic en “Cancelar solicitud”, se le solicitará un motivo. Una vez enviada, la solicitud se marcará como cancelada. Su empleador o el proveedor de educación podrían comunicarse con usted para obtener más información.", // Answer to Frequently Asked Question 5
  "defaultFAQs.a5.jobseeker": "Las solicitudes se pueden cancelar en la página de solicitudes. Cuando haga clic en “Cancelar solicitud”, se le solicitará un motivo. Una vez enviada, la solicitud se marcará como cancelada. Su organización o el proveedor de educación podrían comunicarse con usted para obtener más información.", // Answer to Frequently Asked Question 5
  "defaultFAQs.a5.member": "Las solicitudes se pueden cancelar en la página de solicitudes. Cuando haga clic en “Cancelar solicitud”, se le solicitará un motivo. Una vez enviada, la solicitud se marcará como cancelada. Su asociación o el proveedor de educación podrían comunicarse con usted para obtener más información.", // Answer to Frequently Asked Question 5
  "defaultFAQs.a6": "¡No se preocupe! Su solicitud de aprendizaje se cancelará automáticamente en el sistema, lo que le permitirá enviar otra solicitud para un curso comparable. Como alternativa, si le gustaría saber cuándo se ofrecerá este curso de nuevo, comuníquese con el equipo de soporte.", // Answer to Frequently Asked Question 6
  "defaultFAQs.a7": "Comuníquese con nuestro equipo de soporte mediante el bot de chat en la esquina inferior derecha. Con el bot de chat, puede notificarnos sobre problemas o hacer preguntas mediante una serie de indicaciones. Opcionalmente, utilice el formulario “Comunicarse con el equipo de soporte” al que se puede acceder mediante el enlace que se encuentra en el pie de cada página.", // Answer to Frequently Asked Question 7
  "defaultFAQs.a8": "Por favor, revise la política de privacidad de SkillsWave en <privacy-link>https://skillswave.com/privacy-policy/</privacy-link>.", // Answer to Frequently Asked Question 8
  "defaultFAQs.q1": "¿Cómo solicito financiamiento para tomar un curso o programa?", // Default Frequently Asked Question 1
  "defaultFAQs.q2": "¿Cómo pago mi curso o programa?", // Default Frequently Asked Question 2
  "defaultFAQs.q3": "¿Puedo tomar un curso que sea parte de un programa sin tomar el programa completo?", // Default Frequently Asked Question 3
  "defaultFAQs.q4": "Estoy interesado en un proveedor de educación o curso que no se encuentra actualmente en el catálogo. ¿Cómo solicito que se agregue?", // Default Frequently Asked Question 4
  "defaultFAQs.q5": "Solicité un programa, pero luego cambié de opinión. ¿Cómo cancelo mi solicitud del curso o programa?", // Default Frequently Asked Question 5
  "defaultFAQs.q6": "Fui aprobado para un curso, pero el socio de educación lo canceló. ¿Qué opciones tengo?", // Default Frequently Asked Question 6
  "defaultFAQs.q7": "¿Cómo puedo obtener apoyo técnico o informar un problema?", // Default Frequently Asked Question 7
  "defaultFAQs.q8": "¿Cómo se procesan los datos en SkillsWave?", // Default Frequently Asked Question 8

  "edit-activity.active.checkbox": "Activo", // Label for activity active switch
  "edit-activity.addCourse.label": "Agregar curso", // Text for button to add course to program
  "edit-activity.addCourses.prompt": "¿Qué curso le gustaría agregar al programa?:", // Prompt for dropdown for selecting which course to add to the program
  "edit-activity.admissionBased.addInternationalRequirement": "Agregar requisito internacional",
  "edit-activity.admissionBased.addRequirement": "Agregar requisito",
  "edit-activity.admissionBased.checkbox": "Basado en la admisión",
  "edit-activity.admissionBased.checkboxFlagUnchecked": "Todos los requisitos de admisión serán eliminados de esta actividad después de guardar.",
  "edit-activity.admissionBased.contactEmail": "Correo electrónico de contacto para admisiones",
  "edit-activity.admissionBased.delay": "Retraso (en semanas)",
  "edit-activity.admissionBased.emptyRequirementsSaveError": "Debe tener al menos 1 requisito de admisión",
  "edit-activity.admissionBased.internationalRequirementsCount": "requisito(s) internacional(es)",
  "edit-activity.admissionBased.internationalSummary": "Estos requisitos solo se aplican a los estudiantes que no tienen un expediente académico de una institución canadiense.",
  "edit-activity.admissionBased.internationalTitle": "Requisitos para expedientes académicos internacionales",
  "edit-activity.admissionBased.label": "Configuraciones basadas en admisión",
  "edit-activity.admissionBased.potentialFee": "Tarifa potencial",
  "edit-activity.admissionBased.requirementDeletionWarning": "Debe tener al menos 1 requisito de admisión",
  "edit-activity.admissionBased.requirements": "Requisitos",
  "edit-activity.admissionBased.requirementsAfterSave": "Los requisitos se publicarán en la página de la actividad al guardar",
  "edit-activity.admissionBased.requirementsCount": "requisito(s)",
  "edit-activity.admissionBased.standardSummary": "Estos requisitos se aplican a todos los estudiantes, independientemente de su ubicación o del tipo de expediente académico.",
  "edit-activity.admissionBased.standardTitle": "Requisitos de admisión",
  "edit-activity.admissionBased.title": "Título",
  "edit-activity.allowRequesting.checkbox": "Permitir solicitud", // Label for activity requesting ability check box
  "edit-activity.bannerImageLink.label": "URL de la imagen del banner", // Label for activity banner image URL input field
  "edit-activity.cancel.button": "Cancelar", // Text for button to cancel adding a course to the activity
  "edit-activity.cardImageLink.label": "URL de la imagen de la tarjeta", // Label for activity card image URL input field
  "edit-activity.category.label": "Flujos de habilidades", // Label for activity category dropdown
  "edit-activity.certificateType.label": "Tipo de certificado", // Label for activity certificate type dropdown
  "edit-activity.code.label": "Código", // Label for activity code input field
  "edit-activity.contentFormat.checkbox": "Formato de contenido", // Label for course content format checkboxes section
  "edit-activity.core": "Principal", // Text label for core course radio button
  "edit-activity.coreOrElective.label": "Principal u optativo", // Label for radio buttons to select whether the course being added is required or an elective
  "edit-activity.courses": "Cursos", // Activities of the type course
  "edit-activity.createActivity.button": "Crear actividad", // Text for button that creates an activity when clicked
  "edit-activity.credits.label": "Créditos", // Label for activity credits input field
  "edit-activity.delivery.label": "Entrega", // Label for activity delivery dropdown
  "edit-activity.description.label": "Descripción", // Label for activity description input field
  "edit-activity.documentTitle": "Editar actividad - Administrador: SkillsWave", // Document title when editing an activity. Do not translate "SkillsWave".
  "edit-activity.documentTitle.addActivity": "Agregar actividad - Administrador: SkillsWave", // Document title when creating a new activity. Do not translate "SkillsWave".
  "edit-activity.duration.label": "Duración", // Label for activity duration dropdown
  "edit-activity.elective": "Optativo", // Text label for elective course radio button
  "edit-activity.error.selectAtLeastOneSkillStream": "Por favor, seleccione al menos un área de habilidad", // Error message for when no skill stream is selected
  "edit-activity.extractSkills.button": "Extraer habilidades de la descripción", // Text for extract skills button
  "edit-activity.extractSkills.pleaseWait": "Por favor, espere mientras se extraen las habilidades; esto puede tardar hasta un minuto.", // Text displayed while skills are being extracted
  "edit-activity.fastToComplete.checkbox": "Rápido de completar", // Label to indicate if the associated course is fast to complete (think, less than a week, etc.)
  "edit-activity.feedbackTypes.checkbox": "Tipos de comentarios", // Label for course feedback types checkboxes section
  "edit-activity.format.label": "Formato", // Label for activity format dropdown
  "edit-activity.inPerson.maxLearnerDistance": "Distance radius (in KM)",
  "edit-activity.instructionLang.label": "Idioma de la instrucción", // Label for activity instruction language dropdown
  "edit-activity.keyDifferentiator.label": "Diferenciador clave", // Label for activity key differentiator input field
  "edit-activity.numCoursesRequired.label": "Cantidad de cursos obligatorios", // Label for activity required courses count input field
  "edit-activity.numberElectives.label": "Cantidad de optativos", // Label for activity electives count input field
  "edit-activity.order.label": "Orden", // Label for add course order input field
  "edit-activity.order.placeholder": "sin orden", // Placeholder status indicator text for a course without an order
  "edit-activity.overallCost.label": "Costo total", // Label for activity overall cost input field
  "edit-activity.prerequisites.label": "Prerrequisitos", // Label for activity prerequisites input field
  "edit-activity.program.incorrectCoursesCount": "El número de cursos obligatorios es incorrecto.", // Error message for incorrect number of required or elective courses entered in activity edit/create
  "edit-activity.program.incorrectElectiveCoursesCount": "El número de cursos electivos debe ser menor o igual al número de electivos ingresado.", // Error message for incorrect number of elective courses entered in activity edit/create
  "edit-activity.registrationLink.label": "Enlace de registro", // Label for activity registration link input field
  "edit-activity.removeCourse": "Eliminar curso", // ariaLabel for button to remove course from program
  "edit-activity.removedSkills.header": "Habilidades omitidas de extracción", // Header for removed skills section
  "edit-activity.save.button": "Guardar actividad", // Text for button to save activity
  "edit-activity.saveAndClose.button": "Guardar y cerrar actividad", // Text for button to save and close activity
  "edit-activity.select": "Seleccionar una opción", // Dropdown menu prompt
  "edit-activity.selectCourse": "Seleccionar un curso", // Dropdown menu prompt for adding a new course to the program
  "edit-activity.skillExtractionError": "Error al intentar extraer habilidades de los datos de la actividad. La actividad no se ha actualizado", // The failure  message for when failing to extract skills
  "edit-activity.skills.extractedSkillsEmpty": "No se han extraído habilidades de la descripción o se omitieron todas de la extracción.", // Displayed when there are no extracted skills or all are omitted
  "edit-activity.skills.header.extractedSkills": "Habilidades extraídas", // Header for extracted skills sub-section
  "edit-activity.skills.header.manageExtracted": "Gestionar habilidades extraídas", // Header for extracted skills management sub-section
  "edit-activity.skills.header.manageOtherSkills": "Gestionar otras habilidades", // Header for sub-section that lets people manage non-extracted skills
  "edit-activity.skills.header.omittedFromExtraction": "Habilidades omitidas de extracción", // Header for the omitted extracted skills sub-section
  "edit-activity.skills.header.preview": "Vista previa de las habilidades de la actividad", // Header for sub-section that lets people preview skills as they would appear on a view-activity page
  "edit-activity.skills.omittedExtractedSkillsEmpty": "No se han omitido habilidades de extracción.", // Displayed when there are no extracted skills omitted
  "edit-activity.skills.previewEmpty": "No hay habilidades visibles para esta actividad.", // Displayed when there are no visible skills associated with the activity

  "edit-activity.specialInstructions.label": "Instrucciones especiales", // Label for activity special instructions input field
  "edit-activity.startDateType.anytime": "En cualquier momento", // The course/program starts anytime
  "edit-activity.startDateType.comingSoon": "Próximamente", // The course/program start date is coming soon
  "edit-activity.startDateType.date": "Fecha", // The course/program starts on a specific date
  "edit-activity.startDateType.unknown": "Desconocido", // The course/program start date is unknown
  "edit-activity.successfulSkillExtractionMessage": "Habilidades extraídas correctamente.", // The success message for when the skills are successfully extracted
  "edit-activity.switch.tooltip.category": "Cambio desactivado hasta que se seleccione un flujo de habilidades", // Tooltip message for disabled switch - category issue
  "edit-activity.switch.tooltip.disabled": "Cambiar desactivado, abra la página de edición de curso para resolver el problema", // Tooltip message for disabled switch - general
  "edit-activity.switch.tooltip.inactive": "Este curso no tiene programas activos", // Tooltip message for disabled switch - inactive parents
  "edit-activity.switch.tooltip.viewOnly": "Interruptor desactivado. Se requiere permiso de activación de la actividad para cambiar el estado de la actividad", // Tooltip message for disabled switch - view only
  "edit-activity.tags.label": "Etiquetas",
  "edit-activity.taxable.checkbox": "Imponible", // Label for whether an activity is taxable check box
  "edit-activity.timeCommitment.label": "Tiempo necesario", // Label for activity time commitment dropdown
  "edit-activity.title": "Editar actividad", // Edit Activity page title
  "edit-activity.title.label": "Título", // Label for activity title input field
  "edit-activity.type.label": "Tipo", // Label for activity type dropdown
  "edit-activity.updateCourse.label": "Actualizar curso", // Text for button to edit course added to program
  "edit-activity.weeklyTimeCommitment": "Tiempo semanal necesario", // Dropdown menu prompt for weekly time commitment
  "edit-activity.zeroDollarProgramActivity.checkbox": "Programa de declaración a $0", // Label for the zero dollar program checkbox
  "edit-activity.zeroDollarProgramActivity.priceAndTagMismatch": "Este curso está configurado como un programa de declaración a $0, pero el costo no es 0.", // error message when $0 program flag is set but the activity has a non zero cost
  "edit-activity.zeroDollarProgramActivity.selected": "Programa de $0 seleccionado. Programa actualizado para permitir solicitudes y costo cambiado de ${oldCost} a $0.", // Info message when $0 program flag is set and fields are auto updated

  "edit-permissions.permissions-table.activateUserRolesConfirmation.toast": "Usuario reactivado con éxito", // text for confirmation dialog on the reactivate dialog
  "edit-permissions.permissions-table.activateUserRolesConfirmationFail.toast": "La reactivación del usuario falló, por favor intente nuevamente", // text for confirmation dialog fail on the reactivate dialog
  "edit-permissions.permissions-table.deactivateUserRolesConfirmation.toast": "Usuario desactivado con éxito", // text for confirmation dialog on the deactivat dialog
  "edit-permissions.permissions-table.deactivateUserRolesConfirmationFail.toast": "La desactivación del usuario falló, por favor intente nuevamente", // text for confirmation dialog fail on the deactivate dialog
  "edit-permissions.permissions-table.filter.active": "Activo", // label for active filter on the User Permissions table.
  "edit-permissions.permissions-table.filter.inactive": "Inactivo", // label for inactive filter on the User Permissions table.
  "edit-permissions.permissions-table.filter.introductoryText": "Filtrar por", // label for introductory text on the User Permissions table.
  "edit-permissions.permissions-table.filter.status": "Estado", // label for status filter on the User Permissions table.
  "edit-permissions.permissions-table.header.action": "Acción", // label for header on the User Permissions table.
  "edit-permissions.permissions-table.header.editAdmin": "Editar administrador", // label for edit admin button on the User Permissions table.
  "edit-permissions.permissions-table.header.email": "Correo electrónico", // label for email header on the User Permissions table.
  "edit-permissions.permissions-table.header.jobTitle": "Título del trabajo", // label for job title header on the User Permissions table.
  "edit-permissions.permissions-table.header.name": "Nombre", // label for name header on the User Permissions table.
  "edit-permissions.permissions-table.header.noRoles": "No hay un rol asociado con este usuario", // no Roles text on the User Permissions table.
  "edit-permissions.permissions-table.header.permissions": "Asignaciones de Roles", // label for header on the User Permissions table.
  "edit-permissions.permissions-table.header.status": "Estado", // label for status header on the User Permissions table,
  "edit-permissions.permissions-table.searchLabel": "Buscar nombre de usuario", // label for search input on the User Permissions table.
  "edit-permissions.roles": "Roles", // Label for the Roles tab on the Permissions page. ignore-untranslated
  "edit-permissions.userPermissions": "Permisos de usuario", // Label for the User Permissions tab on the Permissions page.
  "edit-permissions.userPermissions.activeTab": "Usuarios Activos", // Label for Active Users tab on the Permissions page.
  "edit-permissions.userPermissions.addUser": "Agregar usuario", // Label for the Add User button on the User Permissions tab on the Permissions page.
  "edit-permissions.userPermissions.description": "Asigne roles a los usuarios aquí. Cada usuario puede tener varios roles asociados y sus permisos serán acumulativos. Si no se asignan roles a un usuario cuando inicie sesión en SkillsWave admin, no podrá ver ni editar nada.", // Description for the User Permissions tab on the Permissions page.
  "edit-permissions.userPermissions.error.description": "No hay usuarios para mostrar", // Label for add users action on empty state illustration
  "edit-permissions.userPermissions.error.noUsers": "No usuarios.", // Message displayed when there are no users in the User Permissions tab on the Permissions page.
  "edit-permissions.userPermissions.inactiveTab": "Usuarios Inactivos", // Label for Active Users tab on the Permissions page.

  "edit-role.cancel": "Cancelar",
  "edit-role.description": "Modifica un rol existente ajustando los permisos y la descripción.",
  "edit-role.submit": "Actualizar rol",
  "edit-role.title": "Actualizar rol",

  "edit-roles.button.deleteRole": "Eliminar Rol",
  "edit-roles.button.editRole": "Editar Rol",
  "edit-roles.create.success": "Rol creado con éxito",
  "edit-roles.createRole": "Crear rol",
  "edit-roles.delete.success": "Rol eliminado con éxito",
  "edit-roles.description": "Utiliza esta página para crear y editar roles, especificando permisos según la función laboral. Proporciona una descripción clara para cada rol para aclarar las responsabilidades. Puedes asignar varios roles a un solo usuario.",
  "edit-roles.table.action.delete": "Eliminar Rol",
  "edit-roles.table.action.edit": "Editar Rol",
  "edit-roles.table.empty.description": "Crea un nuevo rol para comenzar a configurar permisos",
  "edit-roles.table.empty.title": "Sin Roles",
  "edit-roles.table.header.actions": "Acciones",
  "edit-roles.table.header.allocatedUsers": "Usuarios Asignados",
  "edit-roles.table.header.description": "Descripción",
  "edit-roles.table.header.roleName": "Nombre del Rol",
  "edit-roles.title": "Roles", // ignore-untranslated
  "edit-roles.update.success": "Rol actualizado con éxito",

  "edit-tenant.documentTitle": "Editar organización - Administrador: SkillsWave", // Web page title when editing a tenant. Do not translate "SkillsWave".
  "edit-tenant.documentTitle.addTenant": "Agregar organización - Administrador: SkillsWave", // Web page title when creating a new tenant. Do not translate "SkillsWave".
  "edit-tenant.hybrid.disabled.tooltipText": "El modelo de aprobación híbrida no se puede habilitar cuando se selecciona el presupuesto individual.", // A tooltip description explaining why the radio button is disabled
  "edit-tenant.visibility.display.error": "Error: Actualmente visible", // Error displaying an activity that should not be visible
  "edit-tenant.visibility.display.note": "Nota: Actualmente visible en un programa diferente", // Note activity is still visible for good reason
  "edit-tenant.visibility.display.warning": "Para hacer visible este curso, apague el programa y vuelva a encenderlo", // A course in program is not visible
  "edit-tenant.visibility.search.error": "Se produjo un error con la búsqueda. Por favor, intente de nuevo después de un tiempo.", // Error occurred with search
  "edit-tenant.visibility.search.noMatch": "No se encontró ninguna coincidencia. Por favor, seleccione del menú desplegable o escriba el nombre exacto de la actividad", // No activity matches found for search criteria

  "emails.approval-requested.calloutText.1": "Tenga en cuenta que {name} no seleccionó a su gerente cuando envió esta solicitud. Es posible que haya un problema con los datos del gerente en el sistema.", // First sentence of a notice when the applicant selects "Not my manager"
  "emails.approval-requested.calloutText.link": "Si este es el caso, <link>comuníquese con nosotros</link> para que podamos ayudarlo.", // Last sentence in the notice, reminding the reader to contact support if there is a problem. Please do NOT translate <link> tags
  "emails.approval-requested.customContent.1": "{name} desea tomar {title} a través de {provider}.", // First sentence of the request application approval email
  "emails.approval-requested.customContent.2": "Revise la solicitud rápidamente para que {name} tenga tiempo de registrarse.", // Second sentence in the email
  "emails.approval-requested.external.customContent.1": "{learnerName} desea obtener la aprobación para {activityTitle}.", // A learner would like approval from their boss to expense or participate in an external training opportunity
  "emails.approval-requested.external.customContent.2": "Revise la solicitud rápidamente.", // Prompting the approving manager to review the request quickly
  "emails.approval-requested.headerOptions.text": "Se solicitó su aprobación", // Email main title header
  "emails.approval-requested.subject": "Aprobación requerida: revise la solicitud de aprendizaje de {name}", // Subject line of approval requested email

  "emails.buttons.view-request.text": "Ver solicitud", // Text for the main button in the email

  "emails.cancelled-withdrawn.customContent.apology.employee": "Lamentamos mucho que esto no haya funcionado. <link>Explore otras ofertas que cuentan con el respaldo de su empleador</link>.", // Apology for the cancellation and link to other offerings/activities
  "emails.cancelled-withdrawn.customContent.apology.jobseeker": "Lamentamos mucho que esto no haya funcionado. <link>Explore otras ofertas que cuentan con el respaldo de su organización</link>.", // Apology for the cancellation and link to other offerings/activities
  "emails.cancelled-withdrawn.customContent.apology.member": "Lamentamos mucho que esto no haya funcionado. <link>Explore otras ofertas que cuentan con el respaldo de su asociación</link>.", // Apology for the cancellation and link to other offerings/activities, if tenant is an association
  "emails.cancelled-withdrawn.customContent.expenseContact": "Si ya cargó los costos de este a través de su empresa, comuníquese con <link>{programSponsorName}</link> para conocer los próximos pasos.", // Telling the learner to contact their sponsor/manager if they've already expensed the cost of the activity with their company. Do not translate the <link> tags
  "emails.cancelled-withdrawn.customContent.refund.integrated": "Si pagó, se emitirá un reembolso por {refundPercent, number, percent} por medio de su método de pago original.", // Notifying the learner the percentage that will be refunded to them
  "emails.cancelled-withdrawn.customContent.reimbursement": "Si ya se proporcionó el reembolso para este curso o programa, tome todas las próximas medidas necesarias de acuerdo con las políticas de su empresa o comuníquese con <link>{programSponsorName}</link>.", // Reminding the learner to take any necessary next steps after being reimbursed

  "emails.failed-payment.button.text": "Actualizar aquí",
  "emails.failed-payment.customContent.action": "Actualice su método de pago para permitir que el empleado se registre en el curso.",
  "emails.failed-payment.customContent.failedPayment": "Un empleado no pudo iniciar el proceso de registro porque no pudimos cobrar el método de pago guardado.",
  "emails.failed-payment.customContent.greeting": "Hola:",
  "emails.failed-payment.header.text": "No se pudo cargar su método de pago guardado",
  "emails.failed-payment.subject": "No se pudo cargar su método de pago guardado",

  "emails.invite-requested.button.text": "Iniciar sesión ahora",
  "emails.invite-requested.customContent.greeting": "Hola,",
  "emails.invite-requested.customContent.loginRequest.1": "¡Hola! Buenas noticias: {inviterName} de {tenantName} te ha dado acceso a SkillsWave. 🎉",
  "emails.invite-requested.customContent.loginRequest.2": "SkillsWave te da acceso a miles de cursos y programas para ayudarte a desarrollar tus habilidades a tu propio ritmo.",
  "emails.invite-requested.customContent.loginRequest.3": "¿Listo para empezar?",
  "emails.invite-requested.header.text": "Tu cuenta de SkillsWave está lista",
  "emails.invite-requested.subject": "¡Has sido invitado a SkillsWave!",

  "emails.login-requested.button.text": "Iniciar sesión ahora", // Text for the main button in the email, logs user in to Wave app when clicked
  "emails.login-requested.customContent.greeting": "Hola:", // Greeting to email recipient at top of email
  "emails.login-requested.customContent.loginRequest": "Su solicitud para iniciar sesión en SkillsWave con {requesterEmail} se completó.", // Sentence in body of email, stating that the user's request to log in to SkillsWave has been completed through the receipt of this email. Do not translate "SkillsWave".
  "emails.login-requested.header.text": "Su cuenta de SkillsWave está lista", // Email main title header stating that login access/an account is ready. Do not translate "SkillsWave".
  "emails.login-requested.subject": "Su cuenta de SkillsWave está lista", // Subject line of email stating that login access/an account is ready. Do not translate "SkillsWave".

  "emails.monthly-invoice.customContent.1": "El total de sus transacciones de {month} del {year} es {salesAmount}.", // Mentioning the total sales transaction that occurred during {month} {year}
  "emails.monthly-invoice.customContent.2": "<bold>Pagar a {providerName}</bold>", // Payable to provider
  "emails.monthly-invoice.customContent.3": "Emitimos {salesInvoiceCount, plural, one {una} other {{salesInvoiceCount}}} {salesInvoiceCount, plural, one {factura} other {facturas}} <boldUnderline>anulada(s)</boldUnderline> como una manera de revisar los detalles de sus transacciones, disponible(s) para su descarga durante un período de 60 días:", // Notifying the provider to save their invoice
  "emails.monthly-invoice.customContent.4": "Guarde el informe en sus registros. NO PROCESE {salesInvoiceCount, plural, one {ESTA FACTURA} other {ESTAS FACTURAS}} PARA EL PAGO. En su lugar, emita una factura a {d2lFinanceEmail} por {salesAmount} e incluya el código de referencia “<bold>SKILLSWAVE {salesReferenceInvoiceNumber}</bold>” en su factura para recibir el pago.", // Notifying the provider to issue it to SkillsWave for a payout. Do not translate "SKILLSWAVE".
  "emails.monthly-invoice.customContent.5": "<bold>Pagar a SkillsWave</bold>", // Payable to SkillsWave
  "emails.monthly-invoice.customContent.6": "De acuerdo con el total de transacciones de {month} {year}, emitimos {revShareInvoiceCount, plural, one {una factura de participación en los ingresos} other {{revShareInvoiceCount} facturas de participación en los ingresos}} por el total de {revShareAmount}, disponible(s) para descargar por un período de 60 días:", // Notifying the provider to process the revenue share invoice for payment to SkillsWave.
  "emails.monthly-invoice.customContent.7": "Procese esta factura para el pago antes de la fecha de vencimiento e incluya el número de referencia “<bold>SKILLSWAVE {revShareReferenceInvoiceNumber}</bold>”.", // Notifying the provider to process the revenue share invoice for payment to SkillsWave. Do not translate "SKILLSWAVE".
  "emails.monthly-invoice.customContent.8": "<bold>Recordatorio:</bold> Tiene 5 días hábiles para disputar cualquier transacción a {d2lFinanceEmail}.", // Reminding the provider to dispute any transactions to SkillsWave's finance team if any
  "emails.monthly-invoice.header.text": "Su informe mensual de transacciones está listo para la revisión", // Email main title header
  "emails.monthly-invoice.negativeInvoiceInfo": "En caso de que el monto de su informe de facturación sea negativo, el monto del crédito se aplicará al informe de facturación de los siguientes meses.", // Explains our use of negative invoice items to the tenant
  "emails.monthly-invoice.number": "Número de factura: {invoiceNumber}", // Text that goes before the invoice number in the email
  "emails.monthly-invoice.subject": "[Nuevo informe de transacciones de SkillsWave] {month} {year} Listo para revisión", // Email subject line. Do not translate "SkillsWave".

  "emails.register-learner.customContent.discountCode": "Proporcione un código para que este estudiante se registre en el programa.", // Request to academic admin to provide a discount/rebate code to the learner
  "emails.register-learner.customContent.learnerApproved": "{learnerName} ha sido aprobado por {employerName} para {activityTitle} en una cobertura de hasta {approvedAmount}.", // Notifying the academic admin that a new learner has been approved by their employer, and is covered up to a certain currency value
  "emails.register-learner.header.text.codeRequested": "Código de SkillsWave solicitado", // Main header in the email, notifying the academic admin that a discount/rebate code has been requested. Do not translate "SkillsWave".
  "emails.register-learner.header.text.newLearner": "Nuevo estudiante de SkillsWave", // Main header in the email, notifying the academic admin that a new learner has been approved. Do not translate "SkillsWave".
  "emails.register-learner.subject": "Nuevo estudiante de SkillsWave", // Email subject line, notifying the academic admin that a new learner has been approved. Do not translate "SkillsWave".

  "emails.registration-request.customContent.registrationInstruction": "Utilice el correo electrónico anterior para encontrar a {learnerName} en su sistema de registro.", // Instruction to reader to use a user's email (written above this sentence) to find the user in their registration system
  "emails.registration-request.headerOptions.text": "Tiene una nueva solicitud de registro", // Main header title of the email, indicating that the academic institution has a new request from a user to register for a program or course
  "emails.request-approved.account-connect.follow-steps": " Siga los pasos a continuación para crear su cuenta de {providerName} y enviar su registro antes de que comience su {activityType}. Recomendamos actuar rápidamente, ya que algunos {activityType}s tienen capacidad limitada.",
  "emails.request-approved.account-connect.step-1.body": "Cree una cuenta con {providerName} para que pueda registrarlo en {activityTitle}.",
  "emails.request-approved.account-connect.step-1.button": "Crear cuenta",
  "emails.request-approved.account-connect.step-1.header": "Paso 1: Cree su cuenta de estudiante",
  "emails.request-approved.account-connect.step-2.body": "Después de crear su cuenta con {providerName}, conéctela a su correo electrónico y envíe su registro.",
  "emails.request-approved.account-connect.step-2.button": "Envíe su registro",
  "emails.request-approved.account-connect.step-2.header": "Paso 2: Envíe su registro",
  "emails.request-approved.button.text.reimbursement": "Envíe su registro", // Text for the main button in the email, view request and register for course/program
  "emails.request-approved.customContent.approvalMessage": "El aprobador también dejó un comentario: {approvalReason}",
  "emails.request-approved.customContent.approvedCost": "{employerName} pagará {approvedAmount} para que realice la actividad {activityTitle} a través de {providerName}. El monto que resta pagar es {employeeCost}.", // Notifying the requester of the amount that has been approved and the remaining cost
  "emails.request-approved.customContent.approvedCost.anytime": "{employerName} pagará {approvedAmount} para que puedas realizar {activityTitle} a través de {providerName}. Tu costo restante es {employeeCost}, y puedes comenzar el curso en cualquier momento.", // Notifying the requester of the amount that has been approved and the remaining cost for a course/program that starts anytime
  "emails.request-approved.customContent.approvedCost.zeroApproved": "Se aprobó su solicitud de aprendizaje para {activityTitle} mediante {providerName}. Su costo es de {employeeCost}. Vea su solicitud para completar los siguientes pasos y registrarse. Recomendamos actuar rápidamente, ya que algunos {activityType}s tienen capacidad limitada",
  "emails.request-approved.customContent.approvedCost.zeroApproved.ZeroCost": "Se aprobó su solicitud de aprendizaje para {activityTitle} mediante {providerName}. Vea su solicitud para completar los siguientes pasos y registrarse. Recomendamos actuar rápidamente, ya que algunos {activityType}s tienen capacidad limitada",
  "emails.request-approved.customContent.cancellationNote": "Los cursos y programas están sujetos a cancelación. En caso de cancelación, se le notificará de inmediato.", // Warning that some courses are subject to cancellation
  "emails.request-approved.customContent.multipleApprovalMessages": "Dos aprobadores dejaron comentarios, consulta tu solicitud para leerlos.",
  "emails.request-approved.customContent.registerSoon": "No se pierda la fecha de registro. {activityTitle} comenzará pronto.", // Reminder that that the course/program is starting soon
  "emails.request-approved.customContent.registerToday": "Regístrese hoy para asegurar su lugar, ya que algunos cursos tienen capacidad limitada.", // Reminder to register for the course as soon as possible because some courses may fill up
  "emails.request-approved.customContent.registrationDate": "{activityTitle} comienza el {activityStartDate}.", // Reminder to register before the start date. Start date takes the format of this example: June 30, 2030.
  "emails.request-approved.customContent.submitRegistration": "<bold>Envíe su registro</bold> antes de que comience su {activityType}. Recomendamos actuar rápidamente, ya que algunos {activityType}s tienen capacidad limitada.", // Reminder to register for the course as soon as possible because some courses may fill up
  "emails.request-approved.external.customContent.approvedCost": "{employerName} reembolsará {approvedAmount} del {activityCost} solicitado por {activityTitle}. Para reclamar su subsidio de empleador, pague la tarifa como se establece en la <link>política de su empleador</link>.", // The applicant's company will reimburse them for the cost of the requested activity, they will then expense the approved amount
  "emails.request-approved.external.customContent.registrationDate": "{activityTitle} comienza el {activityStartDate}.", // Reminder about the activity's start date. Start date takes the format of this example: June 30, 2030
  "emails.request-approved.header.above.noName": "Buenas noticias,", // Email main title header
  "emails.request-approved.header.above.withName": "Buenas noticias, {name}:", // Email main title header with name
  "emails.request-approved.header.text2": "Vamos a enviar su registro", // The email recipient's request has been approved
  "emails.request-approved.subject": "Su solicitud de aprendizaje fue aprobada", // The email recipient's request has been approved
  "emails.request-approved-activity-cost-changed.customContent.action": "Recomendamos solicitar la aprobación para el nuevo precio. Para hacerlo, cancele esta solicitud y envíe una nueva.", // Action a user can take
  "emails.request-approved-activity-cost-changed.customContent.priceChange": "El precio para un {activityType} que solicitó, {activityTitle}, tiene {changeType} de {oldCost} a {newCost}.", // Price for an activty type/title has decreased/increased
  "emails.request-approved-activity-cost-changed.decreased": "disminuido",
  "emails.request-approved-activity-cost-changed.header": "El precio de {activityTitle} ha cambiado", // Email header
  "emails.request-approved-activity-cost-changed.increased": "aumentado",
  "emails.request-approved-activity-cost-changed.subject": "Una actualización de precios para su solicitud", // Email subject

  "emails.request-cancelled.button.text": "Ver detalles", // Text for the main button in the email, navigates user to their request page
  "emails.request-cancelled.customContent.cancelReason": "{activityTitle} ha sido cancelado por {providerName}, a partir del {cancellationDate}. Se proporcionó el siguiente motivo:", // Sentence describing the cancellation, with the reason for the cancellation following the colon (:)
  "emails.request-cancelled.header.above.apologyNoName": "Lo sentimos,", // Email main header, if there is no learner name available
  "emails.request-cancelled.header.above.apologyWithName": "Lo sentimos, {learnerFirstName}:", // Email main title header, if there is a learner name
  "emails.request-cancelled.header.text": "El programa para el que se registró ha sido cancelado.", // Email main title header
  "emails.request-cancelled.subject": "Actualización de SkillsWave: el programa al que se inscribió {learnerName} ha sido cancelado", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".

  "emails.request-cancelled-by-employee.button.text": "Ver solicitud", // Sentence describing the cancellation, with the reason for the cancellation following the colon (:)
  "emails.request-cancelled-by-employee.customContent.apology": "Lamentamos que esto no haya funcionado. Explore otras ofertas que cuentan con el respaldo de su empleador.", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".
  "emails.request-cancelled-by-employee.customContent.cancelReason": "Canceló su solicitud para {activityTitle}, a partir del {cancellationDate}. Usted proporcionó el siguiente motivo:", // Sentence describing the cancellation, with the reason for the cancellation following the colon (:)
  "emails.request-cancelled-by-employee.customContent.mistake": "Si se trata de un error, <link>comuníquese con el equipo de soporte de SkillsWave</link>", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".
  "emails.request-cancelled-by-employee.header.text": "Canceló correctamente su solicitud.", // Email main header
  "emails.request-cancelled-by-employee.subject": "Actualización de SkillsWave: canceló correctamente su solicitud.", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".

  "emails.request-cancelled-by-employee-to-approver.customContent.cancelReason": "Le informamos que {learnerName} canceló su solicitud de {activityTitle}, a partir del {cancellationDate}. Se proporcionó el siguiente motivo:", // Sentence describing the cancellation, with the reason for the cancellation following the colon (:)
  "emails.request-cancelled-by-employee-to-approver.customContent.noAction": "No es necesario realizar ninguna acción.", // The line of text informing the approver that they don't have to take any action for this email
  "emails.request-cancelled-by-employee-to-approver.subject": "Actualización de SkillsWave: {learnerName} canceló su solicitud.", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".

  "emails.request-failed.customContent.continuedSupport.employee": "Nos encantaría continuar apoyándolo en su recorrido educativo. <link>Explore otras ofertas que cuentan con el respaldo de su empleador.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses. Please do not translate the <link> tags, just the inner text.
  "emails.request-failed.customContent.continuedSupport.jobseeker": "Nos encantaría continuar apoyándolo en su recorrido educativo. <link>Explore otras ofertas que cuentan con el respaldo de su organización.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses. Please do not translate the <link> tags, just the inner text.
  "emails.request-failed.customContent.continuedSupport.member": "Nos encantaría continuar apoyándolo en su recorrido educativo. <link>Explore otras ofertas compatibles con su asociación.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses supported by the reader's association. Please do not translate the <link> tags, just the inner text.
  "emails.request-failed.customContent.sorry": "Lamentamos mucho que este programa no haya funcionado.", // Sentence expressing sympathy for the learner because their program was unsuccessful
  "emails.request-failed.customContent.thankYou": "Gracias por utilizar SkillsWave. Esperamos que haya encontrado {activityTitle} como una actividad valiosa en su propio desarrollo profesional.", // Sentence expressing gratitude to the learner for using "Wave". Do not translate "SkillsWave".
  "emails.request-failed.header.above.greetingNoName": "Hola:", // Email main title header, greetings to learner, if there is  no first name available
  "emails.request-failed.header.above.greetingWithName": "Hola, {learnerFirstName}:", // Email main title header, greetings to learner, if there is a learner name
  "emails.request-failed.subject": "Actualización de SkillsWave: el estado de su programa se actualizó con errores.", // Subject line of email that is sent to the applicant after their request has been marked as "failed". Do not translate "SkillsWave".

  "emails.request-paid.customContent.contact": "Contacto", // Label in the email table for learner's email
  "emails.request-paid.customContent.name": "Nombre", // Label in the email table for learner's name
  "emails.request-paid.customContent.payment": "Pago", // Label in the email table for activity payment status
  "emails.request-paid.customContent.payment.text": "{cost} pagado", //  Value format in the email table for activity payment status
  "emails.request-paid.headerOptions.below.text": "Una vez que se abra el registro, registre a este estudiante dentro de {registrationTime} días hábiles", // Main title subtext for email to university admin
  "emails.request-paid.headerOptions.text": "Tiene una nueva solicitud", // Main title header for email to university admin
  "emails.request-paid.subject": "[New SkillsWave Learner] Registrar a {name} en {activityTitle}", // Subject line for email to university admin. Do not translate "SkillsWave".

  "emails.request-passed.button.text": "Ver detalles", // Text for the main button in the email, navigates user to their request page
  "emails.request-passed.customContent.continuedSupport.employee": "Nos encantaría continuar apoyándolo en su recorrido educativo. <link>Explore otras ofertas que cuentan con el respaldo de su empleador.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses. Please do not translate the <link> tags, just the inner text.
  "emails.request-passed.customContent.continuedSupport.jobseeker": "Nos encantaría continuar apoyándolo en su recorrido educativo. <link>Explore otras ofertas que cuentan con el respaldo de su organización.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses. Please do not translate the <link> tags, just the inner text.
  "emails.request-passed.customContent.continuedSupport.member": "Nos encantaría continuar apoyándolo en su recorrido educativo. <link>Explore otras ofertas compatibles con su asociación.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses supported by an association. Please do not translate the <link> tags, just the inner text.
  "emails.request-passed.customContent.skillsDeveloped": "Al completar este programa, desarrolló habilidades en {numberOfSkills, select, greaterThanThree {{0}, {1}, {2}, {3} y más} two {{0} y {1}} three {{0}, {1}, y {2}} other {{0}}}.", // Sentence describing the skills that the learner developed by taking the program. A nested select is used to differentiate between the phrases when listing the skills in placeholders, as the number of skills can vary from 1 - 4+.
  "emails.request-passed.customContent.skillsDeveloped.list": "{numberOfSkills, select, greaterThanThree {{0}, {1}, {2}, {3} y más} two {{0} y {1}} three {{0}, {1}, y {2}} other {{0}}}",
  "emails.request-passed.customContent.successful": "Ha finalizado con éxito {activityTitle} de {providerName}.", // Sentence describing the successful completion of the program from the academic provider/institution
  "emails.request-passed.header.above.congratulationNoName": "Felicitaciones,", // Email main title header, congratulations to learner on completing their program, if there no first name available
  "emails.request-passed.header.above.congratulationWithName": "Felicitaciones, {learnerFirstName}:", // Email main title header, congratulations to learner on completing their program, if there is a learner name
  "emails.request-passed.header.text": "¡Completó {activityTitle}!", // Email title header, stating that the learner has successfully completed a program/course
  "emails.request-passed.subject": "Actualización de SkillsWave: {learnerName} completó {activityTitle} correctamente", // Subject line of email that is sent to the applicant after their request has been successfully completed. Do not translate "SkillsWave".

  "emails.request-received.customContent.1": "Su solicitud de {title} a través de {providerName} fue recibida.", // First sentence of the email that gets sent to the applicant after their request has been made
  "emails.request-received.customContent.2": "Fue enviada a su empleador para su revisión.", // Second sentence in the email
  "emails.request-received.customContent.2.association": "Se envió a su asociación para su revisión.", // Second sentence in the email if employer tenant is an association
  "emails.request-received.customContent.3.association": "Si recibe la aprobación, le informaremos qué cantidad del {courseType} cubrirá su asociación.", // Second sentence in the email if employer tenant is an association
  "emails.request-received.external.customContent.1": "Se recibió su solicitud para {activityTitle}.", // First sentence of the email that gets sent to the applicant after their request has been made
  "emails.request-received.external.customContent.3": "Si usted recibe la aprobación, le informaremos de cuánto se hará cargo su empleador.", // Part of last sentence of the email that gets sent to the applicant for an external request
  "emails.request-received.headerOptions.text": "Se recibió su solicitud de aprendizaje", // Email main title header
  "emails.request-received.subject": "Se recibió su solicitud de aprendizaje", // Subject line of email that is sent to the applicant after their request has been received

  "emails.request-registered.customContent.congratulations": "¡Felicitaciones! Su registro en {activityTitle} ha sido confirmado por {providerName}. Ellos se comunicarán con usted con información sobre {activityType} escribiendo a esta dirección de correo electrónico.", // Notifying the requester of the confirmed registration and the activity start date
  "emails.request-registered.customContent.startDate": "Su {activityType} comienza el {activityStartDate}.", // Reminder of the activity's start date. Start date takes the format of this example: June 30, 2030.
  "emails.request-registered.customContent.startSoon": "Su {activityType} comenzará pronto.", // Reminder that that the course/program is starting soon
  "emails.request-registered.customContent.viewRequest": "Vea su solicitud para obtener más información.", // Sentence to encourage recipient to view their request
  "emails.request-registered.header.text": "{providerName} ha confirmado su registro", // Email title header, stating that the provider has confirmed the recipient's registration
  "emails.request-registered.subject": "{providerName} lo ha registrado en {activityTitle}", // The email recipient's request has been registered

  "emails.request-rejected.customContent.declinedReason": "La solicitud fue rechazada por el siguiente motivo: {declinedReason}", // Reason why the approval request was declined
  "emails.request-rejected.customContent.toApprover": "{declinerName} rechazó la solicitud para que {learnerName} tomara {activityTitle}.", // Sentence in email that is sent to the approver
  "emails.request-rejected.customContent.toRequester": "Lo sentimos, {learnerFirstName}, su solicitud de {activityTitle} fue rechazada.", // Sentence in email that is sent to the requester/learner
  "emails.request-rejected.headerOptions.text.toApprover": "La solicitud de aprendizaje de {learnerName} fue rechazada", // Email main title header
  "emails.request-rejected.headerOptions.text.toRequester": "Su solicitud de aprendizaje fue rechazada", // Email main title header
  "emails.request-rejected.subject.toApprover": "La solicitud de {learnerName} fue rechazada", // Subject line of email that is sent to the applicant after their request has been rejected
  "emails.request-rejected.subject.toRequester": "Su solicitud de aprendizaje fue rechazada", // Subject line of email that is sent to the applicant after their request has been rejected

  "emails.request-withdrawn.button.text": "Ver detalles", // Text for the main button in the email, navigates user to their request page
  "emails.request-withdrawn.customContent.withdrawal": "{providerName} ha indicado que a partir del {cancellationDate}, usted se retiró de {activityTitle}.", // Sentence describing the details of a learner's withdrawal from a course/program.
  "emails.request-withdrawn.header.above.apologyNoName": "Lamentamos que se vaya,", // Email main header, if there is no learner name available. Expressing sympathy/apologies that the learner withdrew from the program.
  "emails.request-withdrawn.header.above.apologyWithName": "Lamentamos que se vaya, {learnerFirstName}", // Email main title header, if there is a learner name. Expressing sympathy/apologies that the learner withdrew from the program.
  "emails.request-withdrawn.header.text": "Se retiró del programa en el que estaba registrado.", // Email main title header
  "emails.request-withdrawn.subject": "Actualización de SkillsWave: {learnerName} se retiró de un programa", // Subject line of email that is sent to the applicant after they have withdrawn from a course/program. Do not translate "SkillsWave".

  "emails.suggest.course.alternativeAction.text": "¿No puedes ver este curso? Prueba este enlace", // text for the alternative action link
  "emails.suggest.course.buttons.text": "Ver curso", // text for the action button
  "emails.suggest.course.headerOptions.text": "le ha sugerido un curso", // Email main title header
  "emails.suggest.course.subject": "{firstName} le ha sugerido un curso", // Subject line of email that is sent to the recipient when a user suggests a course for them.
  "emails.suggest.program.alternativeAction.text": "¿No puedes ver este programa? Prueba este enlace", // text for the alternative action link
  "emails.suggest.program.buttons.text": "Ver programa", // text for the action button
  "emails.suggest.program.headerOptions.text": "le ha sugerido un programa", // Email main title header
  "emails.suggest.program.subject": "{firstName} le ha sugerido un programa", // Subject line of email that is sent to the recipient when a user suggests a program for them.

  "emails.zero-dollar-request-registered.button.text": "Ver programa",
  "emails.zero-dollar-request-registered.customContent.congratulations": "Ahora estás oficialmente inscrito en {program} con {provider}. Para comenzar su viaje de aprendizaje, vaya a la página del programa y busque el plan del programa. Seleccione el curso que le interesa y envíe su solicitud. Una vez aprobado, siga las instrucciones para registrarse en el curso con {provider}.",
  "emails.zero-dollar-request-registered.customContent.note": "<bold>Nota:</bold> Deberá repetir este proceso para cada curso del programa. Para cada curso, visite la página del programa, seleccione el curso, solicite la aprobación y luego regístrese en consecuencia.",
  "emails.zero-dollar-request-registered.header.text": "¡Felicitaciones {learnerFirstName}!",
  "emails.zero-dollar-request-registered.header.text.noName": "¡Felicitaciones!",
  "emails.zero-dollar-request-registered.subject": "{providerName} te ha inscrito en {activityTitle}",

  "error.403": "¡Vaya, necesitas acceso!", // Error text when a client error 403 occurs (forbidden)
  "error.403.description": "Aún no tienes acceso a esta página. Comunícate con tu gerente para obtener los permisos adecuados.", // Description of the error text when a client error 403 occurs (forbidden)
  "error.404": "404: No encontrado", // Error text when a client error 404 occurs (page not found)

  "error.LOGIN_001": "Tu cuenta está inactiva. Por favor, contacta con tu administrador para obtener ayuda.",
  "error.LOGIN_002": "Dirección de correo electrónico inválida",
  "error.LOGIN_003": "Dominio no configurado para autenticación",
  "error.LOGIN_004": "Error al verificar el enlace de inicio de sesión",
  "error.LOGIN_005": "El enlace de inicio de sesión ya fue utilizado. Por favor, solicita uno nuevo.",
  "error.LOGIN_006": "El enlace de inicio de sesión ha expirado. Por favor, solicita uno nuevo.",
  "error.LOGIN_007": "Incompatibilidad de cookies. Por favor, solicita un nuevo enlace de inicio de sesión.",
  "error.LOGIN_008": "Error al iniciar sesión",
  "error.LOGIN_009": "Error general al crear el enlace mágico",

  "error.error-loading": "Se produjo un error al intentar cargar SkillsWave", // Error text when there was a problem loading Wave. Do not translate "SkillsWave".
  "error.invalid-attribute": "El usuario no cumple con los requisitos de atributo para {attributeName}",
  "error.maintenanceMode": "¡Vaya! Actualmente estamos realizando tareas de mantenimiento. Nuestro equipo está trabajando arduamente para mejorar su experiencia. Estaremos de vuelta pronto. ¡Gracias por su paciencia!", // Error text when the app is in maintenance mode

  "faqs.title": "Preguntas frecuentes", // Title for FAQ page

  "form.submit": "Enviar", // Submit button for forms

  "general.addActivity": "Agregar actividad", // Label for adding new activity button and page title.
  "general.admin": "Administrador", // Navigation link to Wave administration experience (privileged user only), entitlement for university admins, heading for admin list.

  "general.button-text.apply": "Aplicar", // The label for apply buttons
  "general.button-text.cancel": "Cancelar", // The label for cancel buttons
  "general.button-text.collapseAll": "Contraer todo", // Label for an action that collapses collapsible interactive elements
  "general.button-text.expandAll": "Expandir todo", // Label for an action that expands all collapsible interactive elements
  "general.button-text.export": "Exportar", // The label for export buttons
  "general.button-text.finish": "Terminar",
  "general.button-text.next": "Próximo",
  "general.button-text.no": "No", // The label for yes buttons, ignore-untranslated
  "general.button-text.previous": "Volver",
  "general.button-text.save": "Guardar", // The label for apply buttons
  "general.button-text.yes": "Sí", // The label for yes buttons

  "general.catalog-package.construction": "Construcción",
  "general.catalog-package.custom-health-sciences": "Personalizado: ciencias de la salud",
  "general.catalog-package.custom-upskill-canada": "Personalizado: Upskill Canadá",
  "general.catalog-package.general": "General", // ignore-untranslated
  "general.catalog-package.new-general": "General nuevo",
  "general.catalog-package.tech": "Desarrollo",

  "general.costTo.companyName": "Costo para {companyName}", // The label for the cost to the company
  "general.employee": "Empleado",
  "general.employer": "Empleador", // Text for employer filter, aria text and label for program employer filter dropdown, employer option for tenant type selection dropdown.
  "general.enabled": "Enabled", // Text for things like toggles or labels that would indicate that a setting is enabled, ignore-untranslated
  "general.error": "Algo salió mal", // Error message that appears when something goes wrong
  "general.errorMessage.preAuthFailed": "No pudimos cobrarle a su empleador por esta actividad. Comuníquese con su administrador para resolver este problema.",
  "general.export.complete": "Exportación completa",
  "general.export.error": "Algo salió mal. Por favor, inténtalo de nuevo.",
  "general.export.processing": "Procesamiento de exportación",
  "general.jobseeker": "Persona en busca de empleo",
  "general.language": "Idioma", // Just the word 'language' for labeling things like language selectors or language settings
  "general.language.english": "Inglés",
  "general.language.french": "Francés",
  "general.loading": "Cargando…",
  "general.login": "Iniciar sesión",
  "general.member": "Miembro",
  "general.provider": "Proveedor", // Aria text and label for program provider filter dropdown, provider option for tenant type selection dropdown, label for activity provider dropdown, label for course provider, label for provider in submit own request overview, heading for the provider name column.
  "general.recommended": "Recomendado", // Text for recommended items
  "general.signup": "Registrarse",
  "general.skills": "Habilidades", // Label for Skills tab, header for skills section.

  "general.stream.DEIdiversityInclusionAccessibility": "DEI: Diversidad, inclusión y accesibilidad",
  "general.stream.accounting": "Contabilidad: finanzas, auditoría y gestión de riesgos",
  "general.stream.advancedManufacturing": "Fabricación avanzada",
  "general.stream.agricultureTechnology": "Tecnología agrícola",
  "general.stream.appliedLearning": "Aprendizaje aplicado: Técnicos farmacéuticos, ópticos y más",
  "general.stream.biomanufacturing": "Biofabricación",
  "general.stream.businessOperations": "Operaciones comerciales: mejora del proceso, Agile y Scrum",
  "general.stream.businessStrategyInnovationAdministration": "Negocio: estrategia, innovación y administración de negocios",
  "general.stream.cleanTechnology": "Tecnología limpia",
  "general.stream.cloudComputingProgramming": "Computación en la nube: Cloud Essentials, operaciones y arquitectura en la nube",
  "general.stream.communicationConfidenceInfluenceResolution": "Comunicación: confianza profesional, influencia y resolución de conflictos",
  "general.stream.constructionManagement": "Gestión de la construcción: contratos de construcción, estimación y estrategia",
  "general.stream.cybersecurity": "Ciberseguridad",
  "general.stream.dataAnalytics": "Análisis de datos: PowerBI, Excel, análisis de datos y visualización",
  "general.stream.dataScience": "Ciencia de datos: modelado de datos, ingeniería de datos y programación de datos",
  "general.stream.design": "Diseño: diseño creativo, gráfico y UX/UI",
  "general.stream.digitalSkills": "Diseño: diseño creativo, gráfico y UX/UI",
  "general.stream.digitalTechnology": "Tecnología digital",
  "general.stream.emergentTechnologies": "Tecnologías emergentes: cadena de bloques, aprendizaje automático, IA y metaverso",
  "general.stream.engineeringContinuingEducation": "Ingeniería: educación continua para ingenieros profesionales (Ing. p.)",
  "general.stream.growthAccelerators": "Aceleradores de crecimiento: Estudiantes de inglés como segundo idioma (ESL)/estudiantes de francés como segundo idioma (FSL), comunicaciones escritas y preparación universitaria",
  "general.stream.integratedTechnology": "TI: seguridad cibernética, seguridad de la información y privacidad de la información",
  "general.stream.leadership": "Liderazgo: material esencial para líderes, coaching e inteligencia emocional",
  "general.stream.lifestyle": "Estilo de vida: nutrición, enriquecimiento personal y pasatiempos",
  "general.stream.management101": "Gestión básica: desarrollo del equipo, gestión del desempeño y gestión del cambio",
  "general.stream.marketing": "Marketing: marketing digital, servicio al cliente y gestión de productos",
  "general.stream.myList": "Mi lista",
  "general.stream.onDemand": "A demanda",
  "general.stream.peopleCulture": "Personas y cultura: adquisición de talentos, compensación y relaciones con los empleados",
  "general.stream.preConstruction": "Preconstrucción: BIM, vivienda pasiva y diseño ambiental",
  "general.stream.projectManagement": "Gestión de proyectos: preparación del examen, educación continua y herramientas de gestión de proyectos",
  "general.stream.safetyFirst": "La seguridad es lo primero: salud y seguridad ocupacional, WHMIS y seguridad en el lugar de trabajo",
  "general.stream.softwareDevelopment": "Desarrollo de software: bases de datos, desarrollo de pila completa y web",
  "general.stream.supplyChainManagement": "Gestión de la cadena de suministro: logística, comercio global y prácticas Lean",
  "general.stream.sustainability": "Sostenibilidad: gestión sostenible, prácticas y responsabilidad social corporativa",
  "general.stream.wellness": "Bienestar: bienestar digital, bienestar emocional y equilibrio entre el trabajo y la vida personal",
  "general.stream.writtenCommunication": "Comunicación escrita: comunicación comercial, escritura técnica y aprendizaje de idiomas",

  "general.value": "Valor", // Label for generic inputs that require a value
  "general.view": "Ver", // Navigation link to view an activity, text for view activity button

  "home.academic.publicPortal.copy": "Aprenda nuevas habilidades y mejore su carrera con cursos, certificados profesionales y títulos de proveedores de educación de primer nivel", // Explains how academic partnerships help customers (found on public portal homepage)
  "home.academic.title": "Educación de calidad de proveedores confiables", // Header text for academic partnership section of homepage
  "home.brightspace.button": "Explora {BrightspaceName}",
  "home.help.button": "Comuníquese con nosotros", // Navigates user to support page
  "home.help.text": "Estamos aquí para responder sus preguntas. Permítanos ayudar en su proceso de aprendizaje.", // Text for contact support section of homepage
  "home.help.title": "¿Necesita ayuda?", // Title for contact support section of homepage
  "home.welcome.button": "Examinar programas", // Homepage button that will navigate user to course catalog
  "home.welcome.copy": "Encuentre, solicite y regístrese para obtener oportunidades de educación de calidad que lo ayudarán a alcanzar su máximo potencial.", // Explains how Wave works to customer (found on homepage)
  "home.welcome.header": "Desarrolle nuevas habilidades y crezca en su carrera profesional", // Welcome banner text on homepage
  "home.welcome.publicPortal.copy": "Encuentre y solicite oportunidades de aprendizaje y desarrollo, y regístrese para obtenerlas, que lo ayudarán a avanzar en su carrera profesional", // Explains how Wave works to customer (found on public portal homepage)

  "image.alt.helpingHand": "Mano amiga", // Alt text for an image which is of a helping hand (used in 'contact us' area)

  "in-place-dropdown.buttonSave": "Guardar",

  "info-copy.action-button.aria-label": "Copiar en el portapapeles", // aria-label for copy button - clicking button copies associated data to user's clipboard

  "invite-user.success": "Se crearon {numUsers} usuarios con éxito", // Mensaje de éxito cuando los usuarios son invitados con éxito

  "landing-stream.headingIntro": "Recomendado para sus objetivos",

  "login.collectEmail.description": "Encuentre, solicite y regístrese para obtener oportunidades de educación de calidad que lo ayudarán a alcanzar su máximo potencial.", // Description of D2L Wave displayed on email collection page which is shown before login completes, if user has no email yet
  "login.collectEmail.errorUpdatingUser": "Se produjo un problema al actualizar la cuenta de usuario. Vuelva a intentarlo. Si el problema continúa, comuníquese con el equipo de soporte.", // Error toast shown in case saving the email collection data fails
  "login.collectEmail.greeting.displayName": "Hola, {displayName}.", // Greeting displayed to user of SkillsWave displayed on email collection page which is shown before login completes, if user has no email yet
  "login.collectEmail.greeting.generic": "Hola:", // Greeting displayed to user on email collection page which is shown before login completes, if user has no email yet
  "login.collectEmail.primaryButton": "Examinar programas", // Label/text shown on button on email collection page which is shown before login completes, if user has no email yet

  "login.general.privacyPolicy": "La información personal se puede recopilar, utilizar y divulgar de acuerdo con nuestra <link>política de privacidad</link>", // Label for privacy policy link. <link> tags are a placeholder for a hyperlink and should not be translated.

  "login.magiclink.documentTitle": "Cargando… SkillsWave", // Displayed during sign-in process while generating a magic link for login redirection. Do not translate "SkillsWave".
  "login.magiclink.emailInput": "Correo electrónico", // Email input label
  "login.magiclink.invalidEmail": "Dirección de correo electrónico no válida", // Error message when the email address is invalid
  "login.magiclink.loading": "Cargando enlace de Magic", // Displayed during sign-in process while generating a magic link for login redirection
  "login.magiclink.loginHeader": "Iniciar sesión", // Login header
  "login.magiclink.sendLoginLink": "Enviar enlace de inicio de sesión", // Text on button to submit login link form
  "login.magiclink.submitted.1": "El correo electrónico de inicio de sesión se envió a {email}.", // Text displayed during login redirect
  "login.magiclink.submitted.2": "Busque un enlace en su bandeja de entrada para iniciar sesión.", // Text displayed during login redirect
  "login.marketing.companyName": "Nombre de la empresa", // Label for company name input
  "login.marketing.form1.introText": "Ingrese su dirección de correo electrónico para empezar hoy mismo.", // The branded text on the first form
  "login.marketing.form1.introTextHeader": "¡Bravo! Lo espera una mejora de sus habilidades.", // The header for the flavour text on the first form
  "login.marketing.form1.submit": "Comencemos", // Label for first marketing form submit button
  "login.marketing.form1.title": "Iniciar sesión", // The title for the first form in the marketing page
  "login.marketing.form2.country": "País", // Label for the country input
  "login.marketing.form2.introText": "Cuéntenos un poco más acerca de usted.", // The the branded text on the first form
  "login.marketing.form2.introTextHeader": "¡Ya casi terminamos!", // The header for the flavour text on the first form
  "login.marketing.form2.state": "Estado/Provincia", // Label for the state input
  "login.marketing.form2.submit": "¡Comencemos!", // Label for second marketing form submit button
  "login.marketing.form2.title": "Registrarse", // The title for the second form in the marketing page
  "login.marketing.form3.introText.1": "Ingrese su correo electrónico y vea cómo puede facultar a su personal para que aprenda y se desarrolle mediante el acceso a un catálogo personalizado de cursos, programas y microcredenciales.", // The first <p> on the showcase landing page
  "login.marketing.form3.introText.2": "¿Aún no está listo para comprometerse? No hay problema. Revise el <link>video de tutorial de SkillsWave</link> para saber cómo puede desbloquear su potencial de capacitación.", // The second <p> on the showcase with the video link. Do not translate "SkillsWave" or "SkillsWave".
  "login.marketing.form3.introTextHeader": "¡Hola! Bienvenido a nuestra Experiencia de presentación de productos.", // The header for the flavour text on the showcase landing page
  "login.marketing.form3.submit": "Explorar ahora", // Label for first showcase landing page form submit button
  "login.marketing.form3.title": "Iniciar sesión", // The title for the first form in the showcase landing page
  "login.marketing.fullName": "Nombre completo", // Label for full name input
  "login.marketing.marketingAgreement": "Sí, me gustaría recibir comunicaciones del grupo de empresas de SkillsWave", // Label for marketing agreement checkbox
  "login.marketing.marketingAgreement.required": "Acuerdo de marketing", // Label for marketing agreement required error
  "login.marketing.marketingAgreement.tooltip": "Para probar Wave, debe aceptar las comunicaciones de marketing", // Tooltip to explain why continue button is disabled. Do not translate "SkillsWave".
  "login.marketing.title": "Ocupación", // Label for job title input
  "login.marketing.workEmail": "Correo electrónico laboral", // Label for work email input

  "login.multi.header": "Inicio de sesión", // The header for the multi login screen
  "login.multi.subHeader": "Elegir su organización", // The sub header in the login box for the multi login screen

  "login.saml.redirect": "Se le redirigirá para iniciar sesión.", // Text displayed during login redirect

  "manage-activity-instructions.title": "Instrucciones de actividad",
  "manage-audit.title": "Auditoría",

  "manage-budget-approvals.approvals.title.label": "Modelo de aprobación", // Title for Approval section on the page
  "manage-budget-approvals.budget.title.label": "Presupuesto", // Title for Budget section on the page
  "manage-budget-approvals.caseByCase": "Individual", // Label for radio button for case-by-case budget setting
  "manage-budget-approvals.caseByCase.confirmation": "El aprobador determinará el monto de la cobertura que los empleados reciben según cada caso.", // Confirmation message shown when budget configuration is set as case-by-case
  "manage-budget-approvals.caseByCase.disabled.tooltipText": "El presupuesto individual no se puede habilitar cuando se selecciona el modelo de aprobación híbrida.", // A tooltip description explaining why the radio button is disabled
  "manage-budget-approvals.corporatePolicy.employee": "Monto fijo (por empleado)", // Label for radio button for corporate policy
  "manage-budget-approvals.corporatePolicy.jobseeker": "Monto fijo (por persona en busca de empleo)", // Label for radio button for corporate policy
  "manage-budget-approvals.corporatePolicy.member": "Monto fijo (por miembro)", // Label for radio button for corporate policy
  "manage-budget-approvals.employee.annualCredit.label": "Crédito anual de empleados", // Label for annual employee credit input text box
  "manage-budget-approvals.fiscalStartDate.label": "Fecha de inicio del año fiscal", // Label for fiscal year start date input
  "manage-budget-approvals.jobseeker.annualCredit.label": "Crédito anual de personas en busca de empleo", // Label for annual jobseeker credit input text box
  "manage-budget-approvals.member.annualCredit.label": "Crédito anual de miembros", // Label for annual member credit input text box
  "manage-budget-approvals.percentCoverage": "Porcentaje de cobertura", // Label for radio button for percent coverage
  "manage-budget-approvals.percentCoverage.label": "Porcentaje de cobertura por solicitud", // Label for percent coverage percentage input
  "manage-budget-approvals.saveButton": "Guardar", // Save button text
  "manage-budget-approvals.saveButton.saved": "Guardado", // Button text when budget settings has been saved
  "manage-budget-approvals.savedToast": "Se guardó la información de presupuesto de la organización", // Text for toast that confirms that budget settings have been saved
  "manage-budget-approvals.subtitle": "Se puede cambiar más tarde, pero afectará de dónde provienen los fondos para solicitudes de educación.", // Subtitle for budget tab explaining the impact of budget configuration
  "manage-budget-approvals.title": "Presupuesto y aprobaciones", // Title for budget & approval tab

  "manage-career-explorer.title": "Explorador de carreras",

  "manage-custom-attributes.application.description": "Los valores de estos atributos personalizados se almacenarán en los registros de la aplicación.", // Informational text about where attributes will be saved
  "manage-custom-attributes.application.heading": "Editar atributos personalizados de la aplicación", // Heading for application custom attributes section of the page
  "manage-custom-attributes.application.label": "Atributos personalizados de la aplicación", // Label for the editor section where users can edit custom attributes
  "manage-custom-attributes.defaultNameValue": "Nuevo atributo personalizado", // Default name if user adds a new custom attribute
  "manage-custom-attributes.helpText": "La documentación detallada sobre los atributos personalizados se puede encontrar en Confluence", // Text which is a link to some documentation
  "manage-custom-attributes.save.applicationAttributesInvalid.json": "JSON no válido en los atributos personalizados de la aplicación", // Message which appears on save if there is an issue with the JSON formatting for application attributes
  "manage-custom-attributes.save.applicationAttributesInvalid.schema": "Atributos personalizados de la aplicación no válidos", // Message which appears on save if there is an issue with the schema formatting for application attributes
  "manage-custom-attributes.save.bothAttributesInvalid.json": "JSON no válido en atributos personalizados del usuario y atributos personalizados de la aplicación", // Message if there is a problem with the JSON on both custom attribute types
  "manage-custom-attributes.save.bothAttributesInvalid.schema": "Atributos personalizados del usuario y atributos personalizados de la aplicación no válidos", // Message if there is a problem with the schema on both custom attribute types
  "manage-custom-attributes.save.success": "Atributos personalizados guardados", // Message which appears when attributes were saved successfully
  "manage-custom-attributes.save.userAttributesInvalid.json": "JSON no válido en los atributos personalizados del usuario", // Message which appears on save if there's an issue with the JSON formatting
  "manage-custom-attributes.save.userAttributesInvalid.schema": "Atributos personalizados del usuario no válidos", // Message which appears on save if there is an issue with the data matching the schema
  "manage-custom-attributes.title": "Atributos personalizados", // Title for custom attributes tab in the admin section
  "manage-custom-attributes.user.description": "Los valores de estos atributos personalizados se almacenarán en los registros del usuario.", // Informational text about where attributes will be saved
  "manage-custom-attributes.user.heading": "Editar los atributos personalizados del usuario", // Heading for user custom attributes section of the page
  "manage-custom-attributes.user.label": "Atributos personalizados del usuario", // Label for the editor section where users can edit custom attributes

  "manage-custom-terms.title": "Términos de uso del empleado",
  "manage-enabled-streams.title": "Flujos habilitados",
  "manage-entitlements.title": "Derechos",
  "manage-faq.title": "FAQ", // ignore-untranslated

  "manage-general.accountConnect.studentID": "Requiere identificación de estudiante",
  "manage-general.accountConnect.studentID.format": "formato de identificación de estudiante",
  "manage-general.adminRoles": "Habilitar rol basado en permisos", // Tooltip for check if business is Canada based
  "manage-general.brightspace.name": "Nombre de Brightspace",
  "manage-general.brightspace.url": "URL de Brightspace",
  "manage-general.buttonSave": "Guardar",
  "manage-general.isCanadianBusiness": "Este es un negocio canadiense", // Text for check if business is Canada based
  "manage-general.label.accentColour": "Color de contraste", // Text for accent colour label
  "manage-general.label.daysUntilInvoiceDue": "Días hasta el vencimiento de la factura", // Text for accent colour label
  "manage-general.label.financeEmail": "Correo electrónico de Finanzas", // Text for finance email label
  "manage-general.label.programPolicy": "Política del programa (incluir esquema de URL)", // Text for program policty label
  "manage-general.label.studentSupportEmail": "Correo electrónico de asistencia para estudiantes", // Text for student support email label
  "manage-general.learnerTerminology": "Terminología del estudiante", // Text for learner terminology options
  "manage-general.notes": "Notas administrativas", // Label for Admin notes
  "manage-general.revShare.fixed": "Monto fijo", // Text for revenue share option: fixed amount
  "manage-general.revShare.percent": "Parte (en %) del costo de la actividad", // Text for revenue share option: portion of activity cost
  "manage-general.tags.allowEditableEmail": "Permitir que el correo electrónico del usuario se pueda editar.",
  "manage-general.tags.automaticCatalogOptOut": "Exclusión automática de las actualizaciones automáticas del catálogo para nuevas actividades",
  "manage-general.tags.cacheAnalyticsReports": "Informes de análisis de caché", // Text label for a checkbox that will cache analytics reports
  "manage-general.tags.collectEmailOnLogin": "Recopile los correos electrónicos al iniciar sesión si el usuario no tiene uno", // Text displayed beside checkbox option to determine if we collect emails on login or not
  "manage-general.tags.enableLinkToBrightspace": "Habilitar enlace a Brightspace",
  "manage-general.tags.hideFastToCompleteStream": "Oculte el flujo de “{streamName}” de la página Programas", // Text label for a checkbox that will hide the 'fastToComplete' dynamic carousel of courses
  "manage-general.tags.ownRequestSubmit": "Habilitar la opción Enviar su propia solicitud", // Text for own submit request option
  "manage-general.tags.ownRequestSubmitTooltip": "Esta configuración solo se debe habilitar para los empleadores que hayan adquirido la función adicional Enviar su propia solicitud.", // Tooltip text for own submit request option
  "manage-general.tags.paymentHandledByProvider": "El pago y el registro son gestionados por el proveedor", // Text label for a checkbox handled by provider
  "manage-general.tags.plgTenant": "Configurar como inquilino PLG", // Text label for a checkbox to set the tenant as a PLG tenant
  "manage-general.title": "General", // ignore-untranslated
  "manage-general.userDataSystem": "Sistema de origen de datos del usuario", // Text for User Data System

  "manage-how-it-works.title": "Contenido de Cómo funciona",
  "manage-login.title": "Inicio de sesión",
  "manage-provider-finance.title": "Finanzas", // Title for finance tab

  "manage-role.field.roleDescription.description": "Use esta sección para describir con qué permisos está asociado este rol.",
  "manage-role.field.roleDescription.label": "Descripción del Rol",
  "manage-role.field.roleName.label": "Nombre del Rol",
  "manage-role.heading.permissions": "Permisos",
  "manage-role.submit": "Enviar",

  "manage-user-permissions.documentTitle": "Gestionar permisos de usuario – SkillsWave", // Document title for user permissions page
  "manage-user-permissions.emailInputLabel": "Correo electrónico", // Label for the user email input,
  "manage-user-permissions.failToast": "No se pudieron actualizar los roles del usuario. Por favor, intente de nuevo.", // Text for the failed toast message
  "manage-user-permissions.heading": "Actualizar permisos de usuario", // Heading for the manage user permissions page
  "manage-user-permissions.noRolesAlert": "Este usuario no tiene ningún rol asignado.", // Alert message when no roles are selected
  "manage-user-permissions.pageDescription": "Ajuste los roles asignados a este usuario para garantizar que tengan acceso a las secciones necesarias de la configuración administrativa de SkillsWave.", // Description text for the manage user permissions page
  "manage-user-permissions.rolesHeading": "Roles", // Header text for the Roles heading, ignore-untranslated
  "manage-user-permissions.statusIndicatorActive": "Activo", // Text for the active status indicator
  "manage-user-permissions.statusIndicatorInactive": "Inactivo", // Text for the inactive status indicator
  "manage-user-permissions.successToast": "Los roles del usuario se han actualizado correctamente", // Text for the success toast message
  "manage-user-permissions.titleInputLabel": "Título", // Label for the user title input
  "manage-user-permissions.updateUserButton": "Actualizar usuario", // Label for the update user,
  "manage-user-permissions.userActiveSwitchLabel": "Usuario Activo", // Label for the user active switch
  "manage-user-permissions.userNameInputLabel": "Nombre de usuario", // Label for the user name input

  "manage-visibility.title": "Visibilidad",

  "no-results.message": "No hay resultados para los filtros seleccionados actualmente", // Text displayed when there are no activities that fit the current filters

  "nova-attribute-picker.addValue": "Haga clic para agregar el valor {value}", // Label for screen readers to inform user that they can click to add the given value
  "nova-attribute-picker.limitReached": "Alcanzó el límite de {value} valores seleccionados", //  Tooltip that appears when the user has reach the maximum amount of attributes
  "nova-attribute-picker.minimumRequirement": "Se debe definir al menos un valor", // Tooltip that appears when no values have been set and we need to inform the user that this is an invalid state
  "nova-attribute-picker.removeValue": "Haga clic, presione Retroceso o presione la tecla Suprimir para eliminar el elemento {value}", // Label to inform user how they can remove the given value

  "nova-carousel.viewAll": "Ver todo", // Text for the view all button in the carousel
  "nova-carousel.viewAll.ariaLabel": "Ver todo", // Aria label for the view all button in the carousel

  "nova-json-input.addElement.buttonText": "Agregar elemento", // text appearing on button that lets user add a new element to an array in the JSON object editor
  "nova-json-input.addField.buttonText": "Agregar campo", // text appearing on button that lets user add a new JSON key value pair in the JSON object editor
  "nova-json-input.addField.menuLabel": "Tipo de valor", // label for "add field" sub-menu, visible to screenreaders
  "nova-json-input.arrayIndex": "índice [{index}]", // counting indices of arrays for the label beside array values
  "nova-json-input.deleteField.buttonText": "Eliminar este par de clave-valor", // text for the delete field button, visible to screenreaders only
  "nova-json-input.headers.key": "Clave", // Table heading for JSON Key inputs which will appear in this column
  "nova-json-input.headers.remove": "Eliminar", // Aria label for table heading which has no visible text, this is for screenreaders. The column has an action button in it, e.g. delete (maybe more in future)
  "nova-json-input.headers.type": "Tipo", // Table heading for JSON Type values which will appear in this column
  "nova-json-input.headers.value": "Valor", // Table heading for JSON Value inputs which will appear in this column
  "nova-json-input.mainEditor.label": "Vista de formulario", // label on the main editing tab of a JSON editor component
  "nova-json-input.rawJsonPreview.invalid": "El editor no se puede invocar porque el JSON no es válido. Puede editar en la pestaña vista de JSON o volver a cargar la página para regresar al estado original.", // Message displayed when the JSON is made invalid
  "nova-json-input.rawJsonPreview.label": "Vista de JSON", // label on the raw json preview tab of a JSON editor component
  "nova-json-input.value.label": "Valor para la clave {key}", // hidden label of value field, visible to screenreaders

  "nova-json-input-array.addObject": "Agregar objeto", // Text for button that adds a JSON object to the array
  "nova-json-input-array.contextMenu.deleteObject": "Eliminar objeto", // Title of an object section, the index will be a positive integer
  "nova-json-input-array.contextMenu.duplicateObject": "Objeto duplicado", // Title of an object section, the index will be a positive integer
  "nova-json-input-array.contextMenu.label": "Acciones", // Label for a context menu that enables some action on a form input
  "nova-json-input-array.empty": "No hay objetos en esta lista. Utilice el botón “Agregar objeto” para agregar uno.", // If the array is empty, tell the user they can add an object use the add object button
  "nova-json-input-array.objectTitle": "Objeto {index}", // Title of an object section, the index will be a positive integer

  "onboarding-personalization-flow.skillSelection.backButton": "Regresar",
  "onboarding-personalization-flow.skillSelection.backButtonMobile": "Atrás",
  "onboarding-personalization-flow.skillSelection.header": "Conjuntos de habilidades sugeridos para {title}",
  "onboarding-personalization-flow.skillSelection.nextButton": "Establece Tus Metas Profesionales",
  "onboarding-personalization-flow.skillSelection.search": "Buscar conjuntos de habilidades",
  "onboarding-personalization-flow.skillSelection.skipButton": "Omitir Por Ahora",
  "onboarding-personalization-flow.skillSelection.skipButtonMobile": "Saltar",
  "onboarding-personalization-flow.skillSelection.subheader": "Para obtener mejores resultados, elija al menos 3 conjuntos de habilidades",
  "onboarding-personalization-flow.skillSelection.subtitle": "Elige las habilidades que deseas desarrollar y encontraremos cursos para ti. Puedes cambiar esto más tarde.",
  "onboarding-personalization-flow.skillSelection.title": "Agudiza tus habilidades",

  "onboarding-personalization-flow.titleSelection.empty": "Busque una carrera para comenzar",
  "onboarding-personalization-flow.titleSelection.growthOpportunities": "Oportunidades de crecimiento",
  "onboarding-personalization-flow.titleSelection.loadMore": "Cargar más",
  "onboarding-personalization-flow.titleSelection.meterText": "Coincidencia de habilidades",
  "onboarding-personalization-flow.titleSelection.nextButton": "Obtén Recomendaciones",
  "onboarding-personalization-flow.titleSelection.noResults": "No se encontraron resultados para: {search}",
  "onboarding-personalization-flow.titleSelection.relatedCareers": "Carreras relacionadas",
  "onboarding-personalization-flow.titleSelection.relatedCareersToJobTitle": "Carreras relacionadas con {jobTitle}",
  "onboarding-personalization-flow.titleSelection.relevantSkills": "Habilidades Relevantes",
  "onboarding-personalization-flow.titleSelection.search": "Buscar carreras",
  "onboarding-personalization-flow.titleSelection.subtitle": "Elija las carreras que le interesan para recibir más recomendaciones de cursos. Puede cambiar esto más tarde.",
  "onboarding-personalization-flow.titleSelection.title": "Establezca objetivos profesionales",
  "onboarding-personalization-flow.titleSelection.transferrableSkills": "Habilidades transferibles",

  "permissions.category.activity.description": "Crear, editar y hacer cambios en las actividades (cursos y programas) del catálogo",
  "permissions.category.activity.label": "Configuración de Actividades",
  "permissions.category.admin.description": "Editar las pestañas dentro de la configuración del inquilino administrador",
  "permissions.category.admin.label": "Configuraciones de Administrador",
  "permissions.category.admin:update.description": "Editar las pestañas dentro de la configuración del inquilino administrador",
  "permissions.category.admin:update.label": "Editar Administrador",
  "permissions.category.admin:view.description": "Ver las pestañas dentro de la configuración del inquilino administrador",
  "permissions.category.admin:view.label": "Ver Administrador",
  "permissions.category.analytics.description": "Analítica de actividades y empleadores",
  "permissions.category.analytics.label": "Pestaña de Analítica",
  "permissions.category.employer.description": "Crear inquilinos de empleadores para nuestros clientes corporativos y hacer cambios en la configuración actual del inquilino del empleador",
  "permissions.category.employer.label": "Configuración de Empleadores",
  "permissions.category.employer:update.description": "Editar las pestañas dentro de la configuración del inquilino del empleador",
  "permissions.category.employer:update.label": "Editar Empleador",
  "permissions.category.employer:view.description": "Ver las pestañas dentro de la configuración del inquilino del empleador",
  "permissions.category.employer:view.label": "Ver Empleador",
  "permissions.category.features.description": "Activar o desactivar las banderas de características para inquilinos proveedores o empleadores",
  "permissions.category.features.label": "Pestaña de Características",
  "permissions.category.permissions.description": "Crear inquilinos proveedores para socios educativos y hacer cambios en la configuración actual del inquilino",
  "permissions.category.permissions.label": "Pestaña de Permisos",
  "permissions.category.provider.description": "Configuraciones para inquilinos proveedores",
  "permissions.category.provider.label": "Configuraciones del Proveedor",
  "permissions.category.provider:update.description": "Editar las pestañas dentro de la configuración del inquilino proveedor",
  "permissions.category.provider:update.label": "Editar Proveedor",
  "permissions.category.provider:view.description": "Ver las pestañas dentro de la configuración del inquilino proveedor",
  "permissions.category.provider:view.label": "Ver Proveedor",
  "permissions.category.requests.description": "Solicitudes de actividades de todos los clientes",
  "permissions.category.requests.label": "Pestaña de Solicitudes",

  "permissions.permission.activity:archive.description": "Archivar una actividad para que no sea visible en el catálogo de proveedores educativos",
  "permissions.permission.activity:archive.label": "Archivar Actividad",
  "permissions.permission.activity:create.description": "Agregar nuevas actividades al catálogo.",
  "permissions.permission.activity:create.label": "Agregar Actividad",
  "permissions.permission.activity:create-update.description": "Agregar nuevas actividades o realizar cambios en actividades existentes en el catálogo.",
  "permissions.permission.activity:create-update.label": "Agregar o Actualizar Actividad",
  "permissions.permission.activity:export.description": "Exportar un archivo CSV de actividades con columnas personalizadas.",
  "permissions.permission.activity:export.label": "Exportar Actividades",
  "permissions.permission.activity:status:update.description": "Activar o desactivar actividades dentro del catálogo.",
  "permissions.permission.activity:status:update.label": "Activación de Actividades",
  "permissions.permission.activity:streams:import.description": "Importar actividades para reclasificar las que pertenecen a un flujo.",
  "permissions.permission.activity:streams:import.label": "Importar Actividades a los Flujos",
  "permissions.permission.activity:update.description": "Hacer cambios a todas las actividades existentes en el catálogo.",
  "permissions.permission.activity:update.label": "Editar Actividad",
  "permissions.permission.activity:view.description": "Ver la lista de actividades en el catálogo y consultar la configuración de las actividades.",
  "permissions.permission.activity:view.label": "Ver Actividad",

  "permissions.permission.admin:audit:update.label": "Pestaña de Auditoría",
  "permissions.permission.admin:audit:view.label": "Pestaña de Auditoría",
  "permissions.permission.admin:create.description": "Agregar un inquilino administrador",
  "permissions.permission.admin:create.label": "Agregar Administrador",
  "permissions.permission.admin:general:update.label": "Pestaña General",
  "permissions.permission.admin:general:view.label": "Pestaña General",
  "permissions.permission.admin:login:update.label": "Pestaña de Inicio de Sesión",
  "permissions.permission.admin:login:view.label": "Pestaña de Inicio de Sesión",
  "permissions.permission.admin:roles:update.label": "Pestaña de Roles",
  "permissions.permission.admin:roles:view.label": "Pestaña de Roles",
  "permissions.permission.admin:userpermissions:update.label": "Pestaña de Permisos de Usuario",
  "permissions.permission.admin:userpermissions:view.label": "Pestaña de Permisos de Usuario",

  "permissions.permission.analytics:view.description": "Ver y filtrar análisis para todos los inquilinos.",
  "permissions.permission.analytics:view.label": "Ver Análisis",

  "permissions.permission.employer:audit:update.label": "Pestaña de Auditoría",
  "permissions.permission.employer:audit:view.label": "Pestaña de Auditoría",
  "permissions.permission.employer:careerexplorer:update.label": "Pestaña Explorador de Carreras",
  "permissions.permission.employer:careerexplorer:view.label": "Pestaña Explorador de Carreras",
  "permissions.permission.employer:create.description": "Agregar un inquilino de empleador",
  "permissions.permission.employer:create.label": "Agregar Empleador",
  "permissions.permission.employer:customattributes:update.label": "Pestaña de Atributos Personalizados",
  "permissions.permission.employer:customattributes:view.label": "Pestaña de Atributos Personalizados",
  "permissions.permission.employer:enabledstreams:update.label": "Pestaña de Streams Habilitados",
  "permissions.permission.employer:enabledstreams:view.label": "Pestaña de Streams Habilitados",
  "permissions.permission.employer:entitlements:update.label": "Pestaña de Derechos",
  "permissions.permission.employer:entitlements:view.label": "Pestaña de Derechos",
  "permissions.permission.employer:faq:update.label": "Pestaña de Preguntas Frecuentes",
  "permissions.permission.employer:faq:view.label": "Pestaña de Preguntas Frecuentes",
  "permissions.permission.employer:finance:update.label": "Pestaña de Presupuestos y Aprobaciones",
  "permissions.permission.employer:finance:view.label": "Pestaña de Presupuestos y Aprobaciones",
  "permissions.permission.employer:general:update.label": "Pestaña General",
  "permissions.permission.employer:general:view.label": "Pestaña General",
  "permissions.permission.employer:howitworks:update.label": "Pestaña de Cómo Funciona",
  "permissions.permission.employer:howitworks:view.label": "Pestaña de Cómo Funciona",
  "permissions.permission.employer:login:update.label": "Pestaña de Inicio de Sesión",
  "permissions.permission.employer:login:view.label": "Pestaña de Inicio de Sesión",
  "permissions.permission.employer:termsofuse:update.label": "Pestaña de Términos de Uso del Empleador",
  "permissions.permission.employer:termsofuse:view.label": "Pestaña de Términos de Uso del Empleador",
  "permissions.permission.employer:visibility:update.label": "Pestaña de Visibilidad",
  "permissions.permission.employer:visibility:view.label": "Pestaña de Visibilidad",

  "permissions.permission.features:update.description": "Realizar cambios en las banderas de funciones (activar o desactivar) para los inquilinos.",
  "permissions.permission.features:update.label": "Editar Funciones",
  "permissions.permission.features:view.description": "Ver la lista de funciones disponibles para los inquilinos.",
  "permissions.permission.features:view.label": "Ver Funciones",

  "permissions.permission.permissions.description": "Ver y realizar cambios en todas las secciones dentro de la pestaña de permisos.",
  "permissions.permission.permissions.label": "Ver y Editar Permisos",

  "permissions.permission.provider:activityinstructions:update.label": "Pestaña de Instrucciones de Actividad",
  "permissions.permission.provider:activityinstructions:view.label": "Pestaña de Instrucciones de Actividad",
  "permissions.permission.provider:audit:update.label": "Pestaña de Auditoría",
  "permissions.permission.provider:audit:view.label": "Pestaña de Auditoría",
  "permissions.permission.provider:careerexplorer:update.label": "Pestaña de Explorador de Carreras",
  "permissions.permission.provider:careerexplorer:view.label": "Pestaña de Explorador de Carreras",
  "permissions.permission.provider:create.description": "Agregar un inquilino proveedor",
  "permissions.permission.provider:create.label": "Agregar Proveedor",
  "permissions.permission.provider:customattributes:update.label": "Pestaña de Atributos Personalizados",
  "permissions.permission.provider:customattributes:view.label": "Pestaña de Atributos Personalizados",
  "permissions.permission.provider:entitlements:update.label": "Pestaña de Derechos",
  "permissions.permission.provider:entitlements:view.label": "Pestaña de Derechos",
  "permissions.permission.provider:faq:update.label": "Pestaña de Preguntas Frecuentes",
  "permissions.permission.provider:faq:view.label": "Pestaña de Preguntas Frecuentes",
  "permissions.permission.provider:finance:update.label": "Pestaña de Finanzas",
  "permissions.permission.provider:finance:view.label": "Pestaña de Finanzas",
  "permissions.permission.provider:general:update.label": "Pestaña General",
  "permissions.permission.provider:general:view.label": "Pestaña General",
  "permissions.permission.provider:howitworks:update.label": "Pestaña de Cómo Funciona",
  "permissions.permission.provider:howitworks:view.label": "Pestaña de Cómo Funciona",
  "permissions.permission.provider:login:update.label": "Pestaña de Inicio de Sesión",
  "permissions.permission.provider:login:view.label": "Pestaña de Inicio de Sesión",
  "permissions.permission.requests:update.description": "Realizar cambios en los estados de las solicitudes, antes del registro",
  "permissions.permission.requests:update.label": "Editar Solicitudes",
  "permissions.permission.requests:view.description": "Ver y filtrar la lista de solicitudes de todos los usuarios finales de SkillsWave",
  "permissions.permission.requests:view.label": "Ver Solicitudes",

  "readonly-dialog.marketingMode.message": "Podemos hacer algo diferente aquí si el marketing está habilitado.", // Text displayed notifying user is in marketing mode
  "readonly-dialog.readOnlyMode.message": "Este usuario está en modo de solo lectura. No se puede enviar nada en el modo de solo lectura.", // Text displayed notifying user is in read only mode

  "redirect.skillswave.documentTitle": "Redirigiendo...",
  "redirect.skillswave.linkText": "ir a SkillsWave ahora",
  "redirect.skillswave.message": "Esto solo tomará un momento, o ",
  "redirect.skillswave.title": "Redirigiéndole a",

  "registration-call-to-action.alertButtonText": "Ver", // The label that goes on the registration call to action button
  "registration-call-to-action.viewApplication": "<strong>Acción requerida:</strong> Complete su registro para <strong>{activityTitle}</strong>",
  "registration-call-to-action.viewApplications": "<strong>Acción requerida:</strong> Complete sus registros",

  "report.adoption.approved": "Aprobado", // The label for the approved requests
  "report.adoption.cancelledBy.employee": "Cancelado por el empleado", // The label for the cancelled by employee requests
  "report.adoption.cancelledBy.jobseeker": "Cancelado por la persona en busca de empleo", // The label for the cancelled by jobseeker requests
  "report.adoption.cancelledBy.member": "Cancelado por el miembro", // The label for the cancelled by member requests
  "report.adoption.cancelledByLearner": "Cancelado por el estudiante", // The label for the cancelled by learner requests
  "report.adoption.declined": "Rechazado", // The label for the declined requests
  "report.adoption.header": "Solicitudes", // The header for the adoption report widget
  "report.adoption.pending": "Pendiente", // The label for the pending requests
  "report.adoption.title": "Adopción", // The title for the adoption report widget
  "report.awareness.dailyAverage": "Promedio diario", // The label for the daily average logins
  "report.awareness.header": "Inicios de sesión únicos", // The header for the awareness report widget
  "report.awareness.title": "Conciencia", // The header for the awareness report widget
  "report.awareness.totalLogins": "Total de inicios de sesión", // The label for the total logins
  "report.emptyStateMsg": "No hay datos disponibles según los filtros que seleccionó.", // The message displayed when a report has no data without filters
  "report.investment.costToCompany": "Costo para la empresa", // The label for the cost to the company
  "report.investment.costToEmployees": "Costo para los empleados", // The label for the cost to employees
  "report.investment.employee.costs": "Costo para los empleados", // The label for the cost to employees
  "report.investment.header": "Total aprobado", // The header for the investment report widget
  "report.investment.jobseeker.costs": "Costo para los buscadores de empleo", // The label for the cost to jobseeker
  "report.investment.member.costs": "Costo para los miembros", // The label for the cost to members
  "report.investment.pending": "Pendiente", // The label for the pending costs
  "report.investment.title": "Inversión", // The header for the investment report widget
  "report.progress.completed": "Completado", // The label for the number of completed requests
  "report.progress.header": "Total aprobado", // The header for the investment report widget
  "report.progress.other": "Incorrecto/otro", // The label for the number of unsuccessful requests or requests with another state
  "report.progress.registered": "Registrado", // The label for the number of registered requests
  "report.progress.registrationStarted": "Se inició el registro", // The label for the number of registration started requests
  "report.progress.title": "Progreso", // The header for the investment report widget
  "report.skillsDemand.emptyStateMsg": "Las habilidades con la mayor cantidad de solicitudes aparecerán aquí.", // The message displayed when a skillsDemand report has no data
  "report.skillsDemand.heading1": "Habilidad", // The heading for the skill name column
  "report.skillsDemand.heading2": "Solicitudes", // The heading for the number of requests column
  "report.skillsDemand.title": "Demanda de habilidades", // header for the top departments report widget
  "report.topActivities.emptyStateMsg": "Los programas con la mayor cantidad de solicitudes aparecerán aquí.", // The message displayed when a topActivities report has no data
  "report.topActivities.numRequest": "{numRequests} solicitud", // The number of requests in the top activities report (Case 1: Exactly 1 request)
  "report.topActivities.numRequests": "{numRequests} solicitudes", // The number of requests in the top activities report (Case 2: More than 1 request)
  "report.topActivities.title": "Programas principales", // Title for the topActivities report widget
  "report.topDepartments.emptyStateMsg": "Los departamentos con la mayor cantidad de solicitudes aparecerán aquí.", // The message displayed when a topDepartments report has no data
  "report.topDepartments.heading1": "Departamento", // The heading for the department name column
  "report.topDepartments.heading2": "Solicitudes", // The heading for the number of requests column
  "report.topDepartments.title": "Departamentos principales", // header for the top departments report widget
  "report.topProviders.emptyStateMsg": "Los proveedores de programas con la mayor cantidad de solicitudes aparecerán aquí.", // The message displayed when a topProviders report has no data
  "report.topProviders.heading2": "Solicitudes", // The heading for the number of requests column
  "report.topProviders.title": "Proveedores principales", // header for the top departments report widget

  "review-application.back.button": "Regresar", // Form button to go back to previous page
  "review-application.documentTitle": "Revisar mi solicitud: SkillsWave", // Document title when viewing the second page of the application process (review application). Do not translate "SkillsWave".
  "review-application.overview.approverNames": "Nombres del aprobador", // Label for approver names in review application overview
  "review-application.title": "Revise mi solicitud", // Title for draft application review page

  "settings-finance.add": "Agregar",
  "settings-finance.card-ending": "Tarjeta que termina en {lastFour}",
  "settings-finance.card-expiry": "Vence el {expiry_year}/{expiry_month}",
  "settings-finance.delete": "Eliminar",
  "settings-finance.delete-dialog.message": "¿Estás seguro de que quieres eliminar la tarjeta que termina en {lastFour}?",
  "settings-finance.delete-dialog.title": "Eliminar método de pago",
  "settings-finance.edit": "Editar",
  "settings-finance.edit-dialog.cardholder-name": "Nombre del titular de la tarjeta",
  "settings-finance.edit-dialog.city": "Ciudad",
  "settings-finance.edit-dialog.country": "País",
  "settings-finance.edit-dialog.expiry-month": "Mes de vencimiento",
  "settings-finance.edit-dialog.expiry-year": "Año de vencimiento",
  "settings-finance.edit-dialog.postal": "Código postal",
  "settings-finance.edit-dialog.province": "Estado/provincia",
  "settings-finance.edit-dialog.street": "Calle",
  "settings-finance.edit-dialog.street2": "Calle (línea 2)",
  "settings-finance.edit-dialog.title": "Editar métodos de pago",
  "settings-finance.payment-methods": "Métodos de pago guardados",

  "settings-invite-user.add_num_users": "{num_users, plural, =0 {Agregar usuarios} one {Agregar 1 usuario} other {Agregar {num_users} usuarios}}",
  "settings-invite-user.add_users": "Agregar usuarios",
  "settings-invite-user.all_users": "Todos los usuarios agregados de esta manera tendrán acceso a SkillsWave como estudiantes. Puedes agregar roles a los usuarios después de que sean invitados al sistema.",
  "settings-invite-user.email_addresses": "Direcciones de correo electrónico",
  "settings-invite-user.separate_emails": "Separe los correos electrónicos con una coma",
  "settings-invite-user.user_already_exists_plural": "{num_warnings} direcciones de correo electrónico ya han sido agregadas al sistema. Estas direcciones no recibirán una invitación a SkillsWave.",
  "settings-invite-user.user_already_exists_singular": "{num_warnings} dirección de correo electrónico ya ha sido agregada al sistema. Esta dirección no recibirá una invitación a SkillsWave.",

  "skill-chip.remove-button.text": "Eliminar habilidad", // accessibility text for skill chip removal button, shown to admin users
  "skill-chip-list.numSkills": "{number, plural, one {# habilidad} other {# habilidades}}",

  "sso.attribute-requirement.description": "Cuando un usuario inicia sesión en Wave, los atributos de su objeto de usuario deben pasar TODAS estas comprobaciones de regex antes de que se le conceda acceso.", // Text describing how attribute requirements are used
  "sso.attribute-requirement.save": "Guardar requisitos", // Text for attribute requirements save button
  "sso.attribute-requirement.save.fail": "Se produjo un error al guardar los requisitos de atributo", // Text for attribute requirements save failure toast
  "sso.attribute-requirement.save.success": "Requisitos de atributo guardados", // Text for attribute requirements save success toast
  "sso.attribute-requirement.title": "Requisitos de atributo", // Title text for attribute requirements
  "sso.checkDomain": "Compruebe el dominio", // The check domain flag test
  "sso.checkDomain.help": "Si está deshabilitado, este dominio de inicio de sesión con enlace permitirá que CUALQUIER dirección de correo electrónico inicie sesión.", // The tooltip message describing what the checkDomain flag is used for
  "sso.configuration.title": "Configuración de SSO", // Title for the attribute configuration
  "sso.copy.url": "Copiar dirección URL", // Alt text for copy url button
  "sso.copy.url.toast": "Se copió la dirección url de devolución de llamada {callbackUrl} al portapapeles", // Toast message for when url is copied
  "sso.create": "Crear", // Alt text for create button
  "sso.default": "Dominio predeterminado", // Whether this domain is the default domain to be used when the domain for a user can't be determined
  "sso.default.help": "Este dominio se utilizará cuando no se pueda determinar un dominio de usuarios. NOTA: Este campo se desactivará si otro dominio aparece como predeterminado", // Whether this domain is the default domain to be used when the domain for a user can't be determined
  "sso.delete": "Eliminar", // Alt text for delete button
  "sso.delete.error": "Error al eliminar el objeto SSO {name}. Error: {message}", // Error toast for when it fails to delete the SSO object
  "sso.delete.success": "Se eliminó correctamente el objeto SSO {name}", // Success toast for when it deletes the SSO object
  "sso.dialog.create": "Crear inicio de sesión", // Title for create login dialog
  "sso.dialog.edit": "Editar inicio de sesión", // Title for edit login dialog
  "sso.domain": "Dominio", // The domain attribute display name
  "sso.domain.help": "Este es el dominio en el que el usuario puede navegar para iniciar sesión en SkillsWave. Esto no se puede modificar después de la creación", // The tooltip message describing what the domain is used for. Do not translate "SkillsWave"
  "sso.edit": "Editar información de SSO", // Alt text for edit SSO object button
  "sso.imageUrl": "URL de la imagen", // The image url display name
  "sso.loginType": "Tipo de inicio de sesión", // The loginType attribute display name
  "sso.ml.emailDomain": "Dominio de correo electrónico", // The emailDomain attribute display name
  "sso.name": "Nombre", // The name attribute display name
  "sso.noDefaultDomainSet": "Establezca un dominio predeterminado para garantizar una entrega de correos electrónicos coherente.", // The warning message when there is no default domain set
  "sso.not-configured": "SSO no está configurado para esta organización. No es posible iniciar sesión cuando el SSO no está configurado.", // Warning message for when sso is not configured
  "sso.saml.cert": "Certificación", // The SAML cert attribute display name
  "sso.saml.enablePublicPortal": "Habilitar portal público", // The SAML enable public portal display name
  "sso.saml.entryPoint": "Punto de ingreso", // The saml entry point display name
  "sso.saml.identifierFormat": "Formato del identificador", // The format of the name provided by the identity provider
  "sso.saml.issuer": "Emisor", // The saml issuer attribute display name
  "sso.saml.logoutUrl": "URL de cierre de sesión", // URL that is redirected to for SSO logout
  "sso.saml.mapping": "Esquemas", // The attribute mapping display name
  "sso.save.error": "Error al guardar el objeto SSO {name}. Error: {message}", // The error toast when it fails to save an SSO object
  "sso.save.success": "Se guardó correctamente el objeto SSO {name}", // The success toast when it successfully saves an SSO object

  "streams.custom.walmart.credentials": "Credenciales de habilidades futuras", // Title for custom Credentials stream for Walmart
  "streams.custom.walmart.degreePrograms": "Programas de grado", // Title for custom Degree Programs stream for Walmart
  "streams.ordered.recentlyAdded": "Agregado recientemente", // Title for group of activites that have been recently added
  "streams.ordered.startingSoon": "Comienza pronto", // Title for group of activites that have a start date in the near future
  "streams.property.fastToComplete": "Completar en 8 horas o menos", // Title for stream of activities that a user can complete in a short amount of time.
  "streams.property.trending": "Tendencia en {tenantName}", // Title for stream of activities that are trending at the users company.  {tenantName} is a proper noun.

  "submit-own-request.breadcrumb": "Volver a las solicitudes", // breadcrumb label for submit my own request page
  "submit-own-request.documentTitle": "Enviar mi propia solicitud: SkillsWave", // Document title when viewing the smunit-own-request page. Do not translate "SkillsWave".
  "submit-own-request.form.course.cost": "Costo", // label for course cost
  "submit-own-request.form.course.cost.tooltip": "Asegúrese de incluir todos los impuestos y cargos adicionales.", // tooltip text for course cost input box
  "submit-own-request.form.course.date.enddate": "Fecha final", // label for course end date
  "submit-own-request.form.course.date.startdate": "Fecha de inicio", // label for course start date
  "submit-own-request.form.course.description": "Descripción", // label for course description
  "submit-own-request.form.course.title": "Título", // label for course title
  "submit-own-request.form.course.type": "¿Qué tipo de capacitación es esta?", // label for course type dropdown
  "submit-own-request.form.course.type.option.conference": "Conferencia", // label for type conference
  "submit-own-request.form.course.type.option.course": "Curso", // label for type course
  "submit-own-request.form.course.type.option.other": "Otro", // label for type other
  "submit-own-request.form.course.type.option.program": "Programa", // label for type program
  "submit-own-request.form.course.type.option.textbook": "Libro de texto", // label for type textbook
  "submit-own-request.form.course.type.option.webinar": "Seminario virtual", // label for type webinar
  "submit-own-request.form.course.type.option.workshop": "Taller", // label for type workshop
  "submit-own-request.form.website.label": "URL del sitio web", // label for Website input textbox
  "submit-own-request.introduction.double": "Puede solicitar la aprobación para el desarrollo profesional fuera de SkillsWave.<linebreak></linebreak> Esta solicitud la aprobará: {approverOne} y {approverTwo}.", // Text to inform user of both approvers for their application
  "submit-own-request.introduction.single": "Puede solicitar la aprobación para el desarrollo profesional fuera de SkillsWave.<linebreak></linebreak> Esta solicitud la aprobará: {approver}.", // Text to inform user of approver for their application
  "submit-own-request.loading": "Cargando…",
  "submit-own-request.request-overview.date": "Fecha(s)", // label for date range in submit own request overview
  "submit-own-request.request-overview.description": "Descripción", // label for description in submit own request overview
  "submit-own-request.request-overview.title": "Título", // label for title in submit own request overview
  "submit-own-request.request-overview.type": "Tipo", // label for course type in overview
  "submit-own-request.request-overview.website": "URL del sitio web:", // label for website url in submit own request overview
  "submit-own-request.select.title": "Lo que quiero aprender",
  "submit-own-request.title": "Enviar mi propia solicitud", // Submit my own request page title

  "suggest-panel.copyUrl.course.label": "Copiar enlace al curso",
  "suggest-panel.copyUrl.program.label": "Copie el enlace al programa",
  "suggest-panel.copyUrl.success.course": "Se copió el enlace del curso 🎉", // Suggest panel copy url success message. activtyType is either 'course' or 'program'
  "suggest-panel.copyUrl.success.program": "Se copió el enlace del programa 🎉", // Suggest panel copy url success message. activtyType is either 'course' or 'program'
  "suggest-panel.form.button.course": "Sugiera un curso", // Label for button if activity is a course
  "suggest-panel.form.button.program": "Sugiera un programa", // Label for button if activity is a program
  "suggest-panel.form.date.nextSession": "Siguiente sesión", // Label if the course/program doesn't have a start date
  "suggest-panel.form.date.starts": "Comienza el", // Label if the course/program has a start date
  "suggest-panel.form.error.invalid-email": "Ingrese una dirección de correo electrónico válida",
  "suggest-panel.form.error.required": "Ingrese al menos una dirección de correo electrónico válida.",
  "suggest-panel.form.label.email": "Agregar direcciones de correo electrónico", // Label for email form field
  "suggest-panel.form.label.textarea": "Mensaje personal", // Label for personal message form field
  "suggest-panel.form.message.found.course": "Encontré un excelente curso. ¡No te lo pierdas!", // Populated string for personal message if activity is a course on request page, activity page or default
  "suggest-panel.form.message.found.program": "Encontré un gran programa. ¡No te lo pierdas!", // Populated string for personal message if activity is a program on request page, activity page or default
  "suggest-panel.form.message.taking.course": "Estoy tomando este curso en el que podría estar interesado. ¡Mírelo!", // Populated string for personal message if activity is a course on all set page
  "suggest-panel.form.message.taking.program": "Estoy tomando este programa en el que podría estar interesado. ¡Mírelo!", // Populated string for personal message if activity is a program on all set page
  "suggest-panel.non-request-page.description.course": "Sugiera este curso a sus colegas.", // Suggest panel title if activity is a course on a non-request-page
  "suggest-panel.non-request-page.description.program": "Sugiera este programa a sus colegas.", // Suggest panel title if activity is a program on a non-request-page
  "suggest-panel.request-page.description.course": "¿Este curso es ideal para su equipo? Sugiéralo a los demás.", // Suggest panel title if activity is a course on request page
  "suggest-panel.request-page.description.program": "¿Este programa es ideal para su equipo? Sugiéralo a los demás.", // Suggest panel title if activity is a program on request page
  "suggest-panel.sent.popup.header": "Sugerencia enviada.", // the text to slide up when the suggestion emails are triggered
  "suggest-panel.sent.popup.subText.course": "Gracias por sugerir este curso", // the text to slide up when the suggestion emails are triggered on a course activity
  "suggest-panel.sent.popup.subText.program": "Gracias por sugerir este programa", // the text to slide up when the suggestion emails are triggered on a program activity
  "suggest-panel.title.course": "Sugiera este curso", // Suggest panel title if activity is a course
  "suggest-panel.title.program": "Sugiera este programa", // Suggest panel title if activity is a program

  "take-action-today-carousel.continueYourProgramTab": "Continúe con su programa", // The tab for the continue your program section of the take action today carousel
  "take-action-today-carousel.heading": "Actúe hoy mismo", // The header for the take action today carousel
  "take-action-today-carousel.myListTab": "Mi lista", // The tab for the my list section of the take action today carousel
  "take-action-today-carousel.onDemandTab": "A demanda", // The tab for the upcoming section of the take action today carousel
  "take-action-today-carousel.startingSoonTab": "Empieza pronto", // The tab for the starting soon section of the take action today carousel

  "tenant.approvalModel.centralized": "Centralizado", // The display name for the centralized approval model
  "tenant.approvalModel.distributed": "Distribuido", // The display name for the distributed approval model
  "tenant.approvalModel.hybrid": "Híbrido", // The display name for the hybrid approval model
  "tenant.entitlement.sponsor": "Patrocinador", // Sponsor entitlement for employer tenants
  "tenant.entitlement.watcher": "Observador", // Watcher entitlement for employer tenants

  "tenantSSO.loginType.magic-link": "Enlace de inicio de sesión", // The display name for the magic link login type
  "tenantSSO.loginType.saml": "SAML", // The display name for the magic link login type, ignore-untranslated

  "thankyou-page.documentTitle": "Gracias: SkillsWave", // Document title when viewing the thank you page. Do not translate "SkillsWave".

  "thankyou-signup.body": "Hemos enviado un correo electrónico de verificación a {userEmail}.<linebreak></linebreak> Haga clic en el enlace del correo electrónico para activar su cuenta.",
  "thankyou-signup.emailNotRecieved": "¿No recibió un correo electrónico? <resendLink>Reenviar</resendLink> o <contactLink>comunicarse con nosotros</contactLink>",
  "thankyou-signup.title": "Gracias por registrarse.",
  "thankyou-signup-email-resent": "El correo electrónico de verificación se envió a {email}",

  "transcript.international": "Internacional",

  "user-summary.approvedAmount.cancelled": "Cancelado", // The value to display for the approved amount when the request is cancelled
  "user-summary.approvedAmount.declined": "Rechazado", // The value to display for the approved amount when the request has been declined
  "user-summary.approvedAmount.pending": "Pendiente", // The value to display for the approved amount when the request is still pending
  "user-summary.approvedAmount.withdrawn": "Dado de baja", // The value to display for the approved amount when the learner has withdrawn from the class
  "user-summary.currentFiscalYear.header": "Año fiscal actual", // The header for the user summary card
  "user-summary.currentFiscalYear.noRequests": "{userDisplayName} no tiene ninguna otra solicitud en el año fiscal actual.", // The header for the user summary card
  "user-summary.header": "Otras solicitudes", // The header for the user summary card
  "user-summary.noRequests": "{userDisplayName} no tiene ninguna otra solicitud.", // The header for the user summary card
  "user-summary.previousFiscalYears.header": "Años fiscales anteriores", // The header for the user summary card
  "user-summary.previousFiscalYears.noRequests": "{userDisplayName} no tiene ninguna otra solicitud en los años fiscales anteriores.", // The header for the user summary card

  "users-table.cancel-invite.menu": "Cancelar invitación y eliminar usuario",
  "users-table.cancel-invite.success": "Invitación cancelada con éxito",
  "users-table.column.actions": "Acciones", // Label for the actions column
  "users-table.invited": "Invitado",
  "users-table.reinvite.menu": "Reenviar invitación",
  "users-table.reinvite.success": "Invitación reenviada con éxito",
  "users-table.userActiveSwitchLabel": "Usuario Activo",

  "view-activities.documentTitle": "Programas: SkillsWave", // Browser page title for the activities page. Do not translate "SkillsWave".
  "view-activities.documentTitle.search": "{searchCriteria}: Programas: SkillsWave", // Browser page title for the activities page when you search. Do not translate "SkillsWave".
  "view-activities.search.placeholder": "Buscar una habilidad, curso o programa", // Placeholder text for activities search bar

  "view-activity.activityMetadata.footer.nextSession": "Siguiente sesión", // The request approval button label
  "view-activity.activityMetadata.footer.notRequestableAlert": "Debe registrarse en el programa para tomar este curso.", // Informs the user the course is not requestable
  "view-activity.activityMetadata.footer.notRequestableComingSoonAlert": "El registro se abrirá pronto. Agregue este {activityType} a su lista y le enviaremos un correo electrónico cuando se abra el registro.", // Informs the user the course is not requestable
  "view-activity.activityMetadata.footer.requestApproval": "Iniciar solicitud", // The request approval button label
  "view-activity.activityMetadata.footer.startDateType": "Tipo de fecha de inicio", // Label Start date type dropdown
  "view-activity.activityMetadata.footer.unscheduledAlert": "Este curso estará disponible cuando se programe la próxima sesión.", // Informs the user that the program/course does not have a scheduled start date yet
  "view-activity.activityOverview.activityLink": "Para obtener las fechas de inicio exactas, los plazos de registro, la política de reembolso y otros detalles, consulte <link>{activityTitle}</link> en {providerName}.", // program/course link text, <link> tag should NOT be translated
  "view-activity.activityOverview.header": "Descripción general", // Overview section header
  "view-activity.activityOverview.skillsDescription": "Habilidades asociadas con esta actividad", // description for element that contains list of skills
  "view-activity.addToMyList": "Agregar a mi lista", // Label for Add to My List button icon
  "view-activity.admissionRequirements.header": "Requisitos de admisión", // title for admission requirements section
  "view-activity.backToTop": "Volver al principio", // Anchor displayed at the end of seeing all avaliable programs.
  "view-activity.backToTop.ariaLabel": "Volver al principio. Llegará a la parte superior de la página actual.",
  "view-activity.documentTitle.afterLoading": "{activityTitle}: SkillsWave", // Text which appears in browser page title after someone navigates to the view-activity page after activity data has loaded. Do not translate "SkillsWave".
  "view-activity.documentTitle.whileLoading": "Cargando… SkillsWave", // Text which appears in browser page title after someone navigates to the view-activity page but before the activity title is known. Do not translate "SkillsWave".
  "view-activity.editSkills.button": "Editar habilidades", // button text allowing users to edit skills on an activity
  "view-activity.editSkills.save": "Se guardaron los cambios en las habilidades", // message that shows when skill shave been saved on the activity
  "view-activity.editStartDate.button": "Editar fecha de inicio", // button text allowing users to edit start date on an activity
  "view-activity.editStartDate.date.list": "Agregar fechas de sesión:",
  "view-activity.inactive": "Inactivo", // Status indicator for inactive programs
  "view-activity.myListError": "No se pudo actualizar mi lista", // Error when attempting to add or remove activity to My List
  "view-activity.noResults.prompt.1": "No hay resultados para:", // Part 1 of sentence informing the user that no activities were found based on selected search parameters
  "view-activity.noResults.prompt.2": "{searchCriteria} y sus filtros seleccionados actualmente", // Part 2 of sentence informing the user that no activities were found based on selected search parameters
  "view-activity.removeFromMyList": "Eliminar de mi lista", // Label for Remove from My List button icon
  "view-activity.requirements.header": "Requisitos", // Requirements section header
  "view-activity.startDate": "Elegir fecha", // The course/programs start date input label
  "view-activity.startDateType.anytime": "En cualquier momento", // The course/program starts anytime
  "view-activity.startDateType.comingSoon": "Próximamente", // The course/program start date is coming soon
  "view-activity.startDateType.date": "Fecha", // The course/program starts on a specific date
  "view-activity.startDateType.unknown": "Desconocido", // The course/program start date is unknown
  "view-activity.title": "¿Qué habilidades desarrollará a continuación?", // Title for activities view page
  "view-activity.toast.added": "Se agregó a mi lista", // Toast text when adding activity to My List
  "view-activity.toast.removed": "Se quitó de mi lista", // Toast text when removing activity from My List

  "view-activity-category.career-skills.company-intro": "Habilidades de coincidencia comúnmente requeridas por esta carrera en {companyName}", // Skill intro text when viewing all activities for a career while viewing a company tenant
  "view-activity-category.career-skills.non-company-intro": "Habilidades necesarias para sobresalir en esta carrera", // Skill intro text when viewing all activities for a career while viewing a NON-company tenant, e.g. an association
  "view-activity-category.courses": "Cursos", // Activities of the type course
  "view-activity-category.documentTitle.afterLoading": "{categoryName}: Programas: SkillsWave", // Title of web page when viewing a specific category's activities, while it is loading. Do not translate "SkillsWave".
  "view-activity-category.documentTitle.whileLoading": "Cargando… Programas: SkillsWave", // Title of web page when viewing a specific category's activities, after it has loaded. Do not translate "SkillsWave".
  "view-activity-category.programs": "Programas", // Activities of the type program

  "view-admin.activities": "Actividades", // Label for the Activities tab.
  "view-admin.addButton.employer": "Agregar empleador",
  "view-admin.addButton.provider": "Agregar proveedor",
  "view-admin.admin.heading1": "Administrador",
  "view-admin.documentTitle": "Administrador: SkillsWave", // Do not translate "SkillsWave".
  "view-admin.documentTitle.auditPermissions": "Permisos - auditoría", // title for permissions audit page.
  "view-admin.employer.heading1": "Empleadores", // Heading for the employers list.
  "view-admin.features": "Características", // Label for the Features tab.
  "view-admin.fetchSkills": "Capturar habilidades", // Label for the button that fetches skills.
  "view-admin.filterSkills.label": "Filtrar por habilidad", // Label for the filter skills textbox.
  "view-admin.filterSkills.table.activityId": "ID de actividad", // Heading for the activity ID column.
  "view-admin.filterSkills.table.activityName": "Nombre de la actividad", // Heading for the activity name column.
  "view-admin.filterSkills.table.activityType": "Tipo de actividad", // Heading for the activity type column.
  "view-admin.filterSkills.table.skillId": "ID de habilidad", // Heading for the skill ID column.
  "view-admin.filterSkills.table.skillName": "Nombre de la habilidad", // Heading for the skill name column.
  "view-admin.loading": "Cargando administración", // Placeholder loading admin page.
  "view-admin.provider.heading1": "Proveedores", // Heading for the providers list.
  "view-admin.tenantTypeSelect.label": "Tipo de inquilino", // Label for tenant type selection dropdown.
  "view-admin.tenantTypeSelect.none": "-- Seleccione tipo de inquilino --", // Default option for tenant type selection dropdown.
  "view-admin.tenants": "Inquilinos", // Label for the Tenants tab.

  "view-application.activity-details.heading.details": "{activityType}", // Heading for activity info
  "view-application.activity-details.submit-own-request.message": "Esta oportunidad de aprendizaje se encontró fuera del catálogo de SkillsWave", // message for courses outside of wave catalog
  "view-application.activityMetadata.footer.nextSession": "Siguiente sesión", // The request approval button label
  "view-application.activityOverview.activityLink": "Para obtener las fechas de inicio exactas, los plazos de registro, la política de reembolso y otros detalles, consulte <link>{activityTitle}</link> en {providerName}.", // program/course link text, <link> tag should NOT be translated
  "view-application.activityOverview.header": "Descripción general", // Overview section header
  "view-application.admissionRequirements.description": "Este programa tiene requisitos de admisión y prerequisitos.",
  "view-application.backToResults.link.label": "Volver a los resultados", // Label for back to results link
  "view-application.documentTitle": "Ver solicitud: SkillsWave", // Heading for activity info. Do not translate "SkillsWave".
  "view-application.paymentHandledByProvider.registrationDialog.cancel": "Cancelar",
  "view-application.paymentHandledByProvider.registrationDialog.confirm": "Continuar",
  "view-application.paymentHandledByProvider.registrationDialog.content": "Se le indica que complete sus próximos pasos para registrarse en <b>{providerName}.</b>",
  "view-application.paymentHandledByProvider.registrationDialog.title": "Está saliendo de {tenantName}",
  "view-application.programCompletionPanel.comments": "Comentarios", // Title for the comment box in the program completion panel
  "view-application.programCompletionPanel.completionFormCompleteOption": "{learnerName} ha completado el programa completo", // First radio option in the completion form in the program completion panel
  "view-application.programCompletionPanel.completionFormIncompleteOption": "El programa no ha sido completado", // Second radio option in the completion form in the program completion panel
  "view-application.programCompletionPanel.completionFormTitle": "Por favor, verifique la finalización del programa a continuación.", // Title for the completion form in the program completion panel
  "view-application.programCompletionPanel.completionSummaryEnd": " para el programa ''<link>{programName}</link>''.", // End of completion summary for the program completion panel when opened. <link> should NOT be translated
  "view-application.programCompletionPanel.completionSummaryMiddle": "{coursesCompleted} de {coursesRequired} cursos", // Middle of completion summary for the program completion panel when opened
  "view-application.programCompletionPanel.completionSummaryStart": "{learnerName} ha completado ", // Start of completion summary for the program completion panel when opened
  "view-application.programCompletionPanel.header": "Finalización del Programa", // Header for the program completion panel
  "view-application.programCompletionPanel.saveButton": "Guardar", // Save button for the program completion panel
  "view-application.programCompletionPanel.summary": "{coursesCompleted} de {coursesRequired} cursos completados", // Summary for the program completion panel
  "view-application.programCompletionPanel.summaryTooltip": "{coreCoursesCompleted} de {coreCoursesRequired} cursos principales y {electiveCoursesCompleted} de {electiveCoursesRequired} cursos electivos completados", // Tooltip for the program completion panel summary
  "view-application.programCompletionPanel.summaryTooltip.coreOnly": "{coreCoursesCompleted}/{coreCoursesRequired} cursos básicos completados", // Tooltip for the program completion panel summary if there are only core courses
  "view-application.programCompletionPanel.summaryTooltip.electivesOnly": "{electiveCoursesCompleted}/{electiveCoursesRequired} cursos optativos completados", // Tooltip for the program completion panel summary if there are only elective courses
  "view-application.programCompletionPanel.toastCommentError": "Se requiere un comentario cuando el programa está marcado como incompleto", // Toast message for validation error in program completion panel comment
  "view-application.programCompletionPanel.toastSuccess": "Finalización del programa guardada", // Toast message for successful save of the program completion panel
  "view-application.programCompletionPanel.verificationSummary": "La finalización del programa ha sido verificada", // Summary for the program completion panel before verification
  "view-application.programCompletionPanel.verificationSummary.complete": "Ha confirmado que {learnerName} ha completado todos los cursos en ''<link>{programName}</link>''.", // Toast message for verification of unsuccessful program completion in panel
  "view-application.programCompletionPanel.verificationSummary.incomplete": "Ha confirmado que {learnerName} no ha completado todos los cursos en ''<link>{programName}</link>''.", // Toast message for verification of successful program completion in panel
  "view-application.registrationDetails.cancelled": "Este(a) {activityType, select, course {curso} program {programa} other {actividad}} se canceló", // toast banner to appear if the course has been cancelled
  "view-application.registrationDetails.connectionbanner": "No se encontró el correo electrónico de conexión de cuenta para {displayName}.", // error banner when account connection record doesn't exist for user
  "view-application.registrationDetails.copied": "Correo electrónico copiado en el portapapeles", // toast banner to confirm that the connection email has been copied to the users clipboard
  "view-application.registrationDetails.copy": "copiar correo electrónico", // text for copy button in registation details
  "view-application.registrationDetails.failed": "{displayName} no aprobó el/la {activityType, select, course {curso} program {programa} other {actividad}}", // banner to appear when user has academically failed the course
  "view-application.registrationDetails.header": "Registrar y actualizar", // Header for the stripe provider request view
  "view-application.registrationDetails.helpMessage": "Utilice el siguiente correo electrónico para encontrar a {displayName} en su sistema de registro. Si no encuentra a este estudiante, comuníquese con el equipo de soporte de Wave", // body text to direct providers to action on the copy email below. Do not translate "SkillsWave".
  "view-application.registrationDetails.nextAction": "Una vez registrado, actualice la información de registro y finalización de este estudiante", // body text to direct providers to fill out registration form details
  "view-application.registrationDetails.withdrawn": "{displayName} se retiró del/de la {activityType, select, course {curso} program {programa} other {actividad}}", // toast banner to appear when user has withdrawn from course
  "view-application.requestInformation.header": "Revise la información de la solicitud", // Header for the stripe provider request view
  "view-application.requirements.header": "Requisitos", // Requirements section header

  "view-application-confirmation.backButton": "Volver a la solicitud", // Button text for navigating back to request page
  "view-application-confirmation.info": "Por lo general, {institution} tardará {registrationTime} días en registrarlo. Le enviarán los detalles por correo electrónico a <emailSpan>{email}</emailSpan>.", // Info on request confirmation page
  "view-application-confirmation.info.admissionBased": "{institution} le enviará un correo electrónico a: <emailSpan>{email}</emailSpan> dentro de {registrationTime} días hábiles con información sobre los detalles de admisión y registro.", // Info on request confirmation page for admission based course
  "view-application-confirmation.subtitle": "{institution} lo registrará en {activityTitle} y le enviará un correo electrónico.", // Request confirmation page subtitle
  "view-application-confirmation.subtitle.admissionBased": "{institution} le enviará un correo electrónico", // Request confirmation page subtitle for admission based course
  "view-application-confirmation.title": "¡Ya está listo!<linebreak> </linebreak> Ahora, espere a que lo registren.", // Request confirmation page title for desktop
  "view-application-confirmation.title.mobile": "¡Ya está listo! Ahora solo espere a que lo registren.", // Request confirmation page title for mobile
  "view-application-confirmation.wait.info": "Si su {activityType} no se inicia dentro de un momento, es posible que la ventana de registro no esté abierta todavía. No se preocupe, {institution} lo registrará dentro de los {registrationTime} días posteriores a la apertura del registro.", // Info about registration window on request confirmation page
  "view-application-confirmation.wait.info.admissionBased": "Si su {activityType} no se inicia dentro de un momento, es posible que la ventana de registro no esté abierta todavía. No se preocupe, {institution} lo contactará dentro de los {registrationTime} días posteriores a la apertura del registro.", // Info about registration window on request confirmation page for admission based course

  "view-applications.documentTitle": "Solicitudes: SkillsWave", // Web page title for requests page. Do not translate "SkillsWave".
  "view-applications.title": "Solicitudes", // Requests page title

  "view-bookmarks.noBookmarks": "Haga clic en el ícono de marcador de cualquier página de detalles del programa para agregar el programa a esta lista o eliminarlo de ella.", // Message displayed when there are no bookmarked activities
  "view-bookmarks.title": "Marcadores", // Bookmarks page title

  "view-client-onboarding.documentTitle": "Incorporación",
  "view-client-onboarding.welcomeMessage": "Empieza por aquí desde el principio. Responde algunas preguntas sobre tu empresa y tu equipo y nosotros seleccionaremos nuestro catálogo para mostrarte cursos que te ayudarán a mejorar las habilidades de tu equipo. Realizaremos un seguimiento de tus cursos y te facilitaremos el seguimiento del desarrollo de tus equipos.",

  "view-error-loading.documentTitle": "Error - SkillsWave", // Document title for when there is an error loading wave. Do not translate "SkillsWave". ignore-untranslated

  "view-home.carousel.next": "Logos de los siguientes proveedores",
  "view-home.carousel.prev": "Logos de los proveedores anteriores",

  "view-how-it-works.documentTitle": "Cómo funciona",

  "view-landing-page.carousel.error.action": "Actualizar",
  "view-landing-page.carousel.error.description": "Ocurrió un error al cargar las recomendaciones. Actualice su navegador para intentarlo de nuevo.",
  "view-landing-page.carousel.interestedCareer.heading": "Recomendado para su objetivo profesional:",
  "view-landing-page.carousel.interestedSkillSet.heading": "Recomendado para sus objetivos:",
  "view-landing-page.carousel.interestedSkillSet.menuLabel": "Habilidades",
  "view-landing-page.carousel.tabTitle.bestResults": "Mejores resultados",
  "view-landing-page.carousel.tabTitle.courses": "Cursos",
  "view-landing-page.carousel.tabTitle.degrees": "Grados",
  "view-landing-page.carousel.tabTitle.microlearning": "Microaprendizaje",
  "view-landing-page.carousel.tabTitle.shortCredentials": "Credenciales breves",
  "view-landing-page.carousel.userRole.heading": "Recomendado para su rol:",
  "view-landing-page.documentTitle": "Recomendaciones",
  "view-landing-page.profile.credit.corporatePolicy": "{remainingBalance} de {totalBalance} disponible en {employerTenant}",
  "view-landing-page.profile.credit.zeroInvestment": "Hasta ahora, se ha invertido {amount} en usted, solicite comenzar a aprender hoy",
  "view-landing-page.profile.description.interests": "EEstás interesado en:", // For the profile card, will be followed by a list of interests (skills and careers)
  "view-landing-page.profile.description.interestsPlaceholder": "Elija 3 habilidades o más.", // For the profile card, will be followed by a list of interests (skills and careers)
  "view-landing-page.profile.description.jobTitle": "Su rol es: {jobTitle}", // For the profile card, is followed by view-landing-page.profile.description.interests if there are any interests
  "view-landing-page.profile.editButton": "Editar habilidades",
  "view-landing-page.profile.header": "¡Bienvenido/a, {firstName}!",
  "view-landing-page.profile.recommendSettingGoals": "Establezca sus metas para recibir mejores recomendaciones",
  "view-landing-page.profile.showLessButton": "Mostrar menos",
  "view-landing-page.profile.showMoreButton": "Mostrar más",
  "view-landing-page.profile.thisFiscalYear": "para este año fiscal",
  "view-landing-page.profile.usedCredit": "{employerTenant} ha invertido {amount} en usted",

  "view-login.documentTitle": "Iniciar sesión - SkillsWave", // Document title for the login page. Do not translate "SkillsWave".

  "view-not-found.documentTitle": "Página no encontrada: SkillsWave", // Document title for when user navigates to an invalid page and it's not found. Do not translate "SkillsWave".

  "view-onboarding.area-of-interest.description": "Eres un(a) {jobTitle} interesado(a) en:",
  "view-onboarding.documentTitle": "Incorporación", // title for document of onboarding page
  "view-onboarding.generatingResults.subtext.AddingMagic": "Añadiendo un toque de magia", // text to display beneath the animation that changes throughout
  "view-onboarding.generatingResults.subtext.Enjoy": "¡Disfrute!", // text to display beneath the animation that changes throughout
  "view-onboarding.generatingResults.subtext.FingingCourses": "Buscando cursos de los principales proveedores", // text to display beneath the animation that changes throughout
  "view-onboarding.generatingResults.subtext.GatherSkills": "Reuniendo sus habilidades y objetivos profesionales", // text to display beneath the animation that changes throughout
  "view-onboarding.generatingResults.text": "Recomendando cursos para usted", // text to display beneath the animation
  "view-onboarding.getStarted.animationAltText": "Una persona feliz de pie en las escaleras, mirando un grupo de habilidades flotantes azul oscuro y azul claro", // Alt text for the onboarding animation
  "view-onboarding.getStarted.closestMatchLabel": "Tu rol coincide con este {industryTitle}", // Label for input where user selects a predefined job title that most closely matches the industry definition of their current role
  "view-onboarding.getStarted.closestMatchLabel.tooltip.header": "título de la industria:", // Tooltip header for input where user selects a predefined job title that most closely matches the industry definition of their current role
  "view-onboarding.getStarted.closestMatchTooltip": "Hemos asociado tu rol a este título de la industria para mejorar aún más tus recomendaciones. Si no te parece correcto, puedes editarlo", // Tooltip for input where user selects a predefined job title that most closely matches the industry definition of their current role
  "view-onboarding.getStarted.currentRoleLabel": "Ingrese su rol actual", // Offscreen label for input where user inputs their job title
  "view-onboarding.getStarted.currentRolePlaceholder": "Su rol actual", // Placeholder text for input where user inputs their job title
  "view-onboarding.getStarted.editSOC.ariaLabel": "Editar la coincidencia de industria más cercana", // Aria label for editing job title match with third party platform job titles
  "view-onboarding.getStarted.editSOC.button": "Editar", // Button text for editing job title match with third party platform job titles
  "view-onboarding.getStarted.existingUserAlert": "Antes de continuar con su cuenta, establezca sus metas para mejorar las recomendaciones de aprendizaje", // alert message for exisitng users
  "view-onboarding.getStarted.introduction.subheader": "Hola {name}, su rol es:", // Introduction text saying hello to user and introducing their job title/role in our system
  "view-onboarding.getStarted.marquee.header": "Mejore sus habilidades ahora, agrézcase después", // Marquee header for onboarding process to upskilling platform
  "view-onboarding.getStarted.marquee.subheader": "Encuentre oportunidades educativas de calidad para ayudarle a alcanzar su máximo potencial", // Subtext for marquee header that explains the websites purpose
  "view-onboarding.getStarted.skillpopper.label": "Habilidades clave para su rol:", // Supplemental text explaining that the system is currently loading skills related to the users selected profession
  "view-onboarding.getStarted.submit.button": "Establece Tus Metas", // Button text for submitting page and moving forward with onboarding process

  "view-report-dashboard.documentTitle": "Análisis: SkillsWave", // Document title for the Analytics page. Do not translate "SkillsWave".
  "view-report-dashboard.employerSelect.all": "TODOS LOS EMPLEADORES", // All employers option for employer selection dropdown
  "view-report-dashboard.employerSelect.label": "Seleccione un empleador", // Label for employer selection dropdown
  "view-report-dashboard.employerSelect.none": "-- SELECCIONAR EMPLEADOR --", // Default option for employer selection dropdown
  "view-report-dashboard.exportButton": "Estados de Finalización de Exportación", // Reports dashboard export button
  "view-report-dashboard.filterBy": "Filtrar por", // Label to proceed filter bar interactions
  "view-report-dashboard.filterRemove": "Haga clic para eliminar el valor", // Button to remove filter options
  "view-report-dashboard.mobile.otherFilters": "Otros filtros", // The other filters button label
  "view-report-dashboard.providerSelect.all": "TODOS LOS PROVEEDORES", // All providers option for provider selection dropdown
  "view-report-dashboard.providerSelect.label": "Seleccione un proveedor", // Label for provider selection dropdown
  "view-report-dashboard.providerSelect.none": "-- SELECCIONAR PROVEEDOR --", // Default option for provider selection dropdown
  "view-report-dashboard.timeframeFilter.appliedFilter": "Filtros aplicados", // Label for applied filter
  "view-report-dashboard.timeframeFilter.applyButton": "Aplicar", // Reports dashboard time frame filter button
  "view-report-dashboard.timeframeFilter.clearButton": "Borrar", // Reports dashboard time frame filter button
  "view-report-dashboard.timeframeFilter.custom": "Personalizado", // Reports dashboard time frame filter button
  "view-report-dashboard.timeframeFilter.endDate": "Fecha final", // Timeframe end date input label
  "view-report-dashboard.timeframeFilter.from": "De", // Time frame 'From' word to use it like part of 'From dd\mm\yyyy ...'
  "view-report-dashboard.timeframeFilter.invalidDateRange": "La fecha de inicio debe ser anterior a la fecha final.", // Invalid date range message
  "view-report-dashboard.timeframeFilter.last": "Últimos", // Time frame 'Last' word to use it like part of 'Last 30 days'
  "view-report-dashboard.timeframeFilter.lastMonth": "30 días", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.lastThreeMonths": "90 días", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.lastWeek": "Últimos 7 días", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.lastYear": "Año a la fecha", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.requiredFieldMessage": "El campo es obligatorio.", // Required field message
  "view-report-dashboard.timeframeFilter.sinceFiscalYear": "Año fiscal a la fecha", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.startDate": "Fecha de inicio", // Timeframe start date input label
  "view-report-dashboard.timeframeFilter.timeframeFilterButton": "Hora", // Reports dashboard time frame filter button
  "view-report-dashboard.timeframeFilter.to": "hasta", // Time frame 'From' word to use it like part of '... to dd\mm\yyyy'
  "view-report-dashboard.title": "Análisis", // Reports dashboard page title

  "view-settings.catalog-preferences": "Preferencias del catálogo",
  "view-settings.details": "Detalles de la empresa",
  "view-settings.documentTitle": "Configuración",
  "view-settings.finance": "Finanza",
  "view-settings.user-management": "Gestión de usuarios",
};
